import React from "react"
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";

type ProductGroupMainSubCategoryButtonProps = {
    buttonWidth: number,
    imageUrl: string,
    title: string,
    groupId: string,
    categoryId: string,
    subCategoryId: string,
    shouldIgnoreCategoryId?: boolean
}

export default class ProductGroupMainSubCategoryButton extends React.Component<ProductGroupMainSubCategoryButtonProps> {
    render() {
        const {shouldIgnoreCategoryId = false} = this.props;
        return (
            <button onClick={() => {
                if (this.props.subCategoryId === "menbrand" || this.props.subCategoryId === "womenbrand") {
                    window.location.href = "/Store/Brands"
                } else if ((this.props.groupId === 'Beauty' || this.props.groupId === 'Fashion') && !this.props.categoryId && !shouldIgnoreCategoryId) {
                    window.location.href = `/Store/${this.props.groupId}/${this.props.subCategoryId}`;
                } else {
                    window.location.href = `/Store/Products?groupId=${this.props.groupId}&categoryId=${this.props.categoryId ?? "Men"}&subCategoryId=${this.props.subCategoryId}`
                }
            }}>
                <div 
                    style={{
                        width:`${this.props.buttonWidth}px`,
                        height:`${this.props.buttonWidth}px`,
                        padding:"4px"
                    }}
                >
                    <ImageWidget imageUrl={this.props.imageUrl} />
                </div>
                <div style={{height:'32px', width:`${this.props.buttonWidth}px`, display:"flex", flexDirection:"column", justifyContent:"center"}}>
                    <p style={{fontSize:'12px', fontWeight:"bold", padding:"4px 0px", textAlign:"center"}}>{this.props.title}</p>
                </div>
            </button>
        );
    }
}