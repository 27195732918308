import Scaffold, {
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import OrderReceiptViewModel from "./OrderReceiptViewModel";
import OrderReceiptBillingWidget from "./Widgets/OrderReceiptBillingWidget";
import OrderReceiptItemsWidget from "./Widgets/OrderReceiptItemsWidget";
import OrderReceiptAddressWidget from "./Widgets/OrderReceiptAddressWidget";
import OrderReceiptPaymentInfoWidget from "./Widgets/OrderReceiptPaymentInfoWidget";
import ButtonWidget, {
  ButtonWidgetType,
} from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

import "./OrderReceipt.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_back from "../../Assets/Images/ic_chevron_left.svg";
import ic_cart_black from "../../Assets/Images/ic_cart_black.svg";

import ViewController from "../../CommonComponents/Base/ViewController";
import OrderDetailViewModel from "../../OrderAndPayment/OrderDetail/OrderDetailViewModel";
import UnfinishedPaymentWidget from "../../OrderAndPayment/OrderDetail/Widgets/UnfinishedPayment/UnfinishedPaymentWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import OrderIdWidget from "../../OrderAndPayment/OrderDetail/Widgets/OrderIdWidget/OrderIdWidget";
import PopupTrackingWidget from "../../OrderAndPayment/OrderAndDelivery/Widget/PopupTrackingWidget";
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
import OrderPaymentStepsWidget from "../../OrderAndPayment/OrderDetail/Widgets/OrderPaymentSteps/OrderPaymentStepsWidget";
import OrderChunkWidget from "../../OrderAndPayment/OrderDetail/Widgets/OrderChunk/OrderChunkWidget";
import OrderShippingAddressWidget from "../../OrderAndPayment/OrderDetail/Widgets/OrderShippingAddress/OrderShippingAddressWidget";
import OrderTotalPriceWidget from "../../OrderAndPayment/OrderDetail/Widgets/OrderTotalPrice/OrderTotalPriceWidget";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import OrderPaymentInfo from "../../OrderAndPayment/OrderDetail/Widgets/OrderPaymentInfo/OrderPaymentInfo";
import NeedHelpWidget from "../../OrderAndPayment/OrderDetail/Widgets/NeedHelpWidget/NeedHelpWidget";
import {
  WithCreatorLinkProps,
  getResellerPhoneNumber,
  isResellerDomain,
  withSellerId,
} from "../../CommonComponents/Utils";
import { useParams } from "react-router-dom";
import mixpanel from "mixpanel-browser";

function OrderReceiptPage(props: any) {
  let { orderId } = useParams();
  return <OrderReceiptScreen {...props} id={orderId} />;
}

class OrderReceiptScreen extends ViewController<
  { creatorLink?: string; id?: string },
  OrderDetailViewModel
> {
  constructor(props: { creatorLink?: string; id?: string }) {
    super(
      props,
      new OrderDetailViewModel(
        props.id || localStorage.getItem("orderId") || "",
        true,
        props.creatorLink
      )
    );
    this.viewModel.loadPreloadingProps();

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "OrderReceipt",
    });
  }

  componentWillUnmount(): void {
    localStorage.removeItem("orderId");
  }

  shopMore = () => {
    window.location.href = isResellerDomain()
      ? `/shop/${this.props.creatorLink}`
      : "/";
  };

  render() {
    const DeeplinkHeight = 46;
    const fixedHeight = 72;
    const orderReceiptData = this.viewModel.orderReceiptData;

    if (!localStorage.getItem("fpAuth")) {
      return (
        <Scaffold
          isShowLoading={this.viewModel.isLoading}
          showSnackbar={this.viewModel.snackbarModel}
          showErrorModel={this.viewModel.errorMessage}
        >
          <div id="OrderReceipt" className="container">
            <div className="row">
              <div className="box_white_cart">
                <div className="col no-padding text-center">
                  <br></br>
                  <img
                    className="img-fluid"
                    width="60"
                    src={global.ImgUrl + "shopable/ic-complete@2x.png"}
                    alt="img_complete"
                  ></img>
                  <p className="font18 mtop10">
                    <b>Order completed !</b>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="box_white_cart">
                <div className="row">
                  <div className="col-6">
                    <span className="font14 color585b63">
                      <b>Order Number</b>
                    </span>
                  </div>
                  <div className="col-6 text-end">
                    <span className="font14 color585b63">
                      <b>#{this.viewModel.orderReceiptData?.orderNumber}</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <OrderReceiptItemsWidget orderReceiptData={orderReceiptData} />

            <OrderReceiptBillingWidget orderReceiptData={orderReceiptData} />

            <OrderReceiptAddressWidget orderReceiptData={orderReceiptData} />

            <OrderReceiptPaymentInfoWidget
              orderReceiptData={orderReceiptData}
            />

            <div className="row">
              <div className="col no-padding">
                <div className="box_white_cart">
                  <p>
                    <b>Need Help?</b>
                  </p>
                  <div
                    className="div_ask"
                    data-bs-toggle="modal"
                    data-bs-target="#ChatModal"
                  >
                    <img
                      src={global.ImgUrl + "shopable/ic-detail-chat.png"}
                      alt="img_chat"
                    ></img>
                    &nbsp;&nbsp;Ask Anytime
                  </div>
                </div>
              </div>
            </div>

            <div
              id="CartTopGradient"
              className="row"
              style={{ bottom: `${DeeplinkHeight + fixedHeight - 75}px` }}
            />
            <div className="row fixed">
              <div className="col-12 text-center paddingtop10">
                <ButtonWidget
                  buttonType={ButtonWidgetType.primary}
                  isEnabled={true}
                  width="calc(100% - 6px)"
                  action={() => {
                    this.shopMore();
                  }}
                >
                  Shop More
                </ButtonWidget>
              </div>
            </div>

            <div
              id="SpaceNavigationBackground"
              className="row"
              style={{ opacity: `${this.viewModel.navBarTransparency}` }}
            ></div>
            <div className="row floatingButton">
              {isResellerDomain() ? (
                <></>
              ) : (
                <button
                  id="SpaceHomeButton"
                  onClick={() => (window.location.href = "/")}
                >
                  <img src={ic_thefepi_f} alt="" />
                </button>
              )}
              <button
                id="SpaceCartButton"
                onClick={() => {
                  window.location.href = `${
                    isResellerDomain() ? `/shop/${this.props.creatorLink}` : ""
                  }/Cart`;
                }}
              >
                <img src={ic_cart} alt="" />
                {(this.viewModel.numberInCart ?? 0) > 0 && (
                  <div>{this.viewModel.numberInCart}</div>
                )}
              </button>
            </div>
            {isResellerDomain() ? (
              <ChannelChatPopup
                resellerNumber={getResellerPhoneNumber(this.props.creatorLink)}
                message={`Halo, ${this.props.creatorLink}`}
              />
            ) : (
              <ChannelChatPopup />
            )}
          </div>
        </Scaffold>
      );
    }

    return (
      <Scaffold
        backgroundColor="#f0f0f0"
        topBar={
          <>
            {!isResellerDomain() ? (
              <NavigationButton
                src={ic_thefepi_f}
                onClick={() => (window.location.href = "/")}
              />
            ) : (
              <></>
            )}
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginLeft: "8px",
              }}
            >
              Order {"&"} Delivery
            </p>
            <div style={{ flexGrow: 1 }} />
            <NavigationButton
              src={isResellerDomain() ? ic_cart_black : ic_cart}
              numberOnRightTop={this.viewModel.numberInCart}
              onClick={() => {
                window.location.href = `${
                  isResellerDomain() ? `/shop/${this.props.creatorLink}` : ""
                }/Cart`;
              }}
            />
          </>
        }
        showSnackbar={this.viewModel.snackbarModel}
        selectedBottomBarItem={isResellerDomain() ? undefined : "my-page"}
        isShowLoading={this.viewModel.isLoading}
        creatorId={this.viewModel.creatorId}
        isCreator={this.viewModel.isCreator}
        showErrorModel={this.viewModel.errorMessage}
      >
        <PopupTrackingWidget
          // @ts-ignore
          dataTracking={this.viewModel.trackingData}
          isShowLoadingTracking={this.viewModel.isLoadingTracking}
          showModal={this.viewModel.shouldShowTrackingModal}
          closeModal={() => void this.viewModel.closeTrackingModal()}
        />
        <SizedDiv height={8} />
        <OrderIdWidget
          id={this.viewModel.orderId}
          reminder={this.viewModel.model?.unfinishedPaymentInfo?.paymentMessage}
          message={
            this.viewModel.model?.unfinishedPaymentInfo
              ? {
                  headline:
                    this.viewModel.model?.paymentInfo.importantMessages.title,
                  contents:
                    this.viewModel.model?.paymentInfo.importantMessages
                      .messages,
                }
              : undefined
          }
          remainingTime={
            this.viewModel.model?.unfinishedPaymentInfo?.remainingTimeInSeconds
          }
        />

        {this.viewModel.model?.unfinishedPaymentInfo && (
          <>
            <SizedDiv height={8} />
            <UnfinishedPaymentWidget
              onCopyText={(type, text) => {
                navigator.clipboard.writeText(text || "");
                if (type === "payment-amount") {
                  this.viewModel.createSnackbar(
                    new ShowSnackbarModel({
                      message: "Payment amount copied",
                      type: "info",
                    })
                  );
                }
                if (type === "va") {
                  this.viewModel.createSnackbar(
                    new ShowSnackbarModel({
                      message: "Payment code copied",
                      type: "info",
                    })
                  );
                }
              }}
              paymentAccountHolderName={
                this.viewModel.model.unfinishedPaymentInfo.detail.description
              }
              paymentCode={
                this.viewModel.model.unfinishedPaymentInfo.detail.paymentCode
              }
              paymentVendorName={
                this.viewModel.model.paymentInfo.paymentMethodName
              }
              paymentMethodName={
                this.viewModel.model.unfinishedPaymentInfo.detail.title
              }
              paymentMethodLogoUrl={
                this.viewModel.model.unfinishedPaymentInfo.imageUrl
              }
              paymentCodeImageUrl={
                this.viewModel.model.unfinishedPaymentInfo.detail
                  .barcodeImageUrl
              }
              paymentAmount={
                this.viewModel.model.unfinishedPaymentInfo.paymentAmount
              }
            />
          </>
        )}

        {this.viewModel.model?.unfinishedPaymentInfo && (
          <>
            <SizedDiv height={8} />
            <OrderPaymentStepsWidget
              steps={this.viewModel.model?.unfinishedPaymentInfo?.paymentSteps.map(
                (step) => ({
                  title: step.title,
                  details: step.descriptions,
                })
              )}
            />
          </>
        )}

        <SizedDiv height={8} />
        {this.viewModel.model?.orders.map((o) => {
          return (
            <>
              <OrderChunkWidget
                info={o.info}
                title={o.title}
                sections={o.items.map((i) => ({
                  type: i.type,
                  products: i.products.map((p) => ({
                    ...p,
                    isReordering:
                      this.viewModel.isLoadingReorderForBagItemId ===
                      p.bagItemId,
                  })),
                  status: i.status,
                  details: i.statusDetail,
                  onReorderClick: (bagItemId: string) => {},
                  onMoreDetailsClick: () => {
                    this.viewModel.loadTracking(
                      this.viewModel.orderId,
                      i.products.map((p) => p.bagItemId)
                    );
                    this.viewModel.openTrackingModal();
                  },
                }))}
              />
              <SizedDiv height={12} />
            </>
          );
        })}

        <OrderTotalPriceWidget
          billingItems={this.viewModel.model?.billing.billingItems}
          title="Total Price"
          total={this.viewModel.model?.billing.total}
        />
        <SizedDiv height={12} />
        <OrderShippingAddressWidget
          title="Shipping Address"
          name={this.viewModel.model?.shippingAddress.name}
          address={this.viewModel.model?.shippingAddress.address}
          email={this.viewModel.model?.shippingAddress.email}
          phoneNumber={this.viewModel.model?.shippingAddress.phoneNumber}
        />

        {!isResellerDomain() && this.viewModel.model?.unfinishedPaymentInfo && (
          <>
            <SizedDiv height={12} />
            <div
              role="button"
              style={{
                padding: 24,
                width: "100%",
                fontSize: 12,
                background: "white",
              }}
              onClick={() => this.viewModel.cancelThisOrder()}
            >
              {this.viewModel.isCancelingOrder ? (
                <LoadingWidget />
              ) : (
                "Cancel order"
              )}
            </div>
          </>
        )}
        <SizedDiv height={12} />
        <OrderPaymentInfo
          cardNumberDetail={this.viewModel.model?.paymentInfo.cardNumberDetail}
          title="Payment Method"
          paymentMethodLogo={this.viewModel.model?.paymentInfo.imageUrl}
          paymentMethodName={
            this.viewModel.model?.paymentInfo.paymentMethodName
          }
          headline={this.viewModel.model?.paymentInfo.importantMessages.title}
          messages={
            this.viewModel.model?.paymentInfo.importantMessages.messages
          }
        />
        <SizedDiv height={12} />
        <NeedHelpWidget />
      </Scaffold>
    );
  }
}

// export default withSellerId(OrderReceiptScreen)
export default withSellerId(OrderReceiptPage);
