import ic_current_location from "../../../Assets/Images/ic_current_location.svg";
import ic_location from "../../../Assets/Images/ic_pinpoint_grey.svg";

import React from "react"

export interface LocationSearchResultCellWidgetProps {
    title: string;
    subtitle: string;
    coordinate: {
        longitude: number;
        latitude: number;
    };
    isCurrentLocation: boolean;
    onSelected: (location: {
        label: string;
        latitude: number;
        longitude: number;
    }) => void;
}

export default class LocationSearchResultCellWidget extends React.Component<LocationSearchResultCellWidgetProps> {
    render() {
        return <>
            <button key={`${this.props.coordinate.longitude}${this.props.coordinate.latitude}`} id="LocationSearchResultCell" onClick={() => {
                
                this.props.onSelected({
                    label: this.props.subtitle,
                    latitude: this.props.coordinate.latitude,
                    longitude: this.props.coordinate.longitude
                })
            }}>
                <img src={this.props.isCurrentLocation ? ic_current_location : ic_location} alt="" />
                <div>
                    <p>{this.props.title}</p>
                    <p>{this.props.subtitle}</p>
                </div>
            </button>
            <div id="LocationSearchResultSeparator"/>
        </>;
    }
}