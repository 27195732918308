import { getDeviceModel, isResellerDomain } from "../Utils";
import BaseResponse from "./BaseResponse";
import { Endpoints } from "./Endpoints";
import { v4 as uuidv4 } from "uuid";

export enum ContentType {
  json = "application/json; charset=utf-8",
  form = "application/x-www-form-urlencoded",
}

export enum HttpMethod {
  get = "GET",
  post = "POST",
  put = "PUT",
  delete = "DELETE",
}

export function requestAPI<T extends {}, U>(
  endpoint: Endpoints,
  args: {
    httpMethod?: HttpMethod;
    contentType?: ContentType;
    parameters?: T;
    onSuccess: (response: BaseResponse<U>) => void;
    onFailure: (error: any, errorCode?: number) => void;
    delay?: number;
  }
): AbortController {
  const timeOutMilis = 30000;
  const timeOutController = new AbortController();
  const timoOutControllerId = setTimeout(
    () => timeOutController.abort(),
    timeOutMilis
  );
  const host =
    process.env.REACT_APP_ENV === "production"
      ? "https://app.thefepi.com/"
      : "https://staging.thefepi.com/";
  const fpAuth = localStorage.fpAuth;

  const { delay = 0 } = args;

  let body: any;

  if (args.parameters) {
    if ((args.contentType ?? ContentType.json) === ContentType.json) {
      let paramWithFpAuth: any = {};

      for (const key in args.parameters) {
        paramWithFpAuth[key] = args.parameters[key as keyof T];
      }

      if (fpAuth) {
        paramWithFpAuth["fpAuth"] = fpAuth;
      }

      body = JSON.stringify(paramWithFpAuth);
    } else {
      let queryString = "";

      for (const key in args.parameters) {
        if (queryString !== "") {
          queryString += "&";
        }

        queryString += `${key}=${
          args.parameters[key as keyof typeof args.parameters]
        }`;
      }

      if (fpAuth) {
        if (queryString !== "") {
          queryString += "&";
        }

        queryString += `fpAuth=${fpAuth}`;
      }

      body = queryString;
    }
  } else {
    if (fpAuth) {
      if ((args.contentType ?? ContentType.json) === ContentType.json) {
        body = JSON.stringify({ fpAuth: fpAuth });
      } else {
        body = `fpAuth=${fpAuth}`;
      }
    } else {
      body = "";
    }
  }

  try {
    fetch(`${host}${endpoint}`, {
      method: args.httpMethod ?? "POST",
      headers: {
        "Content-Type": args.contentType ?? ContentType.json,
        "X-AppKey":
          "eyJpdiI6Im9ySEUvR0owSjdVL2M1V3Y5emZzOUE9PSIsInZhbHVlIjoibGJ3Rk1qNWhTbGRtOFJqSmpVZU9xZz09IiwibWFjIjoiYTc1NjcwNjgyMDkzODNlOTg3NzY1NzI3MGVjMzlkNzk4M2FiMzU4NmFiMWI0Y2Q3NTEwNDRkZWEzNDFmYzMyMSJ9",
        Authorization: "Basic dGhlZmVwaTohQCNhcHBzMjAyMVRI",
      },
      body: ensureDeviceProperties(body),
      signal: timeOutController.signal,
    })
      .then(
        (response) => {
          if (response.status === 401) {
            localStorage.removeItem("fpAuth");
            if (!isResellerDomain()) {
              window.location.href = "/Login";
              return;
            } else {
              return response;
            }
          }

          return response.json();
        },
        (error) => {
          if (typeof error !== "string") {
            if (
              error instanceof DOMException &&
              error.message === "The user aborted a request."
            ) {
              args.onFailure("ABORT_REQUEST");
            } else {
              args.onFailure("Please try again");
            }
          } else {
            args.onFailure(error);
          }

          clearTimeout(timoOutControllerId);
        }
      )
      .then((response: any) => {
        return new Promise((resolve) =>
          setTimeout(() => resolve(response), delay)
        );
      })
      .then(
        (response: any) => {
          if (response.newFpAuth) {
            localStorage.setItem("fpAuth", response.newFpAuth);
          }

          if (response.result) {
            args.onSuccess(response);

            clearTimeout(timoOutControllerId);
          } else {
            if (
              response.message.includes("Product Not found") &&
              !isResellerDomain()
            ) {
              args.onFailure(
                response.message + `. BrandId: ${response.data.brandId}`,
                response.resultCd
              );
            } else {
              args.onFailure(response.message, response.resultCd);
            }

            clearTimeout(timoOutControllerId);
          }
        },
        (error) => {
          if (typeof error === "string") {
            args.onFailure(error);
          } else {
            args.onFailure(
              "Please try again later, there is a problem from our server."
            );
          }

          clearTimeout(timoOutControllerId);
        }
      );
  } catch (error) {
    if (typeof error !== "string") {
      args.onFailure("Please try again");
    } else {
      args.onFailure(error);
    }

    clearTimeout(timoOutControllerId);
  }

  return timeOutController;
}

function ensureDeviceProperties(body: string) {
  let obj;

  try {
    obj = JSON.parse(body);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return body; // Return the original body if it's not a valid JSON
  }

  if (!obj.deviceId) {
    // get device id
    var deviceId = getDeviceId();
    if (!deviceId) deviceId = uuidv4();

    // set device id
    setDeviceId(deviceId);

    obj.deviceId = deviceId;
  }

  if (!obj.deviceModel) {
    obj.deviceModel = getDeviceModel(2);
  }

  if (isResellerDomain()) {
    if (!obj.userType) {
      obj.userType = "customer_semo";
    }
  }

  return JSON.stringify(obj);
}

function setDeviceId(deviceId: string): void {
  try {
    localStorage.setItem("uuid", deviceId);
    // console.log('Device ID stored successfully.');
  } catch (error) {
    console.error("Error storing device ID:", error);
  }
}

export function getDeviceId(): string | null {
  try {
    const deviceId = localStorage.getItem("uuid");
    if (deviceId) {
      // console.log('Device ID retrieved successfully.');
      return deviceId;
    } else {
      // console.log('Device ID not found in localStorage.');
      const newDeviceId = uuidv4();
      setDeviceId(newDeviceId);
      return newDeviceId;
    }
  } catch (error) {
    console.error("Error retrieving device ID:", error);
    return null;
  }
}
