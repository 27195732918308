import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import { useParams } from "react-router-dom";
import SizeChartViewModel from "./SizeChartViewModel";
import GoBackWidget from "./Widgets/GoBackWidget";
import { Table } from "react-bootstrap";
import "./SizeChart.css";
import ViewController from "../../CommonComponents/Base/ViewController";
import { mixpanelManager } from "../../Analytics/Mixpanel/Common";

export default function SizeChartScreen() {
  let { brandId } = useParams();
  let { flag } = useParams();
  return <SizeChartPage brandId={brandId ?? ""} flag={flag ?? ""} />;
}

class SizeChartPage extends ViewController<
  { brandId: string; flag: string },
  SizeChartViewModel
> {
  constructor(props: { brandId: string; flag: string }) {
    super(props, new SizeChartViewModel(props.brandId));
    this.viewModel.loadPreloadingProps();

    mixpanelManager.trackPageView("SizeChart");
  }

  render() {
    const sizeData = this.viewModel.sizeData;
    return (
      <Scaffold
        isShowLoading={this.viewModel.isLoading}
        showSnackbar={this.viewModel.snackbarMessage}
        showErrorModel={this.viewModel.errorModel}
      >
        <div className="container" id="SizeChart">
          {this.props.flag === "2" && <GoBackWidget />}
          <div className="row">
            <div className="col text-left">
              <br></br>
              <h4>{sizeData?.brand_name}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <div className="table-wrapper">
                <br></br>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th scope="col">CM</th>
                      <th scope="col">EU</th>
                      <th scope="col">UK</th>
                      <th scope="col">US</th>
                      <th scope="col">BR</th>
                    </tr>
                  </thead>
                  {sizeData?.brand_name !== null ? (
                    <tbody>
                      {sizeData?.sizeChart.map((d, index) => (
                        <tr key={index}>
                          <td>{d.cm}</td>
                          <td>{d.euro}</td>
                          <td>{d.uk}</td>
                          <td>{d.us}</td>
                          <td>{d.br}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan={5}>No Information Yet</td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </div>
            </div>
          </div>
        </div>

        <ChannelChatPopup />
      </Scaffold>
    );
  }
}
