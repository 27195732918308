import React from "react";
import "./SearchBarWidget.css";
import chevronLeftIcon from "../../../../Assets/Images/ic_chevron_left.svg";
import searchIcon from "../../../../Assets/Images/ic_search_black.svg";

export type SearchBarWidgetProps = {
  value?: string;
  onValueChange?: (value: string) => void;
  onCancelClick?: () => void;
  onBackClick?: () => void;
  placeholder?: string;
};

export default class SearchBarWidget extends React.Component<SearchBarWidgetProps> {
  render() {
    return (
      <div id="SearchBarWidgetContainer">
        <button
          id="SearchBarBackButton"
          onClick={() => {
            window.history.back();
          }}
        >
          <img
            src={chevronLeftIcon}
            id="SearchBarBackIcon"
            alt="back to previous screen"
          />
        </button>
        <div id="SearchBarWidgetInputContainer">
          <div id="SearchBarSearchIconContainer">
            <img src={searchIcon} id="SearchBarSearchIcon" alt="search icon" />
          </div>
          <input
            id="SearchBarWidgetInput"
            value={this.props.value}
            onChange={(e) => {
              this.props.onValueChange?.(e.target.value);
            }}
            placeholder={this.props.placeholder}
          />
        </div>
        {/* <button id="SearchBarCancelText">Cancel</button> */}
      </div>
    );
  }
}
