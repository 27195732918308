import React from "react";
import "./CheckoutButtonWidget.css";
import ButtonWidget, {
  ButtonWidgetType,
} from "../../../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import { getResellerPhoneNumber, isResellerDomain } from "../../../../CommonComponents/Utils";
import ic_chat from "../../../../Assets/Images/ic_chat.svg"

export type CheckoutButtonProps = {
  isDisabled?: boolean;
  onClick?: () => void;
  resellerLink?: string;
};

export default class CheckoutButton extends React.Component<CheckoutButtonProps> {
  render() {
    const { onClick = () => {} } = this.props;

    return (
      <div id="CheckoutButtonWidget" className="container">
        {!isResellerDomain() ?
        <div
          className="col-2 text-center paddingtop20"
          style={{ zIndex: 99 }}
          data-bs-toggle="modal"
          data-bs-target="#ChatModal"
        >
          <img
            width="20"
            className="img-fluid"
            src={global.ImgUrl + "shopable/ic-detail-chat@2x.png"}
            alt="gambar1"
          ></img>
        </div>
        : <div className="col-2 text-center" style={{paddingTop: "8px"}}>
              <img alt="chat" src={ic_chat} style={{height: "36px", marginLeft: "12px", marginTop: "8px", marginRight: "8px"}} 
                onClick={() => window.open(`https://wa.me/${getResellerPhoneNumber(this.props.resellerLink)}?text=Halo ${localStorage.getItem("resellerName") || this.props.resellerLink}`, "_blank")}
               />
           </div>
        }
        <div className="col-10 text-center ptop10s">
          <ButtonWidget
            buttonType={ButtonWidgetType.primary}
            isEnabled={!this.props.isDisabled}
            width="calc(100% - 6px)"
            action={onClick}
          >
            Pay
          </ButtonWidget>
        </div>
      </div>
    );
  }
}
