import React from "react";
import SizedDiv from "../../../../CommonComponents/UI/SizedDiv";
import "./OrderPaymentInfo.css";

export type OrderPaymentInfoProps = {
  title?: string;
  paymentMethodLogo?: string;
  paymentMethodName?: string;
  headline?: string;
  messages?: string[];
  cardNumberDetail?: string;
};

export default class OrderPaymentInfo extends React.Component<OrderPaymentInfoProps> {
  render(): React.ReactNode {
    const {
      title,
      paymentMethodLogo,
      paymentMethodName,
      headline,
      cardNumberDetail,
      messages = [],
    } = this.props;

    return (
      <div className="OrderPaymentInfoContainer">
        <div className="OrderPaymentInfoHeader">{this.props.title}</div>
        <div style={{ paddingLeft: 24, paddingRight: 24, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* <SizedDiv height={12} />
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={paymentMethodLogo}
              style={{ height: 24, width: 52, objectFit: "contain" }}
            />
            <div
              className="OrderPaymentInfoMessagesHeadline"
              style={{ marginLeft: 12, fontSize: 12 }}
            >
              {cardNumberDetail}
            </div>
          </div> */}
          <p className="OrderPaymentInfoPaymentMethodName">
            {paymentMethodName}
          </p>
          <img
              src={paymentMethodLogo}
              style={{ height: 28, width: 56, objectFit: "contain" }}
            />
          {/* <div className="OrderPaymentInfoMessagesContainer">
            <div className="OrderPaymentInfoMessagesHeadline">{headline}</div>
            <SizedDiv height={8} />
            {messages.map((m) => (
              <div className="OrderPaymentInfoMessages">{m}</div>
            ))}
          </div> */}
        </div>
      </div>
    );
  }
}
