import { eventManager } from "../../Analytics/Events/Common";
import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowErrorModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { ShareUrlModel } from "../../CommonComponents/UI/ShareUrlPopUp/Models/ShareUrlModel";
import { getPreloadProps } from "../../CommonComponents/Utils";
import { ShowCaseDetailResponse } from "./Models/ShowCaseDetailResponse";

export default class ShowCaseDetailViewModel extends ViewModel {
    id: string;
    isCreator: boolean = false;
    creatorId: string = "";
    numberInCart: number = 0;
    response?: ShowCaseDetailResponse;
    isLoading: boolean = false;
    errorMessage?: ShowErrorModel;
    shareUrlModel?: ShareUrlModel;

    constructor(id: string) {
        super();
        this.id = id;
    }

    loadPreloadingProps() {
        this.emit(() => { 
            this.isLoading = true;
        });

        getPreloadProps({
            onSuccess: (isCreator, creatorId, numberInCart) => {
                this.emit(() => {
                    this.isCreator = isCreator;
                    this.creatorId = creatorId;
                    this.numberInCart = numberInCart;
                });

                this.getContents();
            },
            onFailure: error => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps(),
                    };
                });
            },
        });
    }

    getContents() {
        this.emit(() => this.isLoading = true);

        requestAPI(Endpoints.showCaseDetail, {
            parameters: {
                showcaseId: this.id
            },
            onSuccess: (response: BaseResponse<ShowCaseDetailResponse>) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.response = response.data;
                })
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error, 
                        onRetryButtonTapped: () => this.getContents()
                    }
                });
            }
        })
    }

    onShareLink() {
        eventManager.flush();
        eventManager.add({
          content_type: "showcase",
          item_id: "showcase_" + this.id
        })
        this.emit(() => {
            this.shareUrlModel = new ShareUrlModel({
                urlString: window.location.href
            });
        });
    }
}