import React from "react";
import OrderReceiptResponse from "../Models/OrderReceiptResponse";

type OrderReceiptAddressWidgetProps = {
    orderReceiptData: OrderReceiptResponse | null
};

export default class OrderReceiptAddressWidget extends React.Component<OrderReceiptAddressWidgetProps> {
    render() {
        const orderReceiptData = this.props.orderReceiptData;

        return(
            <div className="row">
                <div className="col no-padding">
                    <div className='box_white_cart'>
                        <div className="border_bottom">
                            <p><b>Shipping Address</b></p>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <br></br>
                                <p className="font12"><b>{orderReceiptData?.shippingAddress.name}</b></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <p className="font12">{orderReceiptData?.shippingAddress.address}</p>
                            </div>
                        </div>
                        <div className="row mtopm10">
                            <div className='col-12'>
                                <p className="font12">{orderReceiptData?.shippingAddress.phone}</p>
                            </div>
                        </div>
                        <div className="row mtopm10">
                            <div className='col-12'>
                                <p className="font12">{orderReceiptData?.shippingAddress.email}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}