import React from "react";
import {
  convertToRupiah,
  isResellerDomain,
} from "../../../CommonComponents/Utils";
import ProductDetailResponse from "../Models/ProductDetailResponse";
import ProductDetailBadgeWidget from "./ProductDetailBadgeWidget";
import ic_share from "../../../Assets/Images/ic_share_box.svg";
import { mixpanelManager } from "../../../Analytics/Mixpanel/Common";

type ProductDetailMainInformationWidgetProps = {
  mainInformation: ProductDetailResponse | null;
  onShareLink?: () => void;
};

export default class ProductDetailMainInformationWidget extends React.Component<ProductDetailMainInformationWidgetProps> {
  render() {
    const mainInformation = this.props.mainInformation;

    return (
      <div id="ProductMainInformationContainer">
        <div style={{ display: "flex", alignItems: "center" }}>
          <p className="productName" style={{ flex: 1 }}>
            {mainInformation?.productName}
          </p>
          <button onClick={() => this.props.onShareLink?.()}>
            <img src={ic_share} alt="share"></img>
          </button>
        </div>
        <div id="brandNameDiv">
          {isResellerDomain() ? (
            <a>
              <img
                width="14"
                src="https://images.thefepi.com/file/shopable/ic-brand-name.png"
                alt=""
              ></img>
              <span className="brandName">{mainInformation?.brand.name}</span>
            </a>
          ) : (
            <a
              onClick={() =>
                mixpanelManager.brandClick(
                  mainInformation?.brand.id || "",
                  mainInformation?.brand.name || "",
                  "ProductDetail"
                )
              }
              href={"/Brand/" + mainInformation?.brand.id}
            >
              <img
                width="14"
                src="https://images.thefepi.com/file/shopable/ic-brand-name.png"
                alt=""
              ></img>
              <span className="brandName">{mainInformation?.brand.name}</span>
            </a>
          )}
          {(this.props.mainInformation?.isNew ||
            this.props.mainInformation?.isSoldOut ||
            (this.props.mainInformation?.badgeUrls.length ?? 0) > 0) && (
            <ProductDetailBadgeWidget
              productDetail={this.props.mainInformation}
              width={40}
            />
          )}
          <div className="list-inline" id="priceDiv">
            <span className="list-inline-item realPrice">
              {convertToRupiah(mainInformation?.price.netPrice)}
            </span>
            {mainInformation?.price.discount !== 0 && (
              <>
                <span className="list-inline-item netPrice">
                  {convertToRupiah(mainInformation?.price.realPrice)}
                </span>
                <span className="list-inline-item discount">
                  {mainInformation?.price.discount} %
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
