import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import PointResponse from "./Models/PointResponse";
import { getLocationQueries, getPreloadProps } from "../../CommonComponents/Utils";
import $ from "jquery";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import ViewModel from "../../CommonComponents/Base/ViewModel";

export default class PointViewModel extends ViewModel {
    pointData : PointResponse | null = null;
    isLoading: boolean = true;
    isLoadingButtonSubmit: boolean = false;
    errorModel: ShowErrorModel | null = null;
    errorSnackBarModel: ShowSnackbarModel | null = null;
    errorMessage: string | null = null;
    navBarTransparency: number = 1;
    numberInCart: number = 0;
    snackbarMessage: ShowSnackbarModel | null = null;
    maxPointUse: number = 0;
    maxPointPercentage: number = 0;
    eligiblePoint: number = 0;
    orderSubtotal: number = 0;

    constructor() {
      super();
      const queries = getLocationQueries();
      this.orderSubtotal = parseInt(queries["orderSubtotal"] || "0")
      this.maxPointPercentage = parseInt(queries["maxPointPercentage"] || "0")
      this.maxPointUse = parseInt(queries["maxPointUse"] || "0")
      this.eligiblePoint = parseInt(queries["eligiblePoint"] || "0")
    }

    loadPreloadingProps() {
        this.isLoading = true;
        this.emit(() => {});
        

        getPreloadProps({
            onSuccess: (_, __, numberInCart) => {
                this.numberInCart = numberInCart;
                this.emit(() => {});

                this.loadPoint();
            },
            onFailure: (error) => {
                this.isLoading = false;
                this.errorModel = {
                    message: error,
                    onRetryButtonTapped: () => this.loadPreloadingProps()
                };

                this.emit(() => {});
            }
        })
    }

    loadPoint() {
        this.isLoading = true;
        this.emit(() => {});

        requestAPI(Endpoints.point, {
            parameters: {
                fpAuth: localStorage.fpAuth
            },
            onSuccess: (response: BaseResponse<PointResponse>) => {
                this.isLoading = false;
                this.pointData = response.data;
                this.emit(() => {});
            },
            onFailure: (error: string) => {
                this.isLoading = false;
                this.errorModel = {
                    message: error,
                    onRetryButtonTapped: () => this.loadPreloadingProps()
                };

                this.emit(() => {});
            }
        })
    }

    handleUseAll = (point: number) => {
        $('#input_point').val(point.toLocaleString());
        localStorage.setItem("point", String(point))
    }

    handleChangePoint = (point: string) => {
        if(Number(point) > Number(this.pointData?.point))
        {
            $('#input_point').val(String(this.maxPointUse.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")));
            localStorage.setItem("point", String(this.pointData?.point).replace(/,/g,''))
        }
        else
        {
            localStorage.setItem("point", point.replace(/,/g,''))
            $('#input_point').val(String(localStorage.point.replace(/\B(?=(\d{3})+(?!\d))/g, ",")));
        }
    }

    onSnackbarIsFinished() {
        this.errorMessage = null;
        this.emit(() => {});
    }
}
