import { ReactNode, useEffect, useRef } from "react";

interface Props {
    children?: ReactNode,
    onVisibilityChanged: (isVisible: boolean) => void
}

export function DivWithIntersectionObserver(props: Props) {
    const ref = useRef(null);

    const {onVisibilityChanged} = props;
    
    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(([entry]) => {
            onVisibilityChanged(entry.isIntersecting);
        });

        if (currentRef) observer.observe(currentRef);
        
        return () => {
            if (currentRef) observer.unobserve(currentRef);
        };
    }, [onVisibilityChanged]);

    return <div ref={ref}>
        {
            props.children
        }
    </div>
}