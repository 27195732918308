import React from "react";
import "./UnfinishedPaymentWidget.css";
import SizedDiv from "../../../../CommonComponents/UI/SizedDiv";
import { convertToRupiah } from "../../../../CommonComponents/Utils";
import CollapsibleViewWidget from "../../../../CommonComponents/UI/CollapsibleView/CollapsibleViewWidget";

export type UnfinishedPaymentWidgetProps = {
  paymentCode?: string;
  paymentAmount?: number;
  paymentCodeImageUrl?: string;
  paymentMethodName?: string;
  paymentMethodLogoUrl?: string;
  paymentVendorName?: string;
  paymentAccountHolderName?: string;
  onCopyText?: (type?: "payment-amount" | "va", text?: string) => void;
};

function UnfinishedPaymentWidget(props: UnfinishedPaymentWidgetProps) {
  const {
    paymentAmount,
    paymentCode,
    paymentCodeImageUrl,
    paymentMethodName,
    paymentAccountHolderName,
    paymentMethodLogoUrl,
    onCopyText,
    paymentVendorName,
  } = props;

  return (
    <div className="UnfinishedPaymentWidgetContainer">
      <div className="UnfinishedPaymentWidgetHeader">
        {paymentVendorName}
        <div className="UnfinishedPaymentWidgetLogo">
          <img alt={paymentMethodName} src={paymentMethodLogoUrl} />
        </div>
      </div>
      <SizedDiv height={16} />
      {paymentAmount && (
        <div className="UnfinishedPaymentWidgetDetail">
          <div>Payment amount</div>
          <SizedDiv height={4} />
          <div className="UnfinishedPaymentWidgetDetailCopiable">
            <p className="UnfinishedPaymentWidgetDetailContent">
              {convertToRupiah(paymentAmount)}
            </p>
            <button
              onClick={() => {
                onCopyText?.("payment-amount", `${paymentAmount}`);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="8"
                  y="6"
                  width="12"
                  height="15"
                  rx="2"
                  stroke="#894CF5"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 6V5C17 3.89543 16.1046 3 15 3H7C5.89543 3 5 3.89543 5 5V16C5 17.1046 5.89543 18 7 18H8"
                  stroke="#894CF5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <line
                  x1="11.5"
                  y1="10.5"
                  x2="16.5"
                  y2="10.5"
                  stroke="#894CF5"
                  stroke-linecap="round"
                />
                <line
                  x1="11.5"
                  y1="14.5"
                  x2="16.5"
                  y2="14.5"
                  stroke="#894CF5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      <SizedDiv height={16} />
      {paymentCode && (
        <div className="UnfinishedPaymentWidgetDetail">
          <div>{paymentMethodName}</div>
          <SizedDiv height={4} />
          <div className="UnfinishedPaymentWidgetDetailCopiable">
            <p className="UnfinishedPaymentWidgetDetailContent">
              {paymentCode}
            </p>
            <button
              onClick={() => {
                onCopyText?.('va', `Payment code copied.`);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="8"
                  y="6"
                  width="12"
                  height="15"
                  rx="2"
                  stroke="#894CF5"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 6V5C17 3.89543 16.1046 3 15 3H7C5.89543 3 5 3.89543 5 5V16C5 17.1046 5.89543 18 7 18H8"
                  stroke="#894CF5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <line
                  x1="11.5"
                  y1="10.5"
                  x2="16.5"
                  y2="10.5"
                  stroke="#894CF5"
                  stroke-linecap="round"
                />
                <line
                  x1="11.5"
                  y1="14.5"
                  x2="16.5"
                  y2="14.5"
                  stroke="#894CF5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
          <SizedDiv height={4} />
          <p
            className="UnfinishedPaymentWidgetDetailContent"
            style={{ fontSize: 14 }}
          >
            {paymentAccountHolderName}
          </p>
        </div>
      )}
      <SizedDiv height={16} />
      {paymentCodeImageUrl && <CollapsibleViewWidget
        renderHandle={({ isOpen, onClick }) => (
          <div
            role="button"
            style={{
              padding: "0px 24px 0px 24px",
              color: "#364DFF",
              fontWeight: 700,
              fontSize: 14,
              display: "flex",
            }}
            onClick={onClick}
          >
            {isOpen ? "Hide barcode" : "Show barcode"}
            <div style={{ marginLeft: 8 }}>
              {isOpen ? (
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 7L7 0.999999L13 7"
                    stroke="#364DFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1L7 7L1 0.999999"
                    stroke="#364DFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
        )}
      >
        <img src={paymentCodeImageUrl} style={{ width: "100%" }} />
      </CollapsibleViewWidget>}
      <SizedDiv height={24} />
    </div>
  );
}

export default UnfinishedPaymentWidget;
