import React from "react";
import YouTube from "react-youtube";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductSummaryItemCellWidget from "../../../CommonComponents/UI/Cells/ProductSummaryItemCellWidget";
import { VideoPlayer } from "../../../CommonComponents/UI/Cells/VideoPlayer";
import { VideoPlayerWithController } from "../../../CommonComponents/UI/Cells/VideoPlayerWithController";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { IndexIndicator } from "../../../CommonComponents/UI/IndexIndicator/IndexIndicatorWidget";
import { getLocationQueries, getScreenSizeWithMaxWidth } from "../../../CommonComponents/Utils";
import { ShowCaseDetailFeedModel, ShowCaseDetailFeedTextContentModel } from "../Models/ShowCaseDetailFeedModel";
import ShowCaseDetailTextContent from "./ShowCaseDetailTextContentWidget";

interface Props {
    model: ShowCaseDetailFeedModel;
    id?: string;
}

interface State {
    showingCarouselIndex: number;
}

export default class ShowCaseDetailFeedWidget extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showingCarouselIndex: 0
        };
    }

    render() {
        const screenWidth = getScreenSizeWithMaxWidth().width;
        const model = this.props.model;
        const textContent = model.textContent;
        const assetContent = model.asset;
        const carouselItems = model.carouselItems;
        const products = model.products;
        const youtubeVideoId = model.youtubeVideoId;

        if (youtubeVideoId) {
            return <div style={{
                marginTop: "42px",
                marginBottom: "42px",
                width: "100%",
                height: `${getScreenSizeWithMaxWidth().width * 9 / 16}px`
            }}>
                <YouTube 
                    videoId={youtubeVideoId}
                    opts={{
                        width: "100%",
                        height: `${getScreenSizeWithMaxWidth().width * 9 / 16}px`
                    }}
                />
            </div>
        }

        if (products.length > 0) {
            return <div id="ShowCaseDetailProductsSection">
                {
                    products.map((model) => 
                        <ProductSummaryItemCellWidget 
                            model={model} 
                            cellWidth={(screenWidth - 44.0) / 2.0}
                            theme={"DARK"}
                        />
                    )
                }
            </div>
        }

        if (carouselItems.length > 0) {
            const height = screenWidth / 1.5;

            return <div 
                id="ShowCaseDetailCarouselSection"
                style={{
                    height: `${height}px`
                }}
            >
                <Swiper
                    slidesPerView={1}
                    onSlideChangeTransitionStart={
                        (swiper) => this.setState({
                            showingCarouselIndex: swiper.activeIndex
                        })
                    }
                >
                    {
                        carouselItems.map((model) => 
                            <SwiperSlide key={`ShowCaseDetailCarouselCell${model.assetUrl}`}>
                                <div style={{height:`${height}px`}}>
                                    <ImageWidget imageUrl={model.assetUrl}/>
                                </div>
                            </SwiperSlide>
                        )
                    }
                </Swiper>
                <div>
                    <div style={{
                        backgroundColor: getLocationQueries()["theme"] === "LIGHT" ? "#FFFFFF66" : "#3E3E3E66"
                    }}>
                        <IndexIndicator 
                            totalOfIndices={carouselItems.length} 
                            showingIndex={this.state.showingCarouselIndex} 
                            color={getLocationQueries()["theme"] === "LIGHT" ? "#3E3E3E" : "white"}
                        />
                    </div>
                </div>
            </div>
        }

        if (assetContent) {
            return <div id="ShowCaseDetailAssetSection" className={this.props.id} style={{
                height: `${getScreenSizeWithMaxWidth().width / assetContent.ratio}px`,
                position: "relative"
            }}
            >
                {
                    assetContent.assetType !== "VIDEO" ?
                        <ImageWidget imageUrl={assetContent.assetUrl}/> :
                        assetContent.isAutoPlay === false ?
                            <VideoPlayerWithController 
                                videoUrl={assetContent.assetUrl} 
                                videoThumbUrl={assetContent.videoThumbnailUrl}
                                isAudioAvailable={false}
                                isPlaying={false}
                                loop={assetContent.loop ?? false}
                            >
                                {
                                    textContent &&
                                    <div 
                                        className="TextPositionContainer"
                                        style={{
                                            top: textContent.position === "TOP" ? "-400px" : undefined,
                                            bottom: textContent.position !== "TOP" ? "-400px" : undefined,
                                            background: `linear-gradient(to ${textContent.position === "TOP" ? "bottom" : "top"}, #00000088, #00000000)`
                                        }}
                                    >
                                        <ShowCaseDetailTextContent textContent={textContent} backgroundColor="transparent" />
                                    </div>
                                }
                            </VideoPlayerWithController> :
                            <VideoPlayer 
                                videoUrl={assetContent.assetUrl}
                                videoThumbUrl={assetContent.videoThumbnailUrl} 
                                loop={assetContent.loop ?? false}
                            />
                    
                }
                {
                    (textContent && !(assetContent.assetType === "VIDEO" && assetContent.isAutoPlay === false) && this._isTextContentNotEmpty(textContent)) &&
                    <div 
                        className="TextPositionContainer"
                        style={{
                            top: textContent.position === "TOP" ? "-400px" : undefined,
                            bottom: textContent.position !== "TOP" ? "-400px" : undefined,
                            background: assetContent.isTextBackgroundVisible ? `linear-gradient(to ${textContent.position === "TOP" ? "bottom" : "top"}, ${textContent.backgroundColorHex}88, ${textContent.backgroundColorHex}00)` : undefined,
                            color: textContent.textColorHex ?? "white"
                        }}
                    >
                        <ShowCaseDetailTextContent textContent={textContent} backgroundColor="transparent" />
                    </div>
                }
        </div>
        }
        
        if (textContent && this._isTextContentNotEmpty(textContent)) {
            return <ShowCaseDetailTextContent textContent={textContent} backgroundColor={textContent.backgroundColorHex ?? "black"} />
        }

        return <></>;
    }

    _isTextContentNotEmpty(model: ShowCaseDetailFeedTextContentModel): boolean {
        return (model.title?.length ?? 0) > 0 || (model.subtitle?.length ?? 0) > 0;
    }
}