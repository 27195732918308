import React from "react";
import "./AlertWidget.css";

export type AlertWidgetProps = {
  type?: "normal" | "danger";
  text?: string;
};

export default class AlertWidget extends React.Component<AlertWidgetProps> {
  getClassnameForType(type?: "normal" | "danger") {
    switch (type) {
      case "normal":
        return "AlertWidgetContainerNormalColors";
      default:
        return "";
    }
  }

  render(): React.ReactNode {
    return (
      <div className={this.getClassnameForType(this.props.type)}>
        <div className={`AlertWidgetContainer`}>{this.props.text}</div>
      </div>
    );
  }
}
