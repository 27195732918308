import React from "react";
import ProductDetailResponse from "../Models/ProductDetailResponse";
import ic_badge_soldout from '../../../Assets/Images/badge-soldout.svg';

type ProductDetailBadgeWidgetProps = {
    width: number,
    productDetail: ProductDetailResponse | null
};

export default class ProductDetailBadgeWidget extends React.Component<ProductDetailBadgeWidgetProps> {
    render() {
        const productDetail = this.props.productDetail;

        const badgeUrls = [productDetail?.isSoldOut && ic_badge_soldout, ...(this.props.productDetail?.badgeUrls || [])]

        return(
            <div style={{marginTop:"16px"}}>
                <ul className="list-inline">
                    {productDetail?.isNew &&
                        <li className="list-inline-item">
                            <span className="newBadge">NEW</span>
                        </li>
                    }              
                    {
                        badgeUrls.map((badgeUrl, index) => {
                            return <li className="list-inline-item" key={index}>
                                <img height={20} src={badgeUrl || ""} key={`${index}_image`} alt=""/>
                            </li>;
                        })
                    }
                    <li className="list-inline-item"></li>
                </ul>
            </div>
        );
    }
}