import { AnalyticEvent } from "../AnalyticEvent";
import { EventState } from "../EventState";
import { GA4BaseEvent } from "./GA4BaseEvent";

export class SelectPromotionEvent
  extends GA4BaseEvent
  implements AnalyticEvent
{
  payload: EventState;
  name: string;

  constructor(args: EventState) {
    super();
    this.payload = args;
    this.name = "SELECT_PROMOTION_GA4";
  }

  exec(): void {
    this.fire("select_promotion", {
      promotion_id: this.payload.coupon?.id,
      promotion_name: this.payload.coupon?.name
    });
  }
}
