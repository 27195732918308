import React from "react";

type ErrorPopupMessageProps = {
    showModal?: boolean,
    isEnabled?: boolean,
    Message?: string | null,
    closeModal?: () => void
}

export default class ErrorPopupMessageWidget extends React.Component<ErrorPopupMessageProps, {}> {
    render() {
        return (
            <>
                <div className={this.props.showModal ? "modal-backdrop fade show" : ""}></div>
                <div className={this.props.showModal ? "modal fade show" : "modal fade"} style={this.props.showModal ? {display:'block'} : {display:'none'}} id="ApiMessageModal" aria-labelledby="ErrorModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <button onClick={this.props.closeModal} type="button" className="btn-close closeBtn" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className="modal-body">
                                <p className="message"><b>{this.props.Message}</b></p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}