import React from "react";
import SizedDiv from "../../../CommonComponents/UI/SizedDiv";
import "./KalkulatorWidget.css";

export type KalkulatorWidgetProps = {
  won?: string;
  idr?: string;
  onIdrChange?: (value: number) => void;
  onWonChange?: (value: number) => void;
  onResetClick?: () => void;
  onConvertClick?: () => void;
};

export default class KalkulatorWidget extends React.Component<KalkulatorWidgetProps> {
  render(): React.ReactNode {
    return (
      <div className="KalkulatorWidgetContainer">
        <div className="KalkulatorWidgetSectionTitle">Kalkulator</div>
        <SizedDiv height={12} />
        <div className="KalkulatorWidgetCurrencyInputContainer">
          <div className="KalkulatorWidgetCurrencyLabel">KRW</div>
          <input
            className="KalkulatorWidgetCurrencyInput"
            inputMode="numeric"
            value={this.props.won}
            onChange={(e) => this.props.onWonChange?.(parseInt(e.target.value || '0'))}
          />
        </div>
        <SizedDiv height={12} />
        <div className="KalkulatorWidgetCurrencyInputContainer">
          <div className="KalkulatorWidgetCurrencyLabel">IDR</div>
          <input
            className="KalkulatorWidgetCurrencyInput"
            value={this.props.idr}
            disabled
            onChange={(e) => this.props.onIdrChange?.(parseInt(e.target.value))}
          />
        </div>
        <SizedDiv height={12} />
        <div className="KalkulatorWidgetCurrencyInputContainer">
          <div className="KalkulatorWidgetCurrencyLabel"></div>
          <div
            style={{ flexGrow: 1, marginLeft: 12, display: "flex", gap: 12 }}
          >
            <div style={{ flexGrow: 1 }}>
              <button
                className="KalkulatorWidgetOulinedButton"
                onClick={() => {
                  this.props.onResetClick?.();
                }}
              >
                Reset
              </button>
            </div>
            <div style={{ flexGrow: 1 }}>
              <button
                className="KalkulatorWidgetFilledButton"
                onClick={() => {
                  this.props.onConvertClick?.();
                }}
              >
                Convert
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
