import { AnalyticEvent } from "../AnalyticEvent";
import { EventState } from "../EventState";
import { GA4BaseEvent } from "./GA4BaseEvent";


export class AddShippingInfoEvent extends GA4BaseEvent implements AnalyticEvent {
  payload: EventState;
  name: string;

  constructor(args: EventState) {
    super();
    this.payload = args;
    this.name = "ADD_SHIPPING_INFO_GA4"
  }

  exec(): void {
    this.fire("add_shipping_info", {
      value: this.payload.shipping?.value || -1,
      currency: this.payload.currency || "IDR",
      shipping_tier: this.payload.shipping?.tier
    });
  }
}
