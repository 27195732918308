import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { standarizeThefepiLink } from "../../../CommonComponents/Utils";
import StoreSectionFeedModel from "../Models/StoreSectionFeedModel";

import "./StoreSectionFeed.css";
import { mixpanelManager } from "../../../Analytics/Mixpanel/Common";

export class StoreSmallBannerSectionWidget extends React.Component<{
  screenWidth: number;
  model: StoreSectionFeedModel;
}> {
  render() {
    const model = this.props.model.contents;
    const banners = model.banners;
    const cellWidth = this.props.screenWidth / 1.6;

    return (
      <div id="StoreSmallBannerSectionFeedSectionContainer">
        <p>{model.sectionTitle}</p>
        <ScrollContainer>
          <div id="StoreSmallBannerSectionFeedSectionBannersContainer">
            {banners.map((model, index) => (
              <button
                key={`button_${index}`}
                onClick={() => {
                  if (model.deeplink) {
                    mixpanelManager.storeFeedClick({
                      type: "banner",
                      title: "banner_" + model.deeplink,
                      group_name:
                        window.location.href.match(/Store\/([a-zA-Z]+)/)?.[1] ||
                        "HOME",
                      identifier:
                        this.props.model.identifier || "STORE_SMALL_BANNER",
                      screen_name: "STORE",
                    });
                    window.location.href = standarizeThefepiLink(
                      model.deeplink
                    );
                  }
                }}
              >
                <div
                  key={`StoreSmallBannerSection_${index}`}
                  style={{
                    width: `${cellWidth}px`,
                    height: `${(cellWidth * 4) / 5}px`,
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <ImageWidget imageUrl={model.imageUrl} />
                </div>
              </button>
            ))}
          </div>
        </ScrollContainer>
      </div>
    );
  }
}
