import { AnalyticEvent } from "../AnalyticEvent";
import { EventState } from "../EventState";
import { GA4BaseEvent } from "./GA4BaseEvent";

export class RemoveFromCartEvent extends GA4BaseEvent implements AnalyticEvent {
  payload: EventState;
  name: string;

  constructor(args: EventState) {
    super();
    this.payload = args;
    this.name = "REMOVE_FROM_CART_GA4";
  }

  exec(): void {
    this.fire("remove_from_cart", {
      value: this.payload.value || -1,
      currency: this.payload.currency || "IDR",
      items:
        this.payload.items?.map((item) => ({
          item_id: item.id,
          item_name: item.name,
          discount: item.price?.discount,
          item_brand: item.brand,
          item_variant: item.variant,
          price: item.price?.net,
          quantity: item.quantity,
        })) || [],
    });
  }
}
