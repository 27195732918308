import React from "react";
import SizedDiv from "../../../CommonComponents/UI/SizedDiv";
import RatingInputWidget from "../RatingInput/RatingInputWidget";
import "./OrderToReviewWidget.css";

export type OrderToReviewWidgetProps = {
  orderId?: string;
  products?: {
    brandName?: string;
    productName?: string;
    rating?: number;
    productPhotoUrl?: string;
    productVariantName?: string;
    bagId?: string;
  }[];
  onRatingClick?: (
    product: {
      brandName?: string;
      productName?: string;
      rating?: number;
      productPhotoUrl?: string;
      productVariantName?: string;
      bagId?: string;
    },
    givenRating?: number
  ) => void;
  date?: string;
};

function OrderToReviewWidget(props: OrderToReviewWidgetProps) {
  const { orderId, products = [], date, onRatingClick } = props;

  return (
    <div className="OrderToReviewWidgetContainer" id={orderId}>
      <div className="OrderToReviewWidgetContent">
        {products.map((product) => (
          <>
            <div className="OrderToReviewWidgetProductListItem">
              <div className="OrderToReviewWidgetProductPhotoContainer">
                <img
                  className="OrderToReviewWidgetProductPhoto"
                  src={product.productPhotoUrl}
                  alt={product.brandName}
                />
              </div>
              <div className="OrderToReviewWidgetProductDescription">
                <p className="OrderToReviewWidgetBrandName">
                  {product.brandName}
                </p>
                <p className="OrderToReviewWidgetProductName">
                  {product.productName}
                </p>
                <p className="OrderToReviewWidgetProductVariantName">
                  {product.productVariantName}
                </p>
                <SizedDiv height={4} />
                <RatingInputWidget
                  max={5}
                  width={32}
                  height={32}
                  rating={product.rating || 0}
                  onStarClick={(index) => {
                    onRatingClick?.(product, index + 1);
                  }}
                />
              </div>
            </div>
            <SizedDiv height={16} />
          </>
        ))}
      </div>
      <div style={{ borderBottom: "1px solid #E4E4E4" }} />

      <div className="OrderToReviewWidgetFooter">
        <p
          style={{
            fontWeight: 400,
            fontSize: "12px",
          }}
        >
          Order ID : {orderId}({date})
        </p>
      </div>
    </div>
  );
}

export default OrderToReviewWidget;
