import { requestAPI } from "../../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../../CommonComponents/ApiRequest/Endpoints";
import { getPreloadProps } from "../../../CommonComponents/Utils";
import { ProductBadgeType } from "../../../CommonTypes";
import { CuratedProductsResponse } from "../Models/CureatedProductsResponse";
import CuratedProductsRepository from "./CuratedProductsRepository";

export default class CuratedProductsRepositoryImpl implements CuratedProductsRepository {
    getPreloadProps(args: {
        onSuccess: (isCreator: boolean, creatorId: string, numberInCart: number,  mappedProductBadgeUrls: {[key in ProductBadgeType]: string}) => void, 
        onFailure: (error: string) => void
    }) {
        getPreloadProps({
            onSuccess: args.onSuccess,
            onFailure: args.onFailure
        });
    }

    getProducts(args: {
        parameters: {}, 
        onSuccess: (response: BaseResponse<CuratedProductsResponse>) => void, 
        onFailure: (error: string) => void
    }) {
        requestAPI(Endpoints.curatedProducts, {
            parameters: args.parameters,
            onSuccess: args.onSuccess,
            onFailure: args.onFailure
        });
    }
}