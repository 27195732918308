import React from "react";
import "./OrderListItemWidget.css";
import { OrderType } from "../Models/OrderAndDeliveryResponseModel";
import { type } from "os";
import { convertToRupiah } from "../../../CommonComponents/Utils";
import ButtonWidget, {
  ButtonWidgetType,
} from "../../../CommonComponents/UI/ButtonWidget/ButtonWidget";

export type OrderListItemWidgetProps = {
  orderNumberLabel?: string;
  isReAddingToCart?: boolean;
  orderDateLabel?: string;
  badgeUrls?: string[];
  type?: OrderType;
  bagId?: string;
  status?: string;
  secondStatus?: string;
  productId?: string;
  productAttribute?: string;
  createContentCommisionBonusPercentage?: number;
  createContentPointsBonus?: number;
  productImageUrl?: string;
  productBrandName?: string;
  moreInfoLabel?: string;
  attribute?: string;
  productName?: string;
  totalOrderValue?: number;
  warningMessage?: string;
  promotionalMessage?: string;
  reviewPoint?: number;
  onOrderDetailClick?: () => void;
  onPaymentInfoClick?: () => void;
  onReorderClick?: () => void;
  onWriteReviewClick?: () => void;
  onOpenChatClick?: () => void;
  onTrackMyOrderClick?: (bagId?: string) => void;
  whatsappUrl?: string;
};

export default class OrderListItemWidget extends React.Component<OrderListItemWidgetProps> {
  getOrderStatusColorClassname(type?: OrderType): string {
    if (!type) return "";
    switch (type) {
      case OrderType.CANCELLED:
        return "OrderListItemWidgetStatusTypeColorsDanger";
      case OrderType.COMPLETED:
        return "OrderListItemWidgetStatusTypeColorsNormal";
      default:
        return "OrderListItemWidgetStatusTypeColorsWarning";
    }
  }

  renderTrackMyOrderButton(bagId?: string) {
    return (
      <ButtonWidget
        titleColor="#000"
        action={() => {
          this.props.onTrackMyOrderClick?.(bagId);
        }}
        borderColor="#000"
        buttonType={ButtonWidgetType.secondary}
      >
        Track My Order
      </ButtonWidget>
    );
  }

  renderReviewToPointMessage(points: number) {
    return (
      <p style={{ fontSize: 12, fontWeight: 400, color: "#999" }}>
        Review your order and get{" "}
        <span style={{ color: "#FF00CE" }}>
          {convertToRupiah(points).replace("Rp", "")}
        </span>{" "}
        Fepi Points
      </p>
    );
  }

  renderFullCSButton() {
    return (
      <ButtonWidget
        titleColor="#000"
        action={() => {
          this.props.onOpenChatClick?.();
        }}
        borderColor="#000"
        buttonType={ButtonWidgetType.secondary}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginRight: 8 }}
        >
          <path
            d="M10 13V13C10 9.68629 12.6863 7 16 7V7C19.3137 7 22 9.68629 22 13V13"
            stroke="black"
          />
          <rect x="17" y="19" width="3" height="2" rx="1" stroke="black" />
          <path d="M22 18V18C22 19.1046 21.1046 20 20 20V20" stroke="black" />
          <path
            d="M8 15C8 13.8954 8.89543 13 10 13H11V18H10C8.89543 18 8 17.1046 8 16V15Z"
            stroke="black"
          />
          <path
            d="M24 16C24 17.1046 23.1046 18 22 18L21 18L21 13L22 13C23.1046 13 24 13.8954 24 15L24 16Z"
            stroke="black"
          />
          <path
            d="M3 7V21C3 23.2091 4.79086 25 7 25H13.1716C13.702 25 14.2107 25.2107 14.5858 25.5858L16.2929 27.2929C16.6834 27.6834 17.3166 27.6834 17.7071 27.2929L19.4142 25.5858C19.7893 25.2107 20.298 25 20.8284 25H25C27.2091 25 29 23.2091 29 21V7C29 4.79086 27.2091 3 25 3H7C4.79086 3 3 4.79086 3 7Z"
            stroke="black"
          />
        </svg>
        Contact CS
      </ButtonWidget>
    );
  }

  renderChatCSButton() {
    return (
      <button
        onClick={() => {
          this.props.onOpenChatClick?.();
        }}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 13V13C10 9.68629 12.6863 7 16 7V7C19.3137 7 22 9.68629 22 13V13"
            stroke="black"
          />
          <rect x="17" y="19" width="3" height="2" rx="1" stroke="black" />
          <path d="M22 18V18C22 19.1046 21.1046 20 20 20V20" stroke="black" />
          <path
            d="M8 15C8 13.8954 8.89543 13 10 13H11V18H10C8.89543 18 8 17.1046 8 16V15Z"
            stroke="black"
          />
          <path
            d="M24 16C24 17.1046 23.1046 18 22 18L21 18L21 13L22 13C23.1046 13 24 13.8954 24 15L24 16Z"
            stroke="black"
          />
          <path
            d="M3 7V21C3 23.2091 4.79086 25 7 25H13.1716C13.702 25 14.2107 25.2107 14.5858 25.5858L16.2929 27.2929C16.6834 27.6834 17.3166 27.6834 17.7071 27.2929L19.4142 25.5858C19.7893 25.2107 20.298 25 20.8284 25H25C27.2091 25 29 23.2091 29 21V7C29 4.79086 27.2091 3 25 3H7C4.79086 3 3 4.79086 3 7Z"
            stroke="black"
          />
        </svg>
      </button>
    );
  }

  renderCreateContentBonusMessage(points: number, commision: number) {
    return (
      <p style={{ fontSize: 12, fontWeight: 400, color: "#999" }}>
        Create content to earn{" "}
        <span style={{ color: "#FF00CE" }}>
          {convertToRupiah(points).replace("Rp", "")}
        </span>{" "}
        Fepi Points and{" "}
        <span style={{ color: "#FF00CE" }}>{commision}% commision</span>!
      </p>
    );
  }

  renderPaymentInfoButton() {
    return (
      <ButtonWidget
        titleColor="#000"
        action={() => {
          this.props.onPaymentInfoClick?.();
        }}
        borderColor="#000"
        buttonType={ButtonWidgetType.secondary}
      >
        Payment Info
      </ButtonWidget>
    );
  }

  renderToReviewButton() {
    return (
      <ButtonWidget
        titleColor="#000"
        action={() => {
          this.props.onWriteReviewClick?.();
        }}
        borderColor="#000"
        buttonType={ButtonWidgetType.secondary}
      >
        Write Review
      </ButtonWidget>
    );
  }

  renderShopMoreButton() {
    return (
      <ButtonWidget
        titleColor="#000"
        action={() => {
          window.location.href = "/Store";
        }}
        borderColor="#000"
        buttonType={ButtonWidgetType.secondary}
      >
        Shop More
      </ButtonWidget>
    );
  }

  renderReorderButton() {
    return (
      <ButtonWidget
        titleColor="#000"
        action={() => {
          this.props.onReorderClick?.();
        }}
        isLoading={this.props.isReAddingToCart}
        borderColor="#000"
        buttonType={ButtonWidgetType.secondary}
      >
        Reorder
      </ButtonWidget>
    );
  }

  render(): React.ReactNode {
    const {
      orderNumberLabel,
      orderDateLabel,
      type,
      status,
      productBrandName,
      productId,
      productImageUrl,
      moreInfoLabel,
      attribute,
      productAttribute,
      totalOrderValue,
      warningMessage,
      onOpenChatClick,
      onPaymentInfoClick,
      badgeUrls,
      onReorderClick,
      createContentPointsBonus,
      createContentCommisionBonusPercentage,
      productName,
      reviewPoint,
      onOrderDetailClick,
      secondStatus,
    } = this.props;
    return (
      <div className="OrderListItemWidgetContainer">
        <div className="OrderListItemWidgetOrderDateLabel">
          {orderDateLabel}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="OrderListItemWidgetOrderNumberLabel">
            {orderNumberLabel}
          </div>
          {badgeUrls?.map((url) => (
            <img
              src={url}
              alt={status}
              className="OrderListItemWidgetOrderStatusPill"
            />
          ))}
          <button
            onClick={() => onOrderDetailClick?.()}
            style={{ marginLeft: "auto" }}
          >
            <svg
              width="6"
              height="9"
              viewBox="0 0 6 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 0.5L5 4.5L1 8.5"
                stroke="black"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div style={{ display: "flex", marginTop: 4, alignItems: "center" }}>
          <span
            className={`${this.getOrderStatusColorClassname(
              type
            )} OrderListItemWidgetStatus`}
          >
            {status}
          </span>
          <span
            className={`${this.getOrderStatusColorClassname(
              type
            )} OrderListItemWidgetSecondStatus`}
          >
            {secondStatus}
          </span>
        </div>
        <div style={{ display: "flex", marginTop: 12 }}>
          <div className="OrderListItemWidgetProductImageContainer">
            <img
              src={productImageUrl}
              className="OrderListItemWidgetProductImage"
              alt={productBrandName}
            />
          </div>
          <div className="OrderListItemWidgetProductInfoContainer">
            <p className="OrderListItemWidgetBrandName">{productBrandName}</p>
            <p className="OrderListItemWidgetProductName">{productName}</p>
            <p className="OrderListItemWidgetAttribute">{productAttribute}</p>
          </div>
        </div>
        <div style={{ marginTop: 12, display: "flex", alignItems: "center" }}>
          <p className="OrderListItemWidgetMoreInfoLabel">{moreInfoLabel}</p>
          <div
            style={{
              display: "flex",
              marginLeft: "auto",
              alignItems: "center",
            }}
          >
            <p style={{ fontWeight: 400, fontSize: 12, marginRight: 8 }}>
              Order Total{" "}
            </p>
            <p className="OrderListItemWidgetOrderValue">
              {convertToRupiah(totalOrderValue)}
            </p>
          </div>
        </div>
        {type && (
          <div style={{ marginTop: 12, display: "flex", alignItems: "center" }}>
            {[
              OrderType.COMPLETED,
              OrderType.PACKING,
              // OrderType.TO_REVIEW,
              OrderType.TO_PAY,
            ].includes(type) && (
              <div style={{ flexShrink: 0, margin: "0px 16px 0px 16px" }}>
                {this.renderChatCSButton()}
              </div>
            )}

            <div style={{ flex: 1 }}>
              {[OrderType.TO_PAY].includes(type) &&
                this.renderPaymentInfoButton()}
              {[OrderType.CANCELLED].includes(type) &&
                this.renderFullCSButton()}
              {[OrderType.TO_REVIEW].includes(type) &&
                this.renderToReviewButton()}
              {[OrderType.IN_DELIVERY, OrderType.PACKING].includes(type) &&
                this.renderTrackMyOrderButton(this.props.bagId)}
              {[OrderType.COMPLETED].includes(type) &&
                this.renderShopMoreButton()}
            </div>
          </div>
        )}
        <div style={{ marginTop: 12 }}>
          {reviewPoint && this.renderReviewToPointMessage(reviewPoint)}
          {createContentPointsBonus &&
            createContentCommisionBonusPercentage &&
            this.renderCreateContentBonusMessage(
              createContentPointsBonus,
              createContentCommisionBonusPercentage
            )}
          {
            <p className="OrderListItemWidgetWarningMessage">
              {warningMessage}
            </p>
          }
        </div>
      </div>
    );
  }
}
