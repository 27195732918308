import ButtonWidget from "../../../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import SizedDiv from "../../../../CommonComponents/UI/SizedDiv";
import "./NeedHelpWidget.css";

export type NeedHelpWidgetProps = {
  waLink?: string;
};

export default function NeedHelpWidget(props: NeedHelpWidgetProps) {
  return (
    <div className="OrderPaymentInfoContainer">
      <div className="OrderPaymentInfoHeader">Need Help?</div>
      <div className="OrderPaymentInfoDivider" />
      <div style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 24 }}>
        <button className="NeedHelpWidgetButton">
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 9.99829V9.99829C4 6.68458 6.68629 3.99829 10 3.99829V3.99829C13.3137 3.99829 16 6.68458 16 9.99829V9.99829"
              stroke="black"
            />
            <path
              d="M5 14.9983V9.99829H3.875C2.83947 9.99829 2 10.8378 2 11.8733V13.1233C2 14.1588 2.83947 14.9983 3.875 14.9983H5Z"
              stroke="black"
            />
            <path
              d="M15 9.99829L15 14.9983L16.125 14.9983C17.1605 14.9983 18 14.1588 18 13.1233L18 11.8733C18 10.8378 17.1605 9.99829 16.125 9.99829L15 9.99829Z"
              stroke="black"
            />
            <rect
              x="9"
              y="16.9983"
              width="3"
              height="2"
              rx="1"
              stroke="black"
            />
            <path
              d="M16 14.9983V15.4983C16 16.879 14.8807 17.9983 13.5 17.9983H12"
              stroke="black"
            />
          </svg>
          Ask anytime
        </button>
      </div>
    </div>
  );
}
