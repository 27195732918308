import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import SearchBrandsResponse from "./Models/SearchBrandsResponse";

export default class BrandListsViewModel extends ViewModel {
  isLoadingBrands?: boolean = false;
  brands: {
    imageUrl: string;
    name: string;
    id: string;
  }[] = [];
  loadingBrandsErrorMessage?: string;
  query?: string;
  fpAuth?: string;
  totalBrands: number;
  categories?: {
    id: string;
    label: string;
  }[];
  selectedCategoryId?: string;

  constructor() {
    super({
      key: 'BrandListViewModel'
    });
    this.refresh();
    const params = new URLSearchParams(window.location.search);
    this.query = this.query ?? params.get("query") ?? undefined;
    this.fpAuth = localStorage.getItem("fpAuth") || undefined;
    this.totalBrands = 0;
  }

  selectCategoryId(id: string) {
    this.emit(() => {
      this.selectedCategoryId = id;
    });
    this.loadBrands();
  }

  changeQuery(q: string) {
    this.emit(() => {
      this.query = q;
    });
  }

  loadBrands() {
    this.emit(() => {
      this.isLoadingBrands = true;
      this.loadingBrandsErrorMessage = undefined;
    });

    requestAPI(Endpoints.searchBrands, {
      parameters: {
        fpAuth: this.fpAuth,
        categoryId: this.selectedCategoryId,
        // query: this.query,
        // startIndex: this.brands.length - 1 < 0 ? 0 : this.brands.length - 1,
        // limit: 20,
      },
      onSuccess: (response: BaseResponse<SearchBrandsResponse>) => {
        this.emit(() => {
          this.brands = [];
          this.totalBrands = response.data.totalOfItems;
          this.brands = this.brands.concat(response.data.items);
          this.isLoadingBrands = false;
          this.loadingBrandsErrorMessage = undefined;
          this.categories = response.data.category;
        });
      },
      onFailure: (error: string) => {
        this.emit(() => {
          this.isLoadingBrands = false;
          this.loadingBrandsErrorMessage = error;
        });
      },
    });
  }
}
