import React from "react";
import "./LoadingView.css"
import { LoadingWidget } from "./LoadingWidget";

type LoadingViewProps = {
    theme?: "dark" | "light"
}

export class LoadingView extends React.Component<LoadingViewProps, {}> {
    render() {
        return(
            <div id={`LoadingViewBackground${this.props.theme === "dark" ? "Dark" : "Light"}`}>
                <LoadingWidget theme={this.props.theme}/>
            </div>
        );
    }
}
