import React from "react";
import { ShowCaseDetailFeedTextContentModel } from "../Models/ShowCaseDetailFeedModel";

interface Props {
    textContent: ShowCaseDetailFeedTextContentModel;
    backgroundColor: string;
}

export default class ShowCaseDetailTextContent extends React.Component<Props> {
    render() {
        const textContent = this.props.textContent;

        return <div 
            id="ShowCaseDetailTextSection"
            style={{
                textAlign: textContent.textAlignment === "CENTER" ? "center" : "left",
                backgroundColor: this.props.backgroundColor,
                color: textContent.textColorHex ?? "white"
            }}
        >
            {
                textContent.title &&
                <p className="Title">{textContent.title}</p>
            }
            {
                textContent.subtitle &&
                <p className="Subtitle">{textContent.subtitle}</p>
            }
            {
                textContent.description &&
                <p className="Description">{textContent.description}</p>
            }
        </div>;
    }
}