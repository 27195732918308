// JS

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ContentDisplayCell } from "../Widgets/ContentDisplayCell";
import {
  ContentExplorerViewModel,
  ExporeType,
} from "./ContentExplorerViewModel";
import Scaffold from "../../../CommonComponents/UI/Scaffold/Scaffold";
import NavigationButton from "../../../CommonComponents/UI/ButtonWidget/NavigationButton";
import LoginRequestPageWidget from "../../../CommonComponents/UI/LoginRequestPage/LoginRequestPageWidget";
import { ContentDisplayModel } from "../Models/ContentDisplayModels";
import ContentExplorerNoItemPage from "../Widgets/ContentExplorerNoItemPage";
import { Mousewheel } from "swiper";
import ViewController from "../../../CommonComponents/Base/ViewController";
import SizedDiv from "../../../CommonComponents/UI/SizedDiv";
import ContentExplorerFilterModalWidget from "./Widgets/ContentExplorerFilterModalWidget";
import ButtonWidget, {
  ButtonWidgetType,
} from "../../../CommonComponents/UI/ButtonWidget/ButtonWidget";

// CSS

import "./ContentExplorerScreen.css";
import "swiper/css";
import "swiper/css/pagination";

// Assets

import ic_cart from "../../../Assets/Images/ic_cart_white.svg";
import ic_chevron_down from "../../../Assets/Images/ic_chevron_down_white.svg";
import ic_no_feed from "../../../Assets/Images/ic_no_feed.svg";
import ic_filter_activated from "../../../Assets/Images/ic_filter_activated.svg";
import mixpanel from "mixpanel-browser";
import { isResellerDomain } from "../../../CommonComponents/Utils";
import { mixpanelManager } from "../../../Analytics/Mixpanel/Common";

interface ContentExplorerProps {
  explorerType: ExporeType;
}

export class ContentExplorerDiscoverScreen extends React.Component {
  render() {
    return <ContentExplorerScreen explorerType="discover" />;
  }
}

export class ContentExplorerFollowingScreen extends React.Component {
  render() {
    return <ContentExplorerScreen explorerType="following" />;
  }
}

export class ContentExplorerScreen extends ViewController<
  ContentExplorerProps,
  ContentExplorerViewModel
> {
  constructor(props: ContentExplorerProps) {
    super(props, new ContentExplorerViewModel(props.explorerType));
    if (
      !(this.viewModel.explorerType === "following" && !localStorage.fpAuth)
    ) {
      this.viewModel.loadPreloadingProps();
    }

    const rootElement = document.getElementById("root");

    if (rootElement) {
      rootElement.style.height = "100%";
    }

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name:
        this.viewModel.explorerType === "following"
          ? "ContentFollowing"
          : "ContentDiscover",
    });
  }

  render() {
    const loadedContentsInSwiper = this.viewModel.loadedContentInSwiper;
    const isDiscover = this.props.explorerType === "discover";
    const isNotLoginInFollowingPage =
      this.viewModel.explorerType === "following" && !localStorage.fpAuth;

    let key = "";

    for (
      let index = 0;
      index < this.viewModel.contents.length && index < 3;
      index++
    ) {
      key += this.viewModel.contents[index].contentId;
    }

    console.log(key);

    return (
      <Scaffold
        showLoadingAndErrorPosition={
          this.viewModel.isPreloadingLoaded ? "on-content-view" : "fullscreen"
        }
        selectedBottomBarItem={
          !this.viewModel.isFilterModalShown ? "home" : undefined
        }
        isShowLoading={this.viewModel.isLoading}
        showErrorModel={this.viewModel.errorMessage}
        showSnackbar={this.viewModel.snackbarMessage}
        showPopUpModel={this.viewModel.popUpModel}
        creatorId={this.viewModel.creatorId}
        isCreator={this.viewModel.isCreator}
        shareUrlModel={this.viewModel.shareUrlModel}
        showDownloadInvitationWidget={!this.viewModel.isFilterModalShown}
      >
        <div id="ContentExplorerContainer">
          <div id="ContentExplorerMainContent" key={key}>
            {isNotLoginInFollowingPage ? (
              <LoginRequestPageWidget theme="dark" />
            ) : loadedContentsInSwiper ? (
              <ContentExplorerPage
                key={
                  this.viewModel.contents.length > 0
                    ? this.viewModel.contents[0].contentAssets[0].assetUrl
                    : "0"
                }
                contents={loadedContentsInSwiper}
                onContentIndexChanged={(index) =>
                  this.viewModel.onContentIndexChanged(index)
                }
                loveContent={(id, isLiked) =>
                  this.viewModel.loveContent(id, isLiked)
                }
                followCreator={(id) => this.viewModel.followCreator(id)}
                shareLink={(url) => this.viewModel.onShareLink(url)}
                showingContentIndex={this.viewModel.showingContentIndex}
                totalOfFilterOptionsSelected={
                  this.viewModel.totalOfFilterOptionsSelected
                }
                onRetryLoadContentsWithResetFilter={() =>
                  this.viewModel.retryLoadContentWithResetFilter
                }
              />
            ) : (
              <ContentExplorerNoItemPage
                isFollowAnyCreator={this.viewModel.isFollowAnyCreator ?? true}
              />
            )}
            <div id="ContentExplorerTopGradation"></div>
            <div id="ContentExplorerTopTabRow">
              <button
                className={`ContentExplorerTopTabButton ${
                  isDiscover ? "TopTabButtonSelected" : "TopTabButtonUnselected"
                }`}
                onClick={() => {
                  if (!isDiscover) {
                    window.location.href = "/Discover";
                  }
                }}
              >
                Discover
              </button>
              <div id="ContentExplorerButtonSeparator"></div>
              <button
                className={`ContentExplorerTopTabButton ${
                  !isDiscover
                    ? "TopTabButtonSelected"
                    : "TopTabButtonUnselected"
                }`}
                onClick={() => {
                  if (isDiscover) {
                    window.location.href = "/Following";
                  }
                }}
              >
                Following
              </button>
              <div className="FlexFillSpacing"></div>
              <NavigationButton
                src={ic_cart}
                onClick={() => {
                  window.location.href = "/Cart";
                }}
                numberOnRightTop={this.viewModel.numberInCart}
              />
            </div>
            {this.viewModel.quickFilters && (
              <div id="ContentExplorerFilterContainer">
                <div>
                  {(this.viewModel.quickFilters ?? []).map((model) => (
                    <button
                      key={model.id}
                      id="Picker"
                      className={
                        model.id === this.viewModel.selectedQuickFilterId
                          ? "ActivedPicker"
                          : "UnactivedPicker"
                      }
                      onClick={() => {
                        this.viewModel.onSelectQuickFilter(model.id);
                      }}
                    >
                      {model.label}
                    </button>
                  ))}
                </div>
                <div>
                  <SizedDiv height={28} width={1} color="white" />
                  <button
                    id="FilterButton"
                    className={
                      this.viewModel.isFilterEnabledToBeShown
                        ? "ActivedFilter"
                        : "UnactivedFilter"
                    }
                    onClick={() => {
                      if (this.viewModel.isFilterEnabledToBeShown)
                        this.viewModel.changeShowFilterModalStatus(true);
                    }}
                  >
                    {this.viewModel.totalOfFilterOptionsSelected > 0 ? (
                      <>
                        <img src={ic_filter_activated} alt="" />
                        {this.viewModel.totalOfFilterOptionsSelected}
                      </>
                    ) : (
                      <>Filter</>
                    )}
                    <img src={ic_chevron_down} alt="" />
                  </button>
                </div>
              </div>
            )}
          </div>
          <ContentExplorerFilterModalWidget viewModel={this.viewModel} />
        </div>
      </Scaffold>
    );
  }
}

type ContentExplorerPageProps = {
  onContentIndexChanged: (index: number) => void;
  contents: ContentDisplayModel[];
  showingContentIndex: number;
  loveContent: (id: string, isLiked: boolean) => void;
  followCreator: (id: string) => void;
  shareLink: (url: string) => void;
  onRetryLoadContentsWithResetFilter: () => void;
  totalOfFilterOptionsSelected: number;
};

class ContentExplorerPage extends React.Component<
  ContentExplorerPageProps,
  {}
> {
  render() {
    return this.props.contents.length > 0 ? (
      <div id="ContentDisplaySwiperCotainer">
        <Swiper
          id="ContentDisplayContentSwiper"
          direction={"vertical"}
          mousewheel={true}
          modules={[Mousewheel]}
          slidesPerView={1}
          onSlideChangeTransitionEnd={(swiper) => {
            this.props.onContentIndexChanged(swiper.activeIndex);
          }}
        >
          {this.props.contents.map(
            (model: ContentDisplayModel, index: number) => {
              return index >= this.props.showingContentIndex - 1 &&
                index <= this.props.showingContentIndex + 1 ? (
                <SwiperSlide
                  key={`${model.contentAssets[0].assetUrl}_${index}`}
                >
                  <ContentDisplayCell
                    model={model}
                    key={index}
                    onLoveButtonTapped={(id, isLiked) => {
                      mixpanelManager.contentClick(
                        window.location.pathname.includes("Following")
                          ? "Following"
                          : "Discover",
                        "LOVE",
                        model.sellerId,
                        model.contentId
                      );
                      this.props.loveContent(id, isLiked);
                    }}
                    onFollowButtonTapped={(id) => {
                      mixpanelManager.contentClick(
                        window.location.pathname.includes("Following")
                          ? "Following"
                          : "Discover",
                        "FOLLOW",
                        model.sellerId,
                        model.contentId
                      );
                      this.props.followCreator(id);
                    }}
                    onShareButtonTapped={(url) => {
                      mixpanelManager.contentClick(
                        window.location.pathname.includes("Following")
                          ? "Following"
                          : "Discover",
                        "SHARE",
                        model.sellerId,
                        model.contentId
                      );
                      this.props.shareLink(url);
                    }}
                  />
                </SwiperSlide>
              ) : (
                <SwiperSlide key={`empty_${index}`}>
                  <div style={{ height: "100%", width: "100%" }} />
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      </div>
    ) : (
      <div id="ContentExplorerNoFeedContainer">
        <img src={ic_no_feed} alt="" />
        <p>No Feed</p>
        {this.props.totalOfFilterOptionsSelected > 0 && (
          <>
            <p>
              No feed for the selected category. Reset filter to reset category.
            </p>
            <ButtonWidget
              buttonType={ButtonWidgetType.purple}
              action={() => this.props.onRetryLoadContentsWithResetFilter()}
            >
              Reset Filter
            </ButtonWidget>
          </>
        )}
      </div>
    );
  }
}
