import React from "react";
import { Optional } from "../../../../CommonTypes";
import "./CheckoutPointWidget.css";
import icChevronRight from "../../../../Assets/Images/ic_right_chevron_black.svg";

type CheckoutPointWidgetProps = {
  point: Optional<number>;
  onClick?: () => void;
  availablePoints?: number;
};

export default class CheckoutPointWidget extends React.Component<CheckoutPointWidgetProps> {
  render() {
    const { point = 0, onClick = () => {}, availablePoints } = this.props;

    return (
      <div id="CheckoutPointWidget" className="container" role="button" onClick={onClick}>
        <div className="title" style={{ flex: 1 }}>
          Point
        </div>
        <p className="point" style={{
          color: (point || 0) > 0 ? 'var(--red)' : 'inherit'
        }}>{point || availablePoints} <span style={{color: 'black', marginLeft: 4}}>P</span></p>
        <img alt="" src={icChevronRight} className="rightIcon" />
      </div>
    );
  }
}
