import { CacheManager } from "../CacheManager";

const cm = new CacheManager();

export default abstract class BaseViewModel {
    states: this[] = [];
    key?: string;
    fresh?: Boolean = true;

    isResellerDomain = false;
    constructor(args?: {
      key?: string;
    }) {
      if (args?.key) {
        this.key = args?.key
      } 
      this.isResellerDomain = !Boolean(window.location.hostname.includes("thefepi.com"));
     
    }

    updateState: (args: any) => void = (_) => {
        this.states.push({...this});
        this.stabPostUpdateState();
    };

    abstract copy<T extends {}>(args: T): this;

    setupUpdateState(action: (args: any) => void) {
        this.updateState = action;
    }

    refresh() {      
      if (this.key) {
        
        const persisted = cm.get(this.key)
        if (persisted) {
          Object.assign(this, persisted)
          Object.assign(this, {fresh: false, isLoading: false, loadingProducts: false})
        }
      }
    }

    clone() {      
      return Object.assign(Object.create(Object.getPrototypeOf(this)), this)
    }

    persist() {
      cm.set(this.key, this.clone());
    }

    stabPostUpdateState() {} // For Testing Only
}