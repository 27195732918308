import React from "react";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";

import ic_edit from "../../../Assets/Images/ic_pencil_edit.svg";
import ic_photo_profile_placeholder from "../../../Assets/Images/ic_photo_profile_placeholder.svg";
import ic_point from "../../../Assets/Images/ic_profile_points.svg";
import ic_coupon from "../../../Assets/Images/ic_profile_coupon.svg";
import ic_level from "../../../Assets/Images/ic_profile_level.svg";

import "./MyPageWidgets.css";

type MyPageProfileSectionWidgetProps = {
    imageUrl: string,
    name: string,
    followerDetail?: string,
    points: {
        imageUrl: string,
        label: string,
        value: string
    },
    coupons: {
        imageUrl: string,
        label: string,
        value: string
    },
    level: {
        imageUrl: string,
        label: string,
        value: string
    }
}

export default class MyPageProfileSectionWidget extends React.Component<MyPageProfileSectionWidgetProps, {}> {
    render() {
        return (
            <div id="MyPageCreatorProfile">
                <ImageWidget imageUrl={this.props.imageUrl} placeholder={ic_photo_profile_placeholder} />
                <p>{this.props.name}</p>
                <button 
                    id="MyPageEditProfileButton"
                    onClick={() => {
                        window.location.href = "/EditProfile";
                    }}
                >
                    <img src={ic_edit} alt="" />
                    <p>Edit Profile</p>
                </button>
                {
                    this.props.followerDetail &&
                    <p>{this.props.followerDetail}</p>
                }
                <div id="MyPageProfileStatusDetails">
                    <div>
                        <img src={ic_point} alt=""></img>
                        <p>{this.props.points.label}</p>
                        <p>{this.props.points.value}</p>
                    </div>
                    <div />
                    <div>
                        <img src={ic_coupon} alt=""></img>
                        <p>{this.props.coupons.label}</p>
                        <p>{this.props.coupons.value}</p>
                    </div>
                    <div />
                    <div>
                        <img src={ic_level} alt=""></img>
                        <p>{this.props.level.label}</p>
                        <p>{this.props.level.value}</p>
                    </div>
                </div>
            </div>
        );
    }
}