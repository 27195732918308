import { animated, useTransition, easings } from "@react-spring/web";
import React from "react";

export type BottomSheetWidgetProps = {
  onClose?: () => void;
  isOpen?: boolean;
  children?: React.ReactNode;
  renderHeader?: (options?: {
    title?: string;
    onBackClick?: () => void;
  }) => React.ReactNode;
  title?: string;
  isLoading?: boolean;
};

function BottomSheetWidget(props: BottomSheetWidgetProps) {
  const {
    isOpen,
    onClose,
    children,
    renderHeader = defaultRenderHeader,
    title,
  } = props;

  const transitions = useTransition(isOpen, {
    from: { backdropOpacity: 0, ty: "translateY(100%)" },
    enter: { backdropOpacity: 0.8, ty: "translateY(0%)" },
    leave: { backdropOpacity: 0, ty: "translateY(100%)" },
    config: { easing: easings.easeInExpo },
  });

  function defaultRenderHeader(options?: {
    title?: string;
    onBackClick?: () => void;
  }) {
    return (
      <div style={{ padding: "24px 28px 12px 28px", display: "flex" }}>
        <button
          onClick={() => {
            options?.onBackClick?.();
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 4L2.99999 11.5L10.5 19"
              stroke="black"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <line
              x1="3.5"
              y1="11.5"
              x2="20.5"
              y2="11.5"
              stroke="black"
              stroke-linecap="round"
            />
          </svg>
        </button>
        <p
          style={{
            fontWeight: "700",
            fontSize: "18px",
            lineHeight: "25px",
            color: "#000000",
            marginLeft: 19,
          }}
        >
          {options?.title}
        </p>
      </div>
    );
  }

  return transitions(
    (styles, item) =>
      item && (
        <div>
          <animated.div
            onClick={() => {
              onClose?.();
            }}
            style={{
              backgroundColor: `black`,
              zIndex: 9999,
              opacity: styles.backdropOpacity,
              position: "absolute",
              top: 0,
              maxWidth: 600,
              bottom: 0,
              width: 600,
            }}
          ></animated.div>
          <animated.div
            style={{
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              overflow: "hidden",
              // top: 48,
              position: "fixed",
              // bottom: 0,
              width: "100%",
              maxWidth: 600,
              bottom: 0,
              zIndex: 9999,
              transform: styles.ty,
              backgroundColor: "white",
              top: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ flexShrink: 0 }}>
              {renderHeader({
                title,
                onBackClick: () => {
                  onClose?.();
                },
              })}
            </div>
            <div style={{ flexGrow: 1, overflow: "auto" }}>{children}</div>
          </animated.div>
        </div>
      )
  );
}

export default BottomSheetWidget;
