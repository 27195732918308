import React from "react";
import SizedDiv from "../../../../CommonComponents/UI/SizedDiv";
import "./OrderShippingAddressWidget.css";

export type OrderShippingAddressWidgetProps = {
  title?: string;
  name?: string;
  address?: string;
  phoneNumber?: string;
  email?: string;
};

export default class OrderShippingAddressWidget extends React.Component<OrderShippingAddressWidgetProps> {
  render(): React.ReactNode {
    const { title, name, address, phoneNumber, email } = this.props;

    return (
      <div className="OrderShippingAddressWidgetContainer">
        <div className="OrderShippingAddressWidgetHeader">
          {this.props.title}
        </div>
        <div className="OrderShippingAddressWidgetDivider" />

        <div className="OrderShippingAddressWidgetShippingDetailsContainer">
          <div className="OrderShippingAddressWidgetName">{name}</div>
          <SizedDiv height={4} />
          <div>{address}</div>
          <div>{phoneNumber}</div>
          <SizedDiv height={4} />
          <div>{email}</div>
        </div>
      </div>
    );
  }
}
