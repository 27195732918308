import SizeChartResponse from "./Models/SizeChartResponse";
import { getPreloadProps } from "../../CommonComponents/Utils";
import axios from "axios";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import ViewModel from "../../CommonComponents/Base/ViewModel";

export default class SizeChartViewModel extends ViewModel {
    brandId: string;
    sizeData : SizeChartResponse | null = null;
    isLoading: boolean = true;
    isLoadingButtonSubmit: boolean = false;
    errorModel: ShowErrorModel | null = null;
    errorSnackBarModel: ShowSnackbarModel | null = null;
    errorMessage: string | null = null;
    navBarTransparency: number = 1;
    numberInCart: number = 0;
    snackbarMessage: ShowSnackbarModel | null = null;

    constructor(brandId: string) {
        super();
        this.brandId = brandId;
    }

    loadPreloadingProps() {
        this.emit(() => this.isLoading = true);

        getPreloadProps({
            onSuccess: (_, __, numberInCart) => {
                this.emit(() => this.numberInCart = numberInCart);

                this.loadSizeChart();
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorModel = {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps()
                    };
                });
            }
        })
    }

    loadSizeChart() {
        this.emit(() => {
            this.isLoading = true;
        });

        axios.post('https://api.thefepi.com/shopable/getSizeChart/'+this.brandId)
        .then((response) => {
            this.emit(() => {
                this.isLoading = false;
                this.sizeData = response.data;
            });
        })
    }

    onSnackbarIsFinished() {
        this.emit(() => {
            this.errorMessage = null;
        });
    }
}