import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_back from "../../Assets/Images/ic_chevron_left.svg";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";

import "./OrderDetail.css";
import ViewController from "../../CommonComponents/Base/ViewController";
import Scaffold, {
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";
import OrderDetailViewModel from "./OrderDetailViewModel";
import { useParams } from "react-router-dom";

import OrderDetailBillingWidget from "./Widgets/OrderDetailBillingWidget";
import OrderDetailAddressWidget from "./Widgets/OrderDetailAddressWidget";
import OrderDetailPaymentInfoWidget from "./Widgets/OrderDetailPaymentInfoWidget";
import OrderIdWidget from "./Widgets/OrderIdWidget/OrderIdWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import OrderChunkWidget from "./Widgets/OrderChunk/OrderChunkWidget";
import OrderType from "../../Store/OrderReceipt/Models/OrderType";
// import PopupTrackingWidget from "../";
import OrderTotalPriceWidget from "./Widgets/OrderTotalPrice/OrderTotalPriceWidget";
import OrderPaymentInfo from "./Widgets/OrderPaymentInfo/OrderPaymentInfo";
import OrderShippingAddressWidget from "./Widgets/OrderShippingAddress/OrderShippingAddressWidget";
import NeedHelpWidget from "./Widgets/NeedHelpWidget/NeedHelpWidget";
import PopupTrackingWidget from "../OrderAndDelivery/Widget/PopupTrackingWidget";
import UnfinishedPaymentWidget from "./Widgets/UnfinishedPayment/UnfinishedPaymentWidget";
import OrderPaymentStepsWidget from "./Widgets/OrderPaymentSteps/OrderPaymentStepsWidget";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import { isResellerDomain } from "../../CommonComponents/Utils";
import mixpanel from "mixpanel-browser";

export default function OrderDetailViewController() {
  let { orderId } = useParams();
  return <OrderDetailPage orderId={orderId ?? ""} />;
}

class OrderDetailPage extends ViewController<
  { orderId: string },
  OrderDetailViewModel
> {
  constructor(props: { orderId: string }) {
    super(props, new OrderDetailViewModel(props.orderId));
    this.viewModel.loadPreloadingProps();

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "OrderDetail",
    });
  }

  render() {
    const model = this.viewModel.model;

    return (
      <Scaffold
        backgroundColor="#f0f0f0"
        topBar={
          <>
            {isResellerDomain() ? (
              <NavigationButton
                src={ic_back}
                onClick={() => (window.location.href = "/")}
              />
            ) : (
              <NavigationButton
                src={ic_thefepi_f}
                onClick={() => (window.location.href = "/")}
              />
            )}
            <p
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginLeft: "8px",
              }}
            >
              Order {"&"} Delivery
            </p>
            <div style={{ flexGrow: 1 }} />
            <NavigationButton
              src={ic_cart}
              numberOnRightTop={this.viewModel.numberInCart}
              onClick={() => {
                window.location.href = "/Cart";
              }}
            />
          </>
        }
        showSnackbar={this.viewModel.snackbarModel}
        selectedBottomBarItem="my-page"
        isShowLoading={this.viewModel.isLoading}
        creatorId={this.viewModel.creatorId}
        isCreator={this.viewModel.isCreator}
        showErrorModel={this.viewModel.errorMessage}
      >
        <PopupTrackingWidget
          // @ts-ignore
          dataTracking={this.viewModel.trackingData}
          isShowLoadingTracking={this.viewModel.isLoadingTracking}
          showModal={this.viewModel.shouldShowTrackingModal}
          closeModal={() => void this.viewModel.closeTrackingModal()}
        />
        <SizedDiv height={8} />
        <OrderIdWidget
          id={this.viewModel.orderId}
          reminder={this.viewModel.model?.unfinishedPaymentInfo?.paymentMessage}
          message={
            this.viewModel.model?.unfinishedPaymentInfo
              ? {
                  headline:
                    this.viewModel.model?.paymentInfo.importantMessages.title,
                  contents:
                    this.viewModel.model?.paymentInfo.importantMessages
                      .messages,
                }
              : undefined
          }
          remainingTime={
            this.viewModel.model?.unfinishedPaymentInfo?.remainingTimeInSeconds
          }
        />

        {this.viewModel.model?.unfinishedPaymentInfo && (
          <>
            <SizedDiv height={8} />
            <UnfinishedPaymentWidget
              onCopyText={(type, text) => {
                navigator.clipboard.writeText(text || "");
                if (type === "payment-amount") {
                  this.viewModel.createSnackbar(
                    new ShowSnackbarModel({
                      message: "Payment amount copied",
                      type: "info",
                    })
                  );
                }
                if (type === "va") {
                  this.viewModel.createSnackbar(
                    new ShowSnackbarModel({
                      message: "Payment code copied",
                      type: "info",
                    })
                  );
                }
              }}
              paymentAccountHolderName={
                this.viewModel.model.unfinishedPaymentInfo.detail.description
              }
              paymentCode={
                this.viewModel.model.unfinishedPaymentInfo.detail.paymentCode
              }
              paymentVendorName={
                this.viewModel.model.paymentInfo.paymentMethodName
              }
              paymentMethodName={
                this.viewModel.model.unfinishedPaymentInfo.detail.title
              }
              paymentMethodLogoUrl={
                this.viewModel.model.unfinishedPaymentInfo.imageUrl
              }
              paymentCodeImageUrl={
                this.viewModel.model.unfinishedPaymentInfo.detail
                  .barcodeImageUrl
              }
              paymentAmount={
                this.viewModel.model.unfinishedPaymentInfo.paymentAmount
              }
            />
          </>
        )}

        {this.viewModel.model?.unfinishedPaymentInfo && (
          <>
            <SizedDiv height={8} />
            <OrderPaymentStepsWidget
              steps={this.viewModel.model?.unfinishedPaymentInfo?.paymentSteps.map(
                (step) => ({
                  title: step.title,
                  details: step.descriptions,
                })
              )}
            />
          </>
        )}

        <SizedDiv height={8} />
        {this.viewModel.model?.orders.map((o) => {
          return (
            <>
              <OrderChunkWidget
                info={o.info}
                title={o.title}
                sections={o.items.map((i) => ({
                  type: i.type,
                  products: i.products.map((p) => ({
                    ...p,
                    isReordering:
                      this.viewModel.isLoadingReorderForBagItemId ===
                      p.bagItemId,
                  })),
                  status: i.status,
                  details: i.statusDetail,
                  onReorderClick: (bagItemId: string) => {},
                  onMoreDetailsClick: () => {
                    this.viewModel.loadTracking(
                      this.viewModel.orderId,
                      i.products.map((p) => p.bagItemId)
                    );
                    this.viewModel.openTrackingModal();
                  },
                }))}
              />
              <SizedDiv height={12} />
            </>
          );
        })}

        <OrderTotalPriceWidget
          billingItems={this.viewModel.model?.billing.billingItems}
          title="Total Price"
          total={this.viewModel.model?.billing.total}
        />
        <SizedDiv height={12} />
        <OrderShippingAddressWidget
          title="Shipping Address"
          name={this.viewModel.model?.shippingAddress.name}
          address={this.viewModel.model?.shippingAddress.address}
          email={this.viewModel.model?.shippingAddress.email}
          phoneNumber={this.viewModel.model?.shippingAddress.phoneNumber}
        />

        {this.viewModel.model?.unfinishedPaymentInfo && (
          <>
            <SizedDiv height={12} />
            <div
              role="button"
              style={{
                padding: 24,
                width: "100%",
                fontSize: 12,
                background: "white",
              }}
              onClick={() => this.viewModel.cancelThisOrder()}
            >
              {this.viewModel.isCancelingOrder ? (
                <LoadingWidget />
              ) : (
                "Cancel order"
              )}
            </div>
          </>
        )}
        <SizedDiv height={12} />
        <OrderPaymentInfo
          cardNumberDetail={this.viewModel.model?.paymentInfo.cardNumberDetail}
          title="Payment Method"
          paymentMethodLogo={this.viewModel.model?.paymentInfo.imageUrl}
          paymentMethodName={
            this.viewModel.model?.paymentInfo.paymentMethodName
          }
          headline={this.viewModel.model?.paymentInfo.importantMessages.title}
          messages={
            this.viewModel.model?.paymentInfo.importantMessages.messages
          }
        />
        <SizedDiv height={12} />
        <NeedHelpWidget />
      </Scaffold>
    );
  }
}

// import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
// import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
// import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
// import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";

// import "./OrderDetail.css";
// import ViewController from "../../CommonComponents/Base/ViewController";
// import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
// import OrderDetailViewModel from "./OrderDetailViewModel";
// import { useParams } from "react-router-dom";

// import OrderDetailItemsWidget from "./Widgets/OrderDetailItemsWidget";
// import OrderDetailBillingWidget from "./Widgets/OrderDetailBillingWidget";
// import OrderDetailAddressWidget from "./Widgets/OrderDetailAddressWidget";
// import OrderDetailPaymentInfoWidget from "./Widgets/OrderDetailPaymentInfoWidget";

// export default function OrderDetailViewController() {
//     let { orderId } = useParams();
//     return (<OrderDetailPage orderId={orderId ?? ""}/>)
// }

// class OrderDetailPage extends ViewController<{orderId: string}, OrderDetailViewModel> {
//     constructor(props: {orderId: string}) {
//         super(props, new OrderDetailViewModel(props.orderId));
//         this.viewModel.loadPreloadingProps();
//     }

//     render() {
//         const model = this.viewModel.model;

//         return <Scaffold
//             topBar={
//                 <>
//                     <NavigationButton
//                         src={ic_thefepi_f}
//                         onClick={() => window.location.href = "/"}
//                     />
//                     <p style={{fontSize:"16px", fontWeight:"bold", marginLeft:"8px"}}>Order {"&"} Delivery</p>
//                     <div style={{flexGrow:1}}/>
//                     <NavigationButton
//                         src={ic_cart}
//                         numberOnRightTop={this.viewModel.numberInCart}
//                         onClick={() => {
//                             window.location.href = "/Cart";
//                         }}
//                     />
//                 </>
//             }
//             selectedBottomBarItem="my-page"
//             isShowLoading={this.viewModel.isLoading}
//             creatorId={this.viewModel.creatorId}
//             isCreator={this.viewModel.isCreator}
//             showErrorModel={this.viewModel.errorMessage}
//         >
//             <div id="OrderDetail" className="container">
//                 <div className="row">
//                     <div className='box_white_cart'>
//                         <div className="row">
//                             <div className="col-6">
//                                 <span className="font14 color585b63"><b>Order Number</b></span>
//                             </div>
//                             <div className="col-6 text-end">
//                                 <span className="font14 color585b63"><b>#{this.viewModel.orderId}</b></span>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <OrderDetailItemsWidget
//                     model={model}
//                 />

//                 <OrderDetailAddressWidget
//                     model={model}
//                 />

//                 <OrderDetailBillingWidget
//                     model={model}
//                 />

//                 <OrderDetailPaymentInfoWidget />

//                 <div className="row">
//                     <div className="col no-padding">
//                         <div className='box_white_cart'>
//                             <p className="font14"><b>Need Help?</b></p>
//                             <div className="div_ask font12" data-bs-toggle="modal" data-bs-target="#ChatModal">
//                                 <img src={global.ImgUrl+'shopable/ic-detail-chat.png'} alt="img_chat"></img>&nbsp;&nbsp;Ask Anytime
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <ChannelChatPopup />

//             </div>

//         </Scaffold>
//     }
// }
