import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { standarizeThefepiLink } from "../../../../CommonComponents/Utils";
import ProgressBar from "../StoreBannersProductList/ProgressBar";
import "./StoreBannerDetailsWidget.css";
import { mixpanelManager } from "../../../../Analytics/Mixpanel/Common";

export type StoreBannerDetailsWidgetProps = {
  title?: string;
  bannerWidth?: number;
  bannerHeight?: number;
  screenWidth?: number;
  banners?: {
    deeplink?: string;
    description?: string;
    imageUrl?: string;
    title?: string;
  }[];
};

export default class StoreBannerDetailsWidget extends React.Component<
  StoreBannerDetailsWidgetProps,
  { activeIndex: number }
> {
  constructor(props: StoreBannerDetailsWidgetProps) {
    super(props);
    this.state = {
      activeIndex: 1,
    };
  }

  render(): React.ReactNode {
    const { title, screenWidth = 0 } = this.props;

    const aspectRatio = 300 / 240;

    return (
      <div className="StoreBannerDetailsWidget">
        <div className="StoreBannerDetailsWidgetHeader">
          <p className="StoreBannerDetailsWidgetTitle">{title || "no title"}</p>
        </div>
        <div>
          <Swiper
            style={{
              padding: "0px 16px",
            }}
            onSlideChange={(x) => {
              this.setState({
                activeIndex: x.activeIndex + 1,
              });
            }}
            spaceBetween={16}
            slidesPerView={1.044}
            centeredSlides={true}
            centeredSlidesBounds={true}
          >
            {this.props.banners?.map((b) => (
              <SwiperSlide>
                <div
                  onClick={() => {
                    mixpanelManager.storeFeedClick({
                      type: "banner",
                      title: b.title || "",
                      group_name:
                        window.location.href.match(/Store\/([a-zA-Z]+)/)?.[1] ||
                        "HOME",
                      identifier: "STORE_BANNER_DETAILS",
                      screen_name: "Store",
                    });
                    window.location.href = standarizeThefepiLink(
                      b.deeplink || ""
                    );
                  }}
                  style={{ height: (screenWidth - 60) * aspectRatio }}
                >
                  <img
                    src={b.imageUrl}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="StoreBannerDetailsBannerTitle">{b.title}</div>
                <div
                  className="StoreBannerDetailsBannerDescription"
                  style={{ height: 52 }}
                >
                  {b.description}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    onClick={() => {
                      mixpanelManager.storeFeedClick({
                        type: "banner",
                        title: "See more",
                        group_name:
                          window.location.href.match(
                            /Store\/([a-zA-Z]+)/
                          )?.[1] || "HOME",
                        identifier: "STORE_BANNER_DETAILS",
                        screen_name: "Store",
                      });
                      window.location.href = standarizeThefepiLink(
                        b.deeplink || ""
                      );
                    }}
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "20px",
                      textAlign: "right",
                      textDecorationLine: "underline",

                      color: "#999999",
                    }}
                  >
                    See more
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
          <ProgressBar
            progress={
              this.state.activeIndex / (this.props.banners?.length || 1)
            }
          />
        </div>
        <div className="StoreBannersProductListWidgetPageIndicator">
          {`${this.state.activeIndex}/${this.props.banners?.length || 1}`}
        </div>
      </div>
    );
  }
}
