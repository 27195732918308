import { eventManager } from "../../Analytics/Events/Common";
import { AddShippingInfoEvent } from "../../Analytics/Events/GA4/AddShippingInfoEvent";
import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getRawLocationQueriesString, isResellerDomain } from "../../CommonComponents/Utils";
import CourierSelectorListResponse from "./Models/CourierSelectorListResponse";

export default class CourierSelectorViewModel extends ViewModel {
    orderId: string;
    shippingAddressId: string;
    bagItemIds: string[];

    isLoading: boolean = true;
    isCtaLoading: boolean = false;
    errorMessage?: string;
    errorSnackbarMessage?: ShowSnackbarModel;
    response?: CourierSelectorListResponse;
    selectedListIndex: number = 0;
    selectedItemIndex: number = 0;
    creatorLink?: string;

    constructor(args: { orderId: string, shippingAddressId: string, bagItemIds: string[], creatorLink?: string }) {
        super();
        
        this.creatorLink = args.creatorLink;
        this.orderId = args.orderId;
        this.shippingAddressId = args.shippingAddressId;
        this.bagItemIds = args.bagItemIds;
    }

    getListOfCourier() {
        this.emit(() => {
            this.isLoading = true;
            this.errorMessage = undefined;
        });

        requestAPI(localStorage.fpAuth ? Endpoints.courierList : Endpoints.courierListNonLogin, {
            parameters: {
                orderId: this.orderId,
                shippingAddressId: this.shippingAddressId,
                bagItemIdList: this.bagItemIds,
                deviceId: localStorage.uuid
            },
            onSuccess: (response: BaseResponse<CourierSelectorListResponse>) => {
                if (response.result) {
                    this.emit(() => {
                        this.isLoading = false;
                        this.response = response.data;
                    });
                } else {
                    this.emit(() => {
                        this.isLoading = false;
                        this.errorMessage = response.message;
                    });
                }
            },
            onFailure: (errorMessage) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = errorMessage;
                });
            }
        });
    }

    selectCourier() {
        this.emit(() => {
            this.isCtaLoading = true;
            this.errorSnackbarMessage = undefined;
        });

        if (this.response) {
            const selectedCourierModel = this.response.courierList[this.selectedListIndex].items[this.selectedItemIndex];

            requestAPI(localStorage.fpAuth ? Endpoints.selectCourier : Endpoints.selectCourierNonLogin, {
                parameters: {
                    orderId: this.orderId,
                    bagItemIdList: this.bagItemIds,
                    netPrice: selectedCourierModel.price.netPrice,
                    realPrice: selectedCourierModel.price.priceBeforeDiscount,
                    courierCompanyId: selectedCourierModel.courierCompanyId,
                    courierTypeId: selectedCourierModel.courierTypeId,
                    deviceId: localStorage.uuid
                },
                onSuccess: (response: BaseResponse<CourierSelectorListResponse>) => {
                    if (response.result) {
                        this.emit(() => {
                            this.isLoading = false;
                        });
                        

                        eventManager.add({
                            currency: 'IDR',
                            shipping: {
                                tier: selectedCourierModel.courierCompanyId,
                                value: selectedCourierModel.price.netPrice,
                            }
                        })

                        eventManager.fire(AddShippingInfoEvent);

                        window.location.href = `${isResellerDomain() ?  `/shop/${this.creatorLink}` : ''}/Checkout?courierCompanyId=${selectedCourierModel.courierCompanyId}&courierTypeId=${selectedCourierModel.courierTypeId}&${getRawLocationQueriesString()}`;
                    } else {
                        this.emit(() => {
                            this.isLoading = false;
                            this.errorSnackbarMessage = new ShowSnackbarModel({
                                message: response.message
                            });
                        });
                    }
                },
                onFailure: (errorMessage) => {
                    this.emit(() => {
                        this.isLoading = false;
                        this.errorSnackbarMessage = new ShowSnackbarModel({
                            message: errorMessage
                        });
                    });
                }
            });
        }


    }
}