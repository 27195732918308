import { title } from "process";
import React from "react";

type TextAreaWidgetProps = {
  title?: string;
  lines?: number;
  variant?: "underline" | "outlined";
  isRequired?: boolean;
  errorMessage?: string;
  action?: (event: any) => void;
  autoComplete?: "on" | "off";
  placeholder?: string;
  name?: string;
  value?: string;
  id?: string;
};

export default class TextAreaWidget extends React.Component<TextAreaWidgetProps, {}> {
  render() {
    const lines = this.props.lines || 1;
    return (
      <>
        {this.props.title && (
          <label
            className="form-label"
            style={this.props.variant === "outlined" ? { color: "black", fontWeight: "500" } : {}}
          >
            {this.props.title}
            <span style={{ color: "red" }}>{this.props.isRequired && "*"}</span>
          </label>
        )}
        <div
          className={this.props.variant === "underline" ? "InputWidgetNoBorder" : "InputWidget"}
          style={{ height: 29 + lines * 24 + "px" }}
        >
          <textarea
            onChange={this.props.action}
            onKeyUp={this.props.action}
            placeholder={this.props.placeholder}
            style={{
              border: "none",
              outline: "none",
              width: "100%",
              height: "100%",
              resize: "none",
              overflow: "auto",
              // marginTop: "-7px"
            }}
            rows={2}
            id={this.props.id}
          >
          </textarea>
        </div>
        <div className="divError">
          {this.props.errorMessage !== "" && this.props.errorMessage !== null && (
            <span className="errormsg">
              <img alt="ic-wrong" width="10" src={global.ImgUrl + "shopable/ic-wrong@3x.png"}></img> &nbsp;&nbsp;{" "}
              {this.props.errorMessage}
            </span>
          )}
        </div>
      </>
    );
  }
}
