import React from "react";

import "./ErrorStateView.css";

import ic_error_light from "../../../Assets/Images/ic_error_pink.svg";
import ic_error_dark from "../../../Assets/Images/ic_error_dark_pink.svg";

type ErrorStateViewProps = {
    theme: "dark" | "light",
    message: string,
    retryAction: () => void
};

export default class ErrorStateView extends React.Component<ErrorStateViewProps, {}> {
    render() {
        const isDark = this.props.theme === "dark";

        return(
            <div className="ErrorStateViewBackground" id={isDark ? "ErrorStateDark" : "ErrorStateLight"}>
                <img src={isDark ? ic_error_dark : ic_error_light} alt="" />
                <p>Oops!</p>
                <p>{this.props.message}</p>
                <button onClick={() => {
                    this.props.message === 'Please Sign in to Continue' ? 
                    window.location.href = "/Login" :
                    this.props.retryAction();
                }}>
                    Try Again
                </button>
            </div>
        );
    }
}