export type CacheManagerArgs = {};

// cache all network request
export class CacheManager {
  map: Map<string, string>;

  constructor(args?: CacheManagerArgs) {
    this.map = new Map();
  }

  get<T = any>(key?: string) {
    if (!key) return;
    console.log({ vm__GET: this.map.get(key) });
    //@ts-ignore
    return this.map.get(key) as T;
  }

  set(key?: string, value?: any) {
    if (!key) return;
    console.log({ vm__SET: value });
    return this.map.set(key, value);
  }
}

const cm = new CacheManager();

export type CacheManagerComponentProps = {
  cacheManager: CacheManager;
};

export function withCacheManager(WrappedComponent: React.ComponentType) {
  const FinalComponent = (props: any) => {
    return <WrappedComponent cacheManager={cm} {...props} />;
  };

  return FinalComponent;
}
