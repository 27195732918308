import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import MyPageResponse from "../MyPage/Models/MyPageResponse";

export default class EditProfileViewModel extends ViewModel {
    isLoading: boolean = true;
    model: MyPageResponse | null = null;
    username: string = "";
    phoneNumber: string = "";
    emailAccount: string = "";
    dob: string = "";
    errorMessage: ShowErrorModel | null = null;
    snackbarMessage: ShowSnackbarModel | null = null;
    isUploadingProfile: boolean = false;

    get isSaveButtonEnable(): boolean {
        const customer = this.model?.customer;

        return this.username.length > 0 
            && this.phoneNumber.length > 0 
            && this.emailAccount.length > 0 
            && this.dob.length > 0 
            && this.isEmailValid
            && (
                this.username !== (customer?.username ?? "")
                    || this.phoneNumber !== (customer?.phoneNumber ?? "")
                    || this.emailAccount !== (customer?.email ?? "")
                    || this.dob !== (customer?.dob ?? "").split(" ")[0]
            );
        
    }

    get isEmailValid(): boolean {
        const matching = this.emailAccount
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        return matching != null;
    }

    loadDetail() {
        this.emit(() => {
            this.isLoading = true;
            this.errorMessage = null;
        });

        requestAPI(Endpoints.myPage, {
            onSuccess: (response: BaseResponse<MyPageResponse>) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.model = response.data;
                    this.username = response.data.customer.username;
                    this.phoneNumber = response.data.customer.phoneNumber ?? "";
                    this.emailAccount = response.data.customer.email ?? "";
                    this.dob = (response.data.customer.dob ?? "").split(" ")[0];
                    console.log(response.data.customer.dob)
                });
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadDetail()
                    }
                });
            }
        })
    }

    changeUsername(value: string) {
        this.emit(() => this.username = value);
    }

    changePhoneNumber(value: string) {
        this.emit(() => this.phoneNumber = value);
    }

    changeEmail(value: string) {
        this.emit(() => this.emailAccount = value);
    }

    changeDob(value: string) {
        this.emit(() => this.dob = value);
    }

    uploadEditProfile() {
        this.emit(() => this.isUploadingProfile = true);

        requestAPI(Endpoints.editProfile, {
            parameters: {
                username: this.username,
                phoneNumber: this.phoneNumber,
                email: this.emailAccount,
                dob: this.dob + " 00:00:00"
            },
            onSuccess: (response: BaseResponse<{}>) => {
                if (response.result) {
                    window.location.href = "/MyPage";
                } else {
                    this.emit(() => {
                        this.isUploadingProfile = false;
                        this.snackbarMessage = new ShowSnackbarModel({
                            message: response.message,
                            type: "error"
                        });
                    })
                }
            },
            onFailure: (_) => {
                this.emit(() => {
                    this.isUploadingProfile = false;
                    this.snackbarMessage = new ShowSnackbarModel({
                        message: "Server error, please try again.",
                        type: "error"
                    });
                })
            }
        })
    }
}