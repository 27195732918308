import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ChannelChatPopup from "../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";
import CartViewModel from "./CartViewModel";
import CartItemsWidget from "./Widgets/CartItemsWidget";
import CartPriceWidget from "./Widgets/CartPriceWidget";
import CartRemovePopupWidget from "./Widgets/CartRemovePopupWidget";
import ButtonWidget, {
  ButtonWidgetType,
} from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

import "./Cart.css";
import ic_thefepi_f from "../../Assets/Images/Logo/ic_fepi_f.svg";
import ic_x from "../../Assets/Images/ic_x.svg";
import ic_back from "../../Assets/Images/ic_chevron_left.svg";
import ViewController from "../../CommonComponents/Base/ViewController";
import {
  getAppLink,
  WithCreatorLinkProps,
  isResellerDomain,
  withSellerId,
  standarizeThefepiLink,
  getResellerPhoneNumber,
} from "../../CommonComponents/Utils";
import { ImageWidget } from "../../CommonComponents/UI/ImageWidget/ImageWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import BackButton from "../../CommonComponents/UI/BackButton/BackButton";

class CartScreen extends ViewController<WithCreatorLinkProps, CartViewModel> {
  constructor(props: WithCreatorLinkProps) {
    super(
      props,
      new CartViewModel({
        creatorLink: props.creatorLink,
      })
    );
    this.viewModel.loadPreloadingProps();
    if (!isResellerDomain()) this.viewModel.loadPromoBanner();
  }

  renderPromotionalBanner(props?: {
    imageUrl?: string;
    deeplink?: string;
    isLoading?: boolean;
  }) {
    const aspectRatio = 0.125;
    if (!props?.deeplink) return null;

    return (
      <>
        <SizedDiv height={72} />
        <div style={{ padding: "8px 16px 8px 16px" }}>
          <a
            href={standarizeThefepiLink(props?.deeplink || "")}
            style={{
              height: this.state.width * aspectRatio,
              borderRadius: 8,
              overflow: "hidden",
              display: "block",
            }}>
            {props?.isLoading ? (
              <div
                className="ShimmerAnimation"
                style={{ height: "100%", width: "100%" }}
              />
            ) : (
              <ImageWidget imageUrl={props?.imageUrl} />
            )}
          </a>
        </div>
      </>
    );
  }

  removeItem = (bagItemId: string) => {
    this.viewModel.onRemoveItem(bagItemId);
  };

  closeModal = () => {
    document.body.classList.remove("modal-open");
    this.viewModel.onCloseModal();
  };

  closeModalBenefit = () => {
    window.location.href = `${
      isResellerDomain() ? `/shop/${this.props.creatorLink}` : ""
    }/Checkout`;
  };

  handleCheckout = () => {
    this.viewModel.onHandleCheckout();
  };

  render() {
    const cartData = this.viewModel.cartData;
    const billingData = this.viewModel.billingData;
    const DeeplinkHeight = 46;
    const fixedHeight = 72;
    return (
      <Scaffold
        isShowLoading={this.viewModel.isLoading}
        showSnackbar={this.viewModel.snackbarMessage}
        showErrorModel={this.viewModel.errorModel}>
        <div id="CartHome" className="container">
          {!isResellerDomain() ? (
            this.renderPromotionalBanner({
              imageUrl: this.viewModel.loadingPromoBannerResult?.imageUrl,
              isLoading: this.viewModel.isLoadingPromoBanner,
              deeplink: this.viewModel.loadingPromoBannerResult?.deeplink,
            })
          ) : (
            <SizedDiv height={54} />
          )}
          <SizedDiv height={24} />

          {!isResellerDomain() ? (
            <div
              style={{
                padding: "8px 16px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}>
              {this.viewModel.cartData?.items.length ?? 0 > 0
                ? this.viewModel.cartData?.promoInfo.map((data) => {
                    return (
                      <div
                        style={{
                          backgroundColor: "#F4F5FF",
                          borderRadius: "8px",
                          padding: "12px 16px",
                          gap: "4px",
                        }}>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "700",
                          }}>
                          {data.title}
                        </div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                          }}>
                          {data.description}
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          ) : (
            <SizedDiv height={20} />
          )}

          <div
            style={{
              padding: "8px 16px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}>
            <CartItemsWidget
              cartData={cartData}
              onPlusMinusClicked={(flag, bagtItemId, quantity) =>
                this.viewModel.onAddToBag(flag, bagtItemId, quantity)
              }
              onCheckClicked={(bagItemId) =>
                this.viewModel.onChecked(bagItemId)
              }
            />
          </div>

          <CartPriceWidget cartData={cartData} billingData={billingData} />

          <div
            id="ProductTopGradient"
            className="row"
            style={{
              bottom: `${DeeplinkHeight + fixedHeight - 75}px`,
              width: "inherit",
            }}
          />
          <div className="row fixed">
            <div
              className="col-2 text-center paddingtop20"
              data-bs-toggle="modal"
              data-bs-target="#ChatModal">
              <img
                width="20"
                className="img-fluid"
                src={global.ImgUrl + "shopable/ic-detail-chat@2x.png"}
                alt="gambar1"></img>
            </div>
            <div
              className="col-10 text-center paddingtop10"
              id="CheckoutButtonContainer">
              <ButtonWidget
                buttonType={ButtonWidgetType.primary}
                isLoading={this.viewModel.isLoadingButtonSubmit ? true : false}
                isEnabled={this.viewModel.isEnableBtn ? true : false}
                width="calc(100% - 6px)"
                action={() => {
                  this.handleCheckout();
                }}>
                Checkout
              </ButtonWidget>
            </div>
            {!isResellerDomain() && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 12,
                  fontSize: 12,
                  color: "#894CF5",
                }}>
                <a href={getAppLink()} target="_blank" rel="noreferrer">
                  FREE ONGKIR checkout via app
                </a>
              </div>
            )}
          </div>

          <div
            id="SpaceNavigationBackground"
            className="row"
            style={{
              opacity: isResellerDomain()
                ? "0"
                : `${this.viewModel.navBarTransparency}`,
            }}></div>
          <div
            className="row floatingButton"
            style={{ display: "flex", alignItems: "center" }}>
            {isResellerDomain() ? (
              <>
                <BackButton
                  id="SpaceHomeButton"
                  style={{ backgroundColor: "#F2F2F2" }}>
                  <img src={ic_back} alt="" />
                </BackButton>
                <b
                  style={{
                    position: "absolute",
                    left: "42px",
                    marginTop: "12px",
                    fontSize: "20px",
                  }}>
                  My Bag
                </b>
              </>
            ) : (
              <button
                id="SpaceHomeButton"
                onClick={() => (window.location.href = "/")}>
                <img src={ic_thefepi_f} alt="" />
              </button>
            )}
          </div>
        </div>

        <CartRemovePopupWidget
          showModal={this.viewModel.showModal}
          Message={this.viewModel.Message}
          closeModal={() => {
            this.closeModal();
          }}
          removeItem={() => {
            this.viewModel.onRemoveItem(this.viewModel.bag_item_id);
          }}
        />

        <div
          className={
            this.viewModel.shouldShowErrorModal
              ? "modal-backdrop fade show"
              : ""
          }></div>
        <div
          className={
            this.viewModel.shouldShowErrorModal
              ? "modal fade show"
              : "modal fade"
          }
          style={
            this.viewModel.benefitType === "mobile-app-purchase"
              ? { display: "block" }
              : { display: "none" }
          }
          id="BenefitModal"
          aria-labelledby="ErrorModalLabel"
          aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="row" style={{ marginBottom: 18 }}>
                <div style={{ display: "flex" }}>
                  <button
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      this.viewModel.onCloseErrorModal();
                    }}>
                    <img alt="" src={ic_x} />
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <span className="ErrorModalTitle">Continue in app</span>
                  <span className="ErrorModalSubtitle">
                    {this.viewModel.errorMessage}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  <div style={{ marginBottom: 12 }}>
                    <ButtonWidget
                      action={() => {
                        window.open(this.viewModel.getAppStoreLink(), "_blank");
                      }}>
                      Open App
                    </ButtonWidget>
                  </div>
                  <ButtonWidget
                    action={() => {
                      window.location.href = isResellerDomain()
                        ? `/shop/${this.props.creatorLink}/Checkout`
                        : "/Checkout";
                    }}
                    buttonType={ButtonWidgetType.secondary}>
                    Continue here without promo
                  </ButtonWidget>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.viewModel.showModalBenefit ? "modal-backdrop fade show" : ""
          }></div>
        <div
          className={
            this.viewModel.showModalBenefit ? "modal fade show" : "modal fade"
          }
          style={
            this.viewModel.benefitType === "registered-user"
              ? { display: "block" }
              : { display: "none" }
          }
          id="BenefitModal"
          aria-labelledby="ErrorModalLabel"
          aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="row">
                <div className="col">
                  <span>
                    Get more benefit such as point and coupon if you are logged
                    in
                  </span>
                  <br></br>
                  <br></br>
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  <button
                    onClick={this.closeModalBenefit}
                    className="btn btn-secondary">
                    Continue Checkout
                  </button>
                </div>
                <div className="col text-center">
                  <a href="/Login">
                    <button className="btn btn-primary">Sign In</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isResellerDomain() ? (
          <ChannelChatPopup
            resellerNumber={getResellerPhoneNumber(this.props.creatorLink)}
            message={`Halo, ${this.props.creatorLink}`}
          />
        ) : (
          <ChannelChatPopup />
        )}
      </Scaffold>
    );
  }

  private handleScrolling() {
    const productImageContainerElement = document.querySelector(
      "#ProductImageContainer"
    );
    const spaceTopContainerRect =
      productImageContainerElement?.getBoundingClientRect();
    let transparencyValue =
      (spaceTopContainerRect?.y ?? 0) / -((this.state.width * 3) / 2 + 16);
    transparencyValue = transparencyValue > 1 ? 1 : transparencyValue;

    const navBar = document.querySelector("#SpaceNavigationBackground");
    this.viewModel.changeNavBarTransparency(transparencyValue);
    if (navBar) {
      (navBar as any).style.opacity = transparencyValue;
    }
  }
}

export default withSellerId(CartScreen);
