import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import BaseViewModel from "../../CommonComponents/Base/BaseViewModel";
import LabelItemModel from "../../CommonComponents/Models/LabelItemModel";
import ProductSummaryItemModel from "../../CommonComponents/Models/ProductSummaryItemModel";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { productTypesForBadgeUrls } from "../../CommonComponents/Utils";
import { ProductBadgeType } from "../../CommonTypes";
import { CuratedProductsResponse, CuratedProductsTimerModel } from "./Models/CureatedProductsResponse";
import CuratedProductsRepository from "./Repositories/CuratedProductsRepository";
import CuratedProductsRepositoryImpl from "./Repositories/CuratedProductsRepositoryImpl";

export default class CuratedProductsViewModel extends BaseViewModel {
    private kLimit = 24;
    private repository: CuratedProductsRepository;
    selectedGroupId: string;
    selectedCategoryId: string;
    selectedSubCategoryId: string | null;
    selectedSubCategoryDetail: string | null;
    totalOfProducts: number = 0;
    products: ProductSummaryItemModel[] = [];
    subCategoryDetails: LabelItemModel[] = [];
    pageTitle: string = "";
    numberInCart: number = 0;
    isLoading: boolean = true;
    errorMessage: ShowErrorModel | null = null;
    errorSnackbar: ShowSnackbarModel | null = null;
    loadingProducts: boolean = false;
    isShowingSort: boolean = false;
    sortModel?: LabelItemModel[];
    selectedSortId: string | null = null;
    selectedSortIdInSortWidget: string | null = null;
    timerModel?: CuratedProductsTimerModel;
    isFlashSaleEnded?: boolean;
    badgeUrlsMap?: {[key in ProductBadgeType]: string};

    constructor(args: {
        groupId: string,
        categoryId: string,
        subCategoryId: string | null,
        detailId: string | null,
    }, repository: CuratedProductsRepository = new CuratedProductsRepositoryImpl()) {
        super({key: 'CuratedsProductsViewModel'});
        this.repository = repository;        
        this.selectedGroupId = args.groupId;
        this.selectedCategoryId = args.categoryId;
        this.selectedSubCategoryId = args.subCategoryId;
        this.selectedSubCategoryDetail = args.detailId;
        this.refresh();
    }

    loadPreloadingProps() {
        this.isLoading = true;

        this.updateState({});

        this.repository.getPreloadProps({
            onSuccess: (_, __, numberInCart, x) => {
              this.badgeUrlsMap = x
                this.numberInCart = numberInCart;

                this.updateState({});
                this.loadProducts();
            },
            onFailure: error => {
                this.isLoading = false;
                this.errorMessage = {
                    message: error,
                    onRetryButtonTapped: () => this.loadPreloadingProps(),
                };

                this.updateState({});
            },
        })
    }

    loadProducts() {
        this.isLoading = this.products.length === 0;
        this.errorMessage = null;
        this.errorSnackbar = null;
        this.loadingProducts = true;

        this.updateState({});

        this.repository.getProducts({
            parameters: {
                limit: this.kLimit,
                startIndex: this.products.length,
                selectedGroup: this.selectedGroupId,
                selectedCategory: this.selectedCategoryId,
                selectedSubCategory: this.selectedSubCategoryId,
                selectedSubCategoryDetail: this.selectedSubCategoryDetail,
                selectedSortId: this.selectedSortId,
            },
            onSuccess: (response: BaseResponse<CuratedProductsResponse>) => {
                const totalOfProducts = response.data.items.length === 0 ? 0 : response.data.totalOfItems;
                
                if (!this.timerModel && response.data.timer) {
                    this.timerModel = response.data.timer;

                    if (this.timerModel.durationRemainingInSeconds > 0) {
                        this.handleTimer();
                    } else {
                        this.isFlashSaleEnded = true;
                    }
                }

                if (!this.sortModel) {
                    this.sortModel = response.data.sorts;
                }

                if (this.sortModel && this.sortModel.length > 0 && this.products.length === 0) {
                    if (this.selectedSortId === null) {
                        this.selectedSortId = this.sortModel[0].id;
                    }
                    
                    this.selectedSortIdInSortWidget = this.selectedSortId;
                }

                if (!response.data.subCategoryDetails) {
                  response.data.subCategoryDetails = []
                }

                if (!this.selectedSubCategoryDetail && response.data.subCategoryDetails.length > 0) {
                    this.selectedSubCategoryDetail = response.data.subCategoryDetails[0].id;
                }

                this.isLoading = false;
                this.totalOfProducts = totalOfProducts;
                this.products = this.products.concat(response.data.items.map(i => ({...i, badgeUrls: productTypesForBadgeUrls(i.badgeTypes, this.badgeUrlsMap || {})})));
                this.pageTitle = response.data.title ?? this.selectedCategoryId ?? "";
                this.loadingProducts = false;
                if (this.subCategoryDetails.length === 0) this.subCategoryDetails = response.data.subCategoryDetails;
                
                this.updateState({});
            },
            onFailure: (error: string) => {
                this.isLoading = false;
                this.errorMessage = this.products.length === 0 ? 
                    {
                        message: error,
                        onRetryButtonTapped: () => this.loadProducts(),
                    } : null;
                this.errorSnackbar = this.products.length !== 0 ? new ShowSnackbarModel({ message: error }) : null;
                this.loadingProducts = false;

                this.updateState({});
            },
        });
    }

    changeSelectedSubCategoryDetail(value: string) {
        this.selectedSubCategoryDetail = value;
        this.products = [];

        this.updateState({});
        this.loadProducts();
    }

    changeSelectedSortInSortWidget(value: string) {
        this.selectedSortIdInSortWidget = value;

        this.updateState({});
    }

    showSort() {
        this.isShowingSort = true;

        this.updateState({});
    }

    hideSort() {
        this.isShowingSort = false;
        this.selectedSortIdInSortWidget = this.selectedSortId;

        this.updateState({});
    }

    applySort() {
        const shouldReloadProducts = this.selectedSortId !== this.selectedSortIdInSortWidget;
        this.selectedSortId = this.selectedSortIdInSortWidget;

        this.hideSort();

        if (shouldReloadProducts) {
            this.products = [];
            this.loadProducts();
        }
    }

    private handleTimer() {
        setTimeout(() => {
            if (this.timerModel) {
                this.timerModel = {
                    totalDurationInSeconds: this.timerModel.totalDurationInSeconds,
                    durationRemainingInSeconds: this.timerModel.durationRemainingInSeconds - 1,
                };

                this.updateState({});

                if (this.timerModel.durationRemainingInSeconds > 0) {
                    this.handleTimer();
                } else {
                    this.isFlashSaleEnded = true;
                }
            }
        }, 1000);
    }

    override copy(args: {}): this {
        return this;
    }
}
