import img_illustration_1 from "../../Assets/Images/CreatorIntroduction/AppFeedFeatureRevision_explanationsliced_2511_1.png";
import img_illustration_2 from "../../Assets/Images/CreatorIntroduction/img_creator_introduction_2.svg";
import img_illustration_3 from "../../Assets/Images/CreatorIntroduction/img_creator_introduction_3.svg";
import img_illustration_4 from "../../Assets/Images/CreatorIntroduction/img_creator_introduction_4.svg";

import img_title_1 from "../../Assets/Images/CreatorIntroduction/img_creator_introduction_title_1.svg";
import img_title_2 from "../../Assets/Images/CreatorIntroduction/img_creator_introduction_title_2.svg";

import image_1 from "../../Assets/Images/CreatorIntroduction/img_1.svg";
import image_2 from "../../Assets/Images/CreatorIntroduction/img_2.svg";
import image_3 from "../../Assets/Images/CreatorIntroduction/img_3.svg";
import image_4 from "../../Assets/Images/CreatorIntroduction/img_4.svg";

import "./CreatorIntroduction.css";
import "bootstrap/dist/js/bootstrap.min.js";

import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import {
  getLocationQueries,
  isResellerDomain,
} from "../../CommonComponents/Utils";
import ButtonWidget from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import mixpanel from "mixpanel-browser";

export default function CreatorIntroductionViewController() {
  //MIXPANEL TRACKING: semo_page_view / screen_view
  mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
    page_name: "CreatorIntroduction",
  });

  const faqs = {
    sections: [
      {
        title: "BENEFIT",
        items: [
          {
            question:
              "Apa sih keuntungan memposting content lifestyle di The Fepi?",
            answer:
              "Kamu akan mendapatkan komisi menggiurkan dari setiap produk yang terjual dari postinganmu.<ul><li>Kategori Fashion (5%, max. 50.000/produk)</li><li>Kategori Fashion (5%, max. 50.000/produk)</li><li>Kategori Food (3%, max. 2.000/produk)</li></ul>",
          },
          {
            question:
              "Apakah aku juga bisa bergabung untuk mendapatkan komisi di thefepi?",
            answer:
              "Tentu saja! Kini siapapun bisa mendapatkan komisi dari thefepi.<br/>Cukup download The Fepi apps dari Android / iOS, sign in untuk memiliki akun The Fepi dan mulailah MEMPOSTING berbagai content lifestyle menarik di akunmu!",
          },
          {
            question: "Kapan komisi akan masuk ke akunku?",
            answer:
              'Ketika pesanan si pembeli telah berubah menjadi "Pesanan Selesai" maka komisimu akan otomatis masuk ke akunmu.',
          },
          {
            question: "Dimana bisa mengecek detail komisi?",
            answer:
              "Cek halaman My Reward untuk melihat detail komisi yang kamu dapatkan dan riwayat cash out yang telah kamu lakukan.",
          },
        ],
      },
      {
        title: "BECOMING A CREATOR",
        items: [
          {
            question:
              "Setelah memiliki akun thefepi, content seperti apa yang bisa dipost?",
            answer:
              "Kamu bisa memposting content menarik dengan menggunakan produk dari berbagai kategori  di thefepi.<br/>Saat ini tersedia kategori Fashion, Beauty & Food, namun kami akan terus mengembangkan kategori kebutuhan lifestyle lainnya. Stay tune terus di thefepi, ya!",
          },
          {
            question: "Apakah harus membeli item di The Fepi dulu?",
            answer:
              "Kamu bisa menggunakan item yang sudah kamu punya, kok. Asalkan item kamu juga tersedia di website The Fepi. (Tips: kamu bisa banget pinjam teman dan keluarga!)",
          },
          {
            question: "Contentnya berupa foto atau video?",
            answer:
              "Untuk saat ini content yang bisa kamu post adalah dalam bentuk foto. Namun jangan khawatir, tim kami sedang dalam tahap pengembangan agar kamu bisa memposting content dalam bentuk video.",
          },
          {
            question:
              "Apakah ada ketentuan mengenai foto yang boleh diposting?",
            answer:
              "Kami memberi kebebasan padamu untuk berkreasi, tapi pastikan content yang kamu upload dan tidak mengandung hal sensitif, seperti SARA, pornografi, serta hal melanggar hukum lainnya.<br/>Foto yang tidak berhubungan dengan produk thefepi bisa saja dihapus oleh admin kami tanpa pemberitahuan terlebih dahulu.",
          },
          {
            question:
              "Ada tips nggak ya supaya content fotoku banyak yang tertarik?",
            answer:
              "Pastikan foto yang kamu upload memiliki resolusi yang baik agar dapat terlihat jelas. Jangan lupa highlight produknya secara unik dan menarik agar banyak yang membeli produknya dari content yang kamu posting.",
          },
        ],
      },
      {
        title: "CASH OUT COMMISION",
        items: [
          {
            question: "Kapan komisi yang sudah aku dapatkan bisa dicairkan?",
            answer:
              "Kamu bisa bisa mulai mencairkan komisi dengan minimal saldo 50.000.",
          },
          {
            question: "Metode apa yang digunakan untuk mencairkan komisi?",
            answer:
              "Kami akan mengirimkan dana komisi melalui bank transfer. Biaya transfer bank dan pajak 10% akan dipotong dari komisi, ya.",
          },
          {
            question:
              "Apakah ada dokumen-dokumen yang diperlukan untuk pencairan komisi?",
            answer:
              'Ketika pesanan si pembeli telah berubah menjadi "Pesanan Selesai" maka komisimu akan otomatis masuk ke akunmu.',
          },
        ],
      },
    ],
  };

  return (
    <Scaffold>
      <div id="CreatorIntroductionFirstSection">
        <img src={img_title_1} alt="Share your day get side hustle" />
        <img src={img_illustration_1} alt="Snap and Earn Money" />
        <SizedDiv height={48} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              backgroundColor: "#FFEE2A",
              padding: "0px 8px",
              borderRadius: 8,
              color: "#EB433D",
              width: "fit-content",
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            Dapatkan komisi s/d 5%
          </p>
          <p>dari tag produk yang terjual di postinganmu</p>
        </div>
        <div>
          <div id="CreatorHowToMakeContent">
            <img src={image_1} alt="1" />
            <p>Post lifestyle-mu yang menarik bersama produk thefepi.</p>
          </div>
          <div id="CreatorHowToMakeContent">
            <img src={image_2} alt="2" />
            <p>
              Tag item dalam content dengan yang produk yang tersedia di
              thefepi.
            </p>
          </div>
          <div id="CreatorHowToMakeContent">
            <img src={image_3} alt="3" />
            <p>
              Bagikan ke sosial media lain agar semakin banyak yang tertarik.
            </p>
          </div>
          <div id="CreatorHowToMakeContent">
            <img src={image_4} alt="4" />
            <p>
              Dapatkan komisi s/d 5 % dari setiap tag produk yang berhasil
              terjual di content-mu.
            </p>
          </div>
        </div>
        <SizedDiv height={24} />
        <img src={img_title_2} alt="" style={{ width: "100%" }} />
        <SizedDiv height={24} />
        <p>Hangout dengan FASHION keren?</p>
        <p style={{ fontWeight: 700, fontSize: 18 }}>
          Jangan lupa mirror selfie dulu, ya~ 😚
        </p>
        <SizedDiv height={24} />
        <img src={img_illustration_2} alt="" style={{ width: "100%" }} />
        <SizedDiv height={24} />
        <p>Makan cemilan favorit sambil nonton Netflix?</p>
        <p style={{ fontWeight: 700, fontSize: 18 }}>
          Wait! Take a picture before you eat~ 📸
        </p>
        <SizedDiv height={24} />
        <img src={img_illustration_3} alt="" style={{ width: "100%" }} />
        <SizedDiv height={24} />
        <p>
          Skincare routine di malam hari?
          <br />
          Yuk, share juga produk andalan yang bikin
        </p>
        <p style={{ fontWeight: 700, fontSize: 18 }}>
          Wait! Take a picture before you eat~ 📸
        </p>
        <SizedDiv height={24} />
        <img src={img_illustration_4} alt="" style={{ width: "100%" }} />
        <SizedDiv height={24} />
        <p>
          Masih ada lifestyle seru lainnya yang kamu abadikan melalui
          smartphone-mu?
        </p>
        <SizedDiv height={24} />
        <img src={img_illustration_4} alt="" style={{ width: "100%" }} />
        <SizedDiv height={24} />
        <p style={{ fontWeight: 700, fontSize: 14 }}>
          POST semua di space-mu dan TAG produknya!
          <br />
          🫰🏻 Jadilah inspirasi dan dapatkan komisi~ 🫰🏻
        </p>
      </div>
      <div id="CreatorIntroductionThirdSection">
        <p>FAQ</p>
        <div style={{ display: "block" }}>
          <div className="accordion" id="CreatorAccordion">
            {faqs.sections.map((section, index) => {
              return (
                <>
                  {index === 0 && <div className="Separator" />}
                  <div className="accordion-item" style={{ border: "none" }}>
                    <div
                      className="accordion-header"
                      id={`${index === 0 ? "headingOne" : "headingTwo"}`}
                    >
                      <button
                        type="button"
                        className={`accordion-button${
                          index !== 0 ? " collapsed" : ""
                        }`}
                        data-bs-toggle="collapse"
                        data-bs-target={`#FAQListSection_${index}`}
                        style={{
                          boxShadow: "none",
                          backgroundColor: "transparent",
                          fontSize: 14,
                          fontWeight: 700,
                          color: "black",
                        }}
                      >
                        <p id="CreatorFaqSectionTitle">{section.title}</p>
                      </button>
                    </div>
                    <div
                      id={`FAQListSection_${index}`}
                      className={`accordion-collapse collapse${
                        index === 0 ? " show" : ""
                      }`}
                      data-bs-parent="#CreatorAccordion"
                    >
                      <div
                        style={{
                          padding: "12px 16px 24px",
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "24px",
                        }}
                      >
                        {section.items.map((item) => (
                          <div style={{ display: "flex", columnGap: "8px" }}>
                            <p className="Question">Q</p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 8,
                              }}
                            >
                              <p className="Question">{item.question}</p>
                              <p
                                className="Answer"
                                dangerouslySetInnerHTML={{
                                  __html: item.answer,
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="Separator" />
                </>
              );
            })}
          </div>
          <p
            style={{
              margin: "16px 0px",
              fontSize: "12px",
            }}
          >
            Apabila kamu memiliki pertanyaan lebih lanjut dan belum tercantum di
            sini, silakan kontak Customer Service kami, ya. Kami akan standby 24
            jam untuk kamu :)
          </p>
          <a href="/TermService">
            <p
              style={{
                textAlign: "center",
                width: "100%",
                minWidth: "100%",
                padding: 8,
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              Syarat dan Ketentuan
            </p>
          </a>
        </div>
      </div>
      {getLocationQueries()["device"] === "web" && (
        <>
          <div
            style={{
              height: 82,
            }}
          />
          <div
            style={{
              height: 82,
              padding: 16,
              boxShadow: "0px 0px 8px rgba(153, 157, 169, 0.25)",
              position: "fixed",
              bottom: 0,
              width: "100%",
              maxWidth: 600,
              backgroundColor: "white",
            }}
          >
            <ButtonWidget
              action={() => {
                window.location.href = window.navigator.userAgent.includes(
                  "Android"
                )
                  ? "https://play.google.com/store/apps/details?id=com.thefepi.shopable"
                  : "https://apps.apple.com/id/app/thefepi/id1590816062";
              }}
              backgroundColor="var(--purple)"
            >
              Mulai Sekarang
            </ButtonWidget>
          </div>
        </>
      )}
    </Scaffold>
  );
}
