import React from "react";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { CreatorContentCellModel } from "../Models/CreatorContentsResponse";

import "./CreatorSpace.css";

import ic_no_feed from "../../../Assets/Images/ic_no_feed.svg";

type CreatorSpaceContentListWidgetProps = {
    contents: CreatorContentCellModel[] | null,
    width: number
}

export default class CreatorSpaceContentListWidget extends React.Component<CreatorSpaceContentListWidgetProps, {}> {
    render() {
        return(
            <>
                {
                    (this.props.contents?.length ?? 0) > 0 ?
                    <div id="SpaceCategoryContentsContainer">
                        {
                            this.props.contents?.map((model, _) => {
                                return (
                                    <button 
                                        id="SpaceContentContainer" 
                                        key={model.id} 
                                        style={{height:`${(this.props.width - 20)* 3 / 4}px`}}
                                        onClick={() => window.location.href = `/Content/${model.id}`}
                                    >
                                        <ImageWidget imageUrl={model.imageUrl} key={model.imageUrl} />
                                    </button>
                                );
                            })
                        }
                    </div> :
                    <div id="SpaceCategoryNoItemContainer">
                        <img src={ic_no_feed} alt="" />
                        <p>There are no feed yet.</p>
                    </div>
                }
            </>
        );
    }
}