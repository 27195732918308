import { AnalyticEvent } from "../AnalyticEvent";
import { ItemEventEntity } from "../Common";
import { EventState } from "../EventState";
import { GA4BaseEvent } from "./GA4BaseEvent";


export class AddPaymentInfoEvent extends GA4BaseEvent implements AnalyticEvent {
  payload: EventState;
  name: string;

  constructor(args: EventState) {
    super();
    this.payload = args;
    this.name = "ADD_PAYMENT_INFO_GA4"
  }

  exec(): void {
    this.fire("add_payment_info", {
      currency: this.payload.currency,
      value: this.payload.value,
      coupon: this.payload.coupon?.name,
      payment_type: this.payload.paymentType,
      items: this.payload.items?.map((item) => ({
        item_id: item.id,
        item_name: item.name,
        discount: item.price?.discount,
        item_brand: item.brand,
        item_variant: item.variant,
        price: item.price?.net,
        quantity: item.quantity,
      })) || []
    });
  }
}
