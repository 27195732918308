import React from "react";
import { normalizeString } from "../../Utils";

type ReadMoreWidgetProps = {
    children: string,
    numberOfShrinkedChars: number,
    id?: string,
    className?: string
}

type ReadMoreWidgetState = {
    isReadMore: boolean
}

export default class ReadMoreWidget extends React.Component<ReadMoreWidgetProps, ReadMoreWidgetState> {
    constructor(props: ReadMoreWidgetProps) {
        super(props);

        this.state = {
            isReadMore: true
        }
    }
    render() {
        return(
            <p id={this.props.id} className={this.props.className}>           
                {
                    this.props.children.length > this.props.numberOfShrinkedChars
                    ? <>
                        {
                            
                                
                                    this.state.isReadMore ? 
                                    [...normalizeString(this.props.children, 60), ' ... '] : 
                                    normalizeString(this.props.children)
                                
                            
                        }
                        {
                            this.props.children.length > this.props.numberOfShrinkedChars &&
                            <span onClick={() => { this.setState({isReadMore: !this.state.isReadMore})}} style={{textDecoration:"underline", fontWeight:"bold"}}>
                                {this.state.isReadMore ? " Read More" : " Read Less"}
                            </span>
                        }
                    </>
                    : normalizeString(this.props.children)
                }
            </p>
        );
    }
}