import React from "react";
import {useNavigate} from 'react-router-dom';

export default function GoBackWidget(){
  const navigate = useNavigate();

  return (
    <div className="row" id="GoBack">
        <div className="col text-left">
            <a href="#;" onClick={() => navigate(-1)}>{"< Back to product detail"}</a>
        </div>
    </div>
  );
}