import React from "react";
import { VideoPlayer } from "../../../CommonComponents/UI/Cells/VideoPlayer";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { getLocationQueries, getScreenSizeWithMaxWidth } from "../../../CommonComponents/Utils";
import { ShowCaseDetailCoverModel } from "../../Detail/Models/ShowCaseDetailCoverModel";

export default class ShowCaseHomeFeedWidget extends React.Component<{model: ShowCaseDetailCoverModel}> {
    render() {
        const model = this.props.model;
        const topMargin = getLocationQueries()["topSafeAreaMargin"] ?? "";

        return <div 
            id="ShowCaseHomeFeed" 
            onClick={ 
                () => {
                    let directionUrl = `/ShowCase/Detail?id=${model.id}`;

                    directionUrl += `${topMargin.length > 0 ? `&topSafeAreaMargin=${topMargin}` : ""}`;
                    directionUrl += `${model.contentTheme ? `&theme=${model.contentTheme}` : ""}`;
                    directionUrl += `${model.navBarIconColor ? `&navBarIconColor=${model.navBarIconColor}` : ""}`;
                    directionUrl += `${model.navBarTitle ? `&title=${(model.navBarTitle ?? "").replace(" ", "%20")}` : ""}`;

                    window.location.href = directionUrl;
                }
            }
            style={{
                height:`${getScreenSizeWithMaxWidth().width / 0.8}px`
            }}
        >
            {
                model.assetType === "VIDEO" ?
                <VideoPlayer
                    videoUrl={model.assetUrl} 
                    videoThumbUrl={model.videoThumbnailUrl} 
                    style={{
                        borderRadius: "12px"
                    }}
                /> :
                <ImageWidget imageUrl={model.assetUrl} />
            }
    
            <div>
                <div 
                    style={{
                        textAlign: model.textAlignment === "CENTER" ? "center" : "left"
                    }}
                >
                    <p>{model.title}</p>
                    <p>{model.subtitle}</p>
                </div>

                <div
                    style={{
                        flexDirection: model.textAlignment === "CENTER" ? "column" : "row",
                    }}
                >
                    <div
                        style={{height: `${model.textAlignment === "CENTER" ? 100 : 48}px`, width: `${model.textAlignment === "CENTER" ? 100 : 48}px`}}
                    >
                        <ImageWidget imageUrl={model.profileImageUrl} />
                    </div>
                    {
                        model.feedContextLabel && 
                        <div>
                            <p>{model.feedContextLabel}</p>
                        </div>
                    }
                </div>

            </div>
        </div>;
    }
}