import React from "react";
import { convertToRupiah } from "../../../../CommonComponents/Utils";
import "./CheckoutBillingWidget.css";

type CheckoutBillingWidgetProps = {
  billing?: {
    label: string;
    value: number;
  }[];
  total?: number;
};

export default class CheckoutBillingWidget extends React.Component<CheckoutBillingWidgetProps> {
  render() {
    const { billing, total } = this.props;
    
    return (
      <div id="CheckoutBillingWidget" className="container">
         <div className="row" id="billings">
          <div className="col-7">
            <span className="label">Total</span>
          </div>
          <div className="col-1 no-padding dotted"></div>
          <div className="col-4 text-end">
            <span className="title">
              <b>{convertToRupiah(total)}</b>
            </span>
          </div>
        </div>
        <div className="divider"></div>       
        {billing?.map((b, index) => (
          <div className="billingItem" id="billings" key={index}>
            <span className="label" style={{maxWidth: 200}}>
              {b.label}
            </span>
            <div className="dotdot"></div>
            <span className="price" style={{flexShrink: 0}}>
              {b.value < 0 ? (
                <span className="discPrice">
                  {"- " + convertToRupiah(b.value * -1)}{" "}
                </span>
              ) : (
                convertToRupiah(b.value)
              )}
            </span>
          </div>
        ))}
      </div>
    );
  }
}
