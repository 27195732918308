import React from "react";
import { getFepiCSNumber, isResellerDomain } from "../../Utils";
import { mixpanelManager } from "../../../Analytics/Mixpanel/Common";

type ChannelChatProps = {
  resellerNumber?: string;
  message?: string;
};

export default class ChannelChatPopup extends React.Component<
  ChannelChatProps,
  {}
> {
  render() {
    return (
      <div
        className="modal fade"
        id="ChatModal"
        aria-labelledby="ChatModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close closeBtn"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            <div className="modal-body">
              <p className="fsize22">
                <b>Ask Anytime To</b>
              </p>
              {!isResellerDomain() ? (
                <div className="row ptop30">
                  <div className="col text-end pright30">
                    <a
                      onClick={() =>
                        mixpanelManager.helpClick(
                          mixpanelManager.getPageNameByUrlV2(),
                          "wa"
                        )
                      }
                      rel="noreferrer"
                      href={
                        isResellerDomain()
                          ? `https://wa.me/${this.props.resellerNumber}?text=${this.props.message}`
                          : `https://wa.me/${getFepiCSNumber()}?text=${
                              this.props.message || "Halo thefepi"
                            }`
                      }
                      target="_blank"
                    >
                      <img
                        width="70"
                        src={
                          global.ImgUrl +
                          "shopable/A10F5243-E293-4210-A3D2-1B61C1361F21.svg"
                        }
                        alt="wa"
                      />
                    </a>
                  </div>
                  <div className="col text-left pleft30">
                    <a
                      rel="noreferrer"
                      id="custom-button-1"
                      href="/ChannelSinglePage"
                    >
                      <img
                        width="70"
                        src={
                          global.ImgUrl +
                          "shopable/9937B910-0DF2-452B-A6DE-F5C47529F3CD.png"
                        }
                        alt="io"
                      />
                    </a>
                  </div>
                </div>
              ) : (
                <div className="row ptop30">
                  <div
                    className="text-end pright30"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      onClick={() =>
                        mixpanelManager.helpClick(
                          mixpanelManager.getPageNameByUrlV2(),
                          "live chat"
                        )
                      }
                      rel="noreferrer"
                      href={
                        isResellerDomain()
                          ? `https://wa.me/${this.props.resellerNumber}?text=${this.props.message}`
                          : `https://wa.me/${getFepiCSNumber()}?text=${
                              this.props.message || "Halo thefepi"
                            }`
                      }
                      target="_blank"
                    >
                      <img
                        width="70"
                        src={
                          global.ImgUrl +
                          "shopable/A10F5243-E293-4210-A3D2-1B61C1361F21.svg"
                        }
                        alt="wa"
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
