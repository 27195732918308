import React from "react";
import "./LoadingWidget.css"

type LoadingWidgetProps = {
    theme?: "dark" | "light"
}

export class LoadingWidget extends React.Component<LoadingWidgetProps, {}> {
    render() {
        return(
            <div className={`LoadingWidgetContainer ${this.props.theme === "dark" ? "LoadingWidgetDarkTheme" : "LoadingWidgetLightTheme"}`}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }
}