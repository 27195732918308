enum OrderType {
  TO_PAY = "TO_PAY",
  PACKING = "PACKING",
  DELIVERY = "DELIVERY",
  TO_REVIEW = "TO_REVIEW",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export default OrderType;
