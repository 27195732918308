import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { IndexIndicator } from "../../../CommonComponents/UI/IndexIndicator/IndexIndicatorWidget";
import { getScreenSizeWithMaxWidth } from "../../../CommonComponents/Utils";
import ProductDetailResponse from "../Models/ProductDetailResponse";

type ProductDetailImageWidgetProps = {
    screenWidth: number,
    productDetail: ProductDetailResponse | null
};

type ProductDetailImageWidgetState = {
    showingIndex: number
}

export default class ProductDetailImageWidget extends React.Component<ProductDetailImageWidgetProps, ProductDetailImageWidgetState> {
    constructor(props: ProductDetailImageWidgetProps) {
        super(props);

        this.state = {
            showingIndex: 0
        };
    }

    render() {
       return(
            <div id="ProductImageContainer">
                <Swiper 
                    id="SpaceCoverContainer" 
                    slidesPerView={1}
                    style={{height:`100%`}}
                    onSlideChangeTransitionEnd={(swiper) => {
                        this.setState({
                            showingIndex: swiper.activeIndex
                        });
                    }}
                >
                    {
                        Array.from({length: ((this.props.productDetail?.productImageUrls ?? []).length ?? 0)}).map((_, index) => {
                            return <SwiperSlide key={index} style={{
                                height: `${getScreenSizeWithMaxWidth().width * 4 / 3}px`
                            }}>
                                <ImageWidget imageUrl={this.props.productDetail?.productImageUrls[index] ?? ""} key={`${index}_image`} />
                            </SwiperSlide>;
                        })
                    }
                </Swiper>
                <div id="SpaceIndexIndicatorContainer" >
                    <IndexIndicator 
                        showingIndex={this.state.showingIndex} 
                        totalOfIndices={(this.props.productDetail?.productImageUrls ?? []).length ?? 0} 
                    />
                </div>
            </div>
        );
    }
}