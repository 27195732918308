
import BaseViewModel from "../../CommonComponents/Base/BaseViewModel";
import { ValidateEmailTelp } from "../../CommonComponents/Utils";
import axios from "axios";

export default class CreatorRegistrationViewModel extends BaseViewModel {
    email: string = '';
    name: string = '';
    dob: string = '';
    mobile: string = '';
    sosmed_check_ig: boolean = false;
    sosmed_check_tiktok: boolean = false;
    sosmed_ig: string = '';
    sosmed_tiktok: string = '';
    isLoadingButtonSubmit: boolean = false;
    errorMessage: string | null = null;
    isSuccess: boolean = false;
    errorMessageEmail: string = '';
    errorMessageName: string = '';
    errorMessageDOB: string = '';
    errorMessageMobile: string = '';
    errorMessageSosmed: string = '';
    isClearValidSosmed: boolean = false;
    brand_like: string = '';
    shop_outcome: string = '';
    file_upload: string = '';
    cn: string = '';

    onEmailChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        this.updateState({errorMessageEmail: null})
        
        if(ValidateEmailTelp(val) === true) {
            this.updateState({errorMessageEmail: ''})
            this.updateState({
                email: val
            })
        }
        else {
            this.updateState({errorMessageEmail: 'Plese input valid email'})
            this.updateState({
                email: ''
            })
        }
    }

    onNameChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        
        this.updateState({
            name: val
        })
    }

    onDOBChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        
        this.updateState({
            dob: val
        })
    }

    onMobileChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        
        this.updateState({
            mobile: val
        })
    }

    onSosmedCheckIGChanged(val: boolean) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        if(this.sosmed_ig !== '' && val === true)
        {
            this.updateState({isClearValidSosmed: true})
        }
        else
        {
            this.updateState({isClearValidSosmed: false})
        }

        this.updateState({
            sosmed_check_ig: val
        })
    }

    onSosmedCheckTiktokChanged(val: boolean) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        if(this.sosmed_tiktok !== '' && val === true)
        {
            this.updateState({isClearValidSosmed: true})
        }
        else
        {
            this.updateState({isClearValidSosmed: false})
        }
        this.updateState({
            sosmed_check_tiktok: val
        })
    }

    onSosmedIGChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        if(this.sosmed_check_ig === true && val !== '')
        {
            this.updateState({isClearValidSosmed: true})
        }
        else
        {
            this.updateState({isClearValidSosmed: false})
        }
        
        this.updateState({
            sosmed_ig: val
        })
    }

    onSosmedTiktokChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        if(this.sosmed_check_tiktok === true && val !== '')
        {
            this.updateState({isClearValidSosmed: true})
        }
        else
        {
            this.updateState({isClearValidSosmed: false})
        }
        
        this.updateState({
            sosmed_tiktok: val
        })
    }

    onBrandLikeChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        
        this.updateState({
            brand_like: val
        })
    }

    onShopOutcomeChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        
        this.updateState({
            shop_outcome: val
        })
    }

    onFileUploadChanged(val: string | ArrayBuffer) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        
        this.updateState({
            file_upload: val
        })
    }

    onSnackbarIsFinished() {
        this.updateState({errorMessage: null})
    }

    onSubmit() {
        this.updateState({isLoadingButtonSubmit: true})

        var arr_sosmed = [];
        this.sosmed_check_ig === true && arr_sosmed.push(1); 
        this.sosmed_check_tiktok === true && arr_sosmed.push(2);

        var arr_shop_outcome = [this.shop_outcome];
        
        const requestOptions = {
            email: this.email,
            full_name: this.name,
            dob: this.dob,
            mobile: this.mobile,
            checkbox_sosmed: arr_sosmed,
            username_ig: this.sosmed_ig,
            username_tiktok: this.sosmed_tiktok,
            brand_like: this.brand_like,
            radio_amount: arr_shop_outcome,
            file: this.file_upload 
        }
        axios.post('https://manage.file.thefepi.com/bo/shopable/SubmitRegistrationFinal2', requestOptions )
        .then(res=>{
            if(res.data.ResultMsg === 'Success') {
                this.updateState({isSuccess: true});
            } else{
                console.log(res.data.message)
                this.updateState({errorMessage: res.data.message, isLoadingButtonSubmit: false});
            }
            
        })
    }

    override copy({
        email = this.email,
        name = this.name,
        dob = this.dob,
        mobile = this.mobile,
        sosmed_check_ig = this.sosmed_check_ig,
        sosmed_check_tiktok = this.sosmed_check_tiktok,
        sosmed_ig = this.sosmed_ig,
        sosmed_tiktok = this.sosmed_tiktok,
        isLoadingButtonSubmit = this.isLoadingButtonSubmit,
        errorMessage = this.errorMessage,
        errorMessageEmail = this.errorMessageEmail,
        errorMessageDOB = this.errorMessageDOB,
        errorMessageMobile = this.errorMessageMobile,
        errorMessageSosmed = this.errorMessageSosmed,
        isSuccess = this.isSuccess,
        isClearValidSosmed = this.isClearValidSosmed,
        brand_like = this.brand_like,
        shop_outcome = this.shop_outcome,
        file_upload = this.file_upload,
        cn = this.cn
    }): this {
        this.email = email;
        this.name = name;
        this.dob = dob;
        this.mobile = mobile;
        this.sosmed_check_ig = sosmed_check_ig;
        this.sosmed_check_tiktok = sosmed_check_tiktok;
        this.sosmed_ig = sosmed_ig;
        this.sosmed_tiktok = sosmed_tiktok;
        this.isLoadingButtonSubmit = isLoadingButtonSubmit;
        this.errorMessage = errorMessage;
        this.errorMessageEmail = errorMessageEmail;
        this.errorMessageDOB = errorMessageDOB;
        this.errorMessageMobile = errorMessageMobile;
        this.errorMessageSosmed = errorMessageSosmed;
        this.isSuccess = isSuccess;
        this.isClearValidSosmed = isClearValidSosmed;
        this.brand_like = brand_like;
        this.shop_outcome = shop_outcome;
        this.file_upload = file_upload;
        this.cn = cn;

        return this;
    }
}