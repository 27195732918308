import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import BaseViewModel from "../../CommonComponents/Base/BaseViewModel";
import LoginSsoResponse from "./Models/LoginSsoResponse";
import { ValidateEmailTelp } from "../../CommonComponents/Utils";
import mixpanel from "mixpanel-browser";

export default class LoginViewModel extends BaseViewModel {
    input_email_telp: string = '';
    pwd: string = '';
    isLoadingButtonSubmit: boolean = false;
    errorMessage: string | null = null;
    isSuccess: boolean = false;
    errorMessageEmailTelp: string = '';

    onEmailOrPhoneNumberChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        this.updateState({errorMessageEmailTelp: null})

        if(ValidateEmailTelp(val) === true) {
            this.updateState({errorMessageEmailTelp: ''})
            this.updateState({
                input_email_telp: val
            })
        }
        else {
            this.updateState({errorMessageEmailTelp: 'Plese input valid email or phone number'})
            this.updateState({
                input_email_telp: ''
            })
        }
    }

    onPwdChanged(val: string) {
        this.updateState({errorMessageEmailTelp: null})
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})

        this.updateState({
            pwd: val
        })
        if(ValidateEmailTelp(this.input_email_telp) === true) {
            this.updateState({errorMessageEmailTelp: ''})
        }
        else {
            this.updateState({errorMessageEmailTelp: 'Plese input valid email or phone number'})
        }
    }

    onSnackbarIsFinished() {
        this.updateState({errorMessage: null})
    }

    onSubmit() {
        this.updateState({isLoadingButtonSubmit: true})
        this.updateState({errorMessageEmailTelp: ''})
        requestAPI(Endpoints.loginSso, {
            parameters: {
                input_email_telp: this.input_email_telp,
                pwd: this.pwd,
                svc_id : '101'
            },
            onSuccess: (response: BaseResponse<LoginSsoResponse>) => {
                this.updateState({isLoadingButtonSubmit: false})
                localStorage.setItem("fpAuth", response.data?.fpAuth ?? '');
                localStorage.removeItem("cn")
                localStorage.removeItem("uuid")
                localStorage.removeItem("mixpanelSuperProperties")
                mixpanel.reset()

                this.updateState({isSuccess: true});
            },
            onFailure: (error) => {
                this.updateState({isLoadingButtonSubmit: false})
                this.updateState({errorMessage: error});
            }
        });
    }

    override copy({
        input_email_telp = this.input_email_telp, 
        pwd = this.pwd,
        isLoadingButtonSubmit = this.isLoadingButtonSubmit,
        errorMessage = this.errorMessage,
        errorMessageEmailTelp = this.errorMessageEmailTelp,
        isSuccess = this.isSuccess
    }): this {
        this.input_email_telp = input_email_telp;
        this.pwd = pwd;
        this.isLoadingButtonSubmit = isLoadingButtonSubmit;
        this.errorMessage = errorMessage;
        this.errorMessageEmailTelp = errorMessageEmailTelp;
        this.isSuccess = isSuccess;

        return this;
    }
}