import React from "react";

export type RadioFieldWidgetProps = {
  label?: string;
  onChecked?: (value?: string) => void;
  value?: string;
  isChecked?: boolean;
};

function RadioFieldWidget(props: RadioFieldWidgetProps) {
  const { label, onChecked, value, isChecked = false } = props;

  return (
    <button
      style={{ display: "flex", alignItems: "center" }}
      onClick={() => {
        onChecked?.(value);
      }}
    >
      {isChecked ? (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0Z"
            fill="#894CF5"
          />
          <path
            d="M9.65049 4.56115L5.6343 8.63733L3.96496 6.96266C3.75583 6.75353 3.41676 6.75353 3.20763 6.96266C2.9985 7.17179 2.9985 7.51086 3.20763 7.71999L5.25563 9.76799C5.35577 9.86896 5.49209 9.92575 5.6343 9.92575C5.7765 9.92575 5.91282 9.86896 6.01296 9.76799L10.4292 5.29715C10.6383 5.08802 10.6383 4.74895 10.4292 4.53981C10.22 4.33068 9.88096 4.33068 9.67182 4.53981L9.65049 4.56115Z"
            fill="white"
            stroke="white"
            stroke-width="0.3"
          />
        </svg>
      ) : (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7Z"
            stroke="#999999"
          />
        </svg>
      )}
      <p style={{ marginLeft: 8, fontWeight: 400, fontSize: "12px" }}>
        {label}
      </p>
    </button>
  );
}

export default RadioFieldWidget;
