export type TimeRemaining = {
    seconds: string;
    minutes: string;
    hours: string;
    days: string;
    remainingTimeDivWidth: number;
}


export default function timerCalculation(args: {
    timeRemaining?: number,
    totalDurationInSeconds?: number,
    screenWidth: number
}): TimeRemaining | undefined {
    const timeRemaining = args.timeRemaining;
    const totalDurationInSeconds = args.totalDurationInSeconds ?? 0;

    if (timeRemaining) {
        const timeNumberFormat = Intl.NumberFormat("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
        });

        const seconds = timeNumberFormat.format(timeRemaining % 60);
        const minutes = timeNumberFormat.format(Math.floor((timeRemaining / 60) % 60));
        const hours = timeNumberFormat.format(Math.floor((timeRemaining / 3600) % 24));
        const days = timeNumberFormat.format(Math.floor(timeRemaining / 86400));
        const flashSaleTimeRemainingPercentage = timeRemaining / totalDurationInSeconds;
        const remainingTimeDivWidth = (flashSaleTimeRemainingPercentage < 1 ? flashSaleTimeRemainingPercentage : 1) * (args.screenWidth - 32);

        return {
            seconds,
            minutes,
            hours,
            days,
            remainingTimeDivWidth
        };
    }

    return undefined;
}