import React from "react";
import { VideoPlayerWithController } from "../../../CommonComponents/UI/Cells/VideoPlayerWithController";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { getScreenSizeWithMaxWidth } from "../../../CommonComponents/Utils";
import { ProductDetailDescriptionFeedModel } from "../Models/ProductDetailResponse";

interface ProductDetailFeedSectionWidgetProps {
    models: ProductDetailDescriptionFeedModel[]
}

export default class ProductDetailFeedSectionWidget extends React.Component<ProductDetailFeedSectionWidgetProps> {
    render() {
        return <div id="ProductDetailFeedSectionContainer">
            {
                this.props.models.map(
                    (model, index) => <div style={{display:"flex", flexDirection: "column"}}>
                        {
                            model.videoUrl ?
                            <div style={{height:`${getScreenSizeWithMaxWidth().width}px`}}>
                                <VideoPlayerWithController 
                                    videoUrl={model.videoUrl} 
                                    videoThumbUrl={model.imageUrl}
                                    isAudioAvailable={model.isAudioAvailable}
                                />
                            </div> : 
                            model.imageUrl && <div>
                                <ImageWidget imageUrl={model.imageUrl} />
                            </div>
                        }
                        {
                            (model.subtitle || model.description) && <div style={{height:"12px"}}/>
                        }
                        {
                            model.subtitle &&
                            <p id="ProductDetailFeedSubtitle" style={{marginTop:"12px", marginBottom:"12px"}}>{model.subtitle}</p>
                        }
                        {
                            model.description &&
                            <div id="ProductDetailFeedDescription" style={{margin: "12px 12px"}} dangerouslySetInnerHTML={{__html: model.description.replaceAll("\n", "<br>")}} />
                        }
                        {
                            (model.subtitle || model.description) ? <div style={{height:"12px"}}/> : (index === (this.props.models.length - 1) && <div style={{height:"24px"}}/>)
                        }
                    </div>
                )
            }
        </ div>;
    }
}