import React from "react";
import ViewController from "../../CommonComponents/Base/ViewController";
import BottomSheetWidget from "../../CommonComponents/UI/BottomSheet/BottomSheetWidget";
import ButtonWidget from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ToReviewFormWidget from "./ToReviewForm/ToReviewFormWidget";
import ToReviewViewModel from "./ToReviewViewModel";
import "./ToReviewViewController.css";
import chevronLeftIcon from "../../Assets/Images/ic_chevron_left.svg";
import OrderToReviewWidget from "./OrderToReview/OrderToReviewWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import EmptyState from "../../OrderAndPayment/OrderAndDelivery/Widget/EmptyState";
import mixpanel from "mixpanel-browser";
import { isResellerDomain } from "../../CommonComponents/Utils";

export type ToReviewViewControllerProps = {};

class ToReviewViewController extends ViewController<{}, ToReviewViewModel> {
  constructor(props: ToReviewViewControllerProps) {
    super(props, new ToReviewViewModel());
    this.viewModel.loadToReviewList();

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "ToReview",
    });
  }

  renderHeader() {
    return (
      <div id="BrandListsViewControllerBrandListHeaderContainer">
        <button
          onClick={() => {
            window.history.back();
          }}
        >
          <img
            src={chevronLeftIcon}
            id="BrandListsViewControllerBrandListHeaderBackIcon"
            alt="back to previous screen"
          />
        </button>
        <p id="BrandListsViewControllerBrandListHeaderTitle">To Review</p>
      </div>
    );
  }

  render(): React.ReactNode {
    let shouldButtonBeDisabled = false;

    if (
      (this.viewModel.formState.rating || 0) < 3 &&
      this.viewModel.formState.checkedProblems?.length === 0
    ) {
      shouldButtonBeDisabled = true;
    }

    return (
      <>
        <Scaffold
          showSnackbar={this.viewModel.snackbar}
          showErrorModel={this.viewModel.error}
          isShowLoading={this.viewModel.isLoadingToReviewList}
          topBar={this.renderHeader()}
        >
          <div style={{ padding: 24 }}>
            {(this.viewModel.toReviewListResponse?.toReviews || [])?.length ===
              0 && <EmptyState />}
            {(this.viewModel.toReviewListResponse?.toReviews || [])?.length >
              0 &&
              this.viewModel.toReviewListResponse?.toReviews?.map((review) => {
                return (
                  <>
                    <OrderToReviewWidget
                      date={review.timeLabel}
                      orderId={review.orderId}
                      products={review.products?.map((p) => ({
                        productId: p.productId,
                        brandName: p.brand,
                        productName: p.name,
                        productPhotoUrl: p.imageUrl,
                        rating: p.rating,
                        productVariantName: p.attribute,
                      }))}
                      onRatingClick={(product, rating) => {
                        this.viewModel.setProduct({ ...product });
                        this.viewModel.setOrderId(review.orderId);
                        this.viewModel.updateFormState({ rating });
                        if (!product.rating) {
                          this.viewModel.openSheet();
                        }
                      }}
                    />
                    <SizedDiv height={16} />
                  </>
                );
              })}
          </div>
        </Scaffold>
        <BottomSheetWidget
          title="Write Review"
          onClose={() => {
            this.viewModel.closeSheet();
            this.viewModel.resetState();
          }}
          isOpen={this.viewModel.shouldOpenReviewSheet}
        >
          <ToReviewFormWidget
            rating={this.viewModel.formState.rating}
            review={this.viewModel.formState.review}
            variant={this.viewModel.selectedProduct?.productVariantName}
            checkedProblems={this.viewModel.formState.checkedProblems}
            imageUrls={this.viewModel.formState.imageUrls}
            productBrandName={this.viewModel.selectedProduct?.brandName}
            productName={this.viewModel.selectedProduct?.productName}
            productPhotoUrl={this.viewModel.selectedProduct?.productPhotoUrl}
            onStateChange={(state) => {
              this.viewModel.updateFormState(state);
            }}
          />
          <div className="ToReviewBottomSheetFooter">
            <ButtonWidget
              isEnabled={!shouldButtonBeDisabled}
              isLoading={this.viewModel.isSubmittingReview}
              action={() => {
                this.viewModel.closeSheet();
                this.viewModel.submitReview();
              }}
            >
              Submit
            </ButtonWidget>
          </div>
        </BottomSheetWidget>
      </>
    );
  }
}

export default ToReviewViewController;
