import "./StoreSectionFeed.css";

import React from "react";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";

interface StoreHeadlineImageSectionWidgetProps {
    imageUrl: string,
    screenWidth: number
}

export class StoreHeadlineImageSectionWidget extends React.Component<StoreHeadlineImageSectionWidgetProps> {
    render() {
        return <div id="StoreHeadlineImageSectionWidget">
            <ImageWidget imageUrl={this.props.imageUrl} />
        </div>;
    }
}