import React from "react";
import "./HypeButton.css";

export type HypeButtonProps = {
  label?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  renderLeftIcon?: () => React.ReactNode;
  isBlack?: boolean;
};

export default class HypeButton extends React.Component<HypeButtonProps> {
  render(): React.ReactNode {
    return (
      <button
        id={this.props.isDisabled ? "HypeButtonDisabled" : (this.props.isBlack ? "HypeButtonBlack" : "HypeButton")}
        onClick={() => {
          this.props.onClick?.();
        }}
      >
        {this.props.renderLeftIcon?.()}
        {this.props.label}
      </button>
    );
  }
}
