import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import BaseViewModel from "../../CommonComponents/Base/BaseViewModel";
import RegisterResponse from "./Models/RegisterResponse";

export default class RegisterVerificationViewModel extends BaseViewModel {
    verification_code: string = '';
    isLoadingButtonSubmit: boolean = false;
    errorMessage: string | null = null;
    isSuccess: boolean = false;
    isWait: boolean = true;
    timeRemainingCountingInSeconds: number = 60;

    onVerificationCodeChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})

        this.updateState({
            verification_code: val
        })
    }

    onSnackbarIsFinished() {
        this.updateState({errorMessage: null})
    }

    onSubmitVerification() {
        this.updateState({isLoadingButtonSubmit: true})
        requestAPI(Endpoints.registerVerification, {
            parameters: {
                input_email_telp: localStorage.input_email_telp,
                verification_code: this.verification_code,
                svc_id : '101'
            },
            onSuccess: (response: BaseResponse<RegisterResponse>) => {
                this.updateState({isSuccess: true});
            },
            onFailure: (error) => {
                if(error === "You already verification")
                {
                    this.updateState({isSuccess: true});
                }
                this.updateState({errorMessage: error});
            }
        });
    }

    onResendVerification() {
        this.updateState({isWait: true})
        requestAPI(Endpoints.resendVerification, {
            parameters: {
                input_email_telp: localStorage.input_email_telp,
                svc_id : '101'
            },
            onSuccess: (response: BaseResponse<RegisterResponse>) => {
                this.updateState({isWait: true});
            },
            onFailure: (error) => {
                this.updateState({errorMessage: error});
            }
        });
        this.updateState({timeRemainingCountingInSeconds: 60});
        this.handleTimer();
    }

    handleTimer() {
        setTimeout(() => {
            if (this.timeRemainingCountingInSeconds > 0) {
                this.updateState({timeRemainingCountingInSeconds: this.timeRemainingCountingInSeconds - 1});
                this.handleTimer();
            }
            else
            {
                this.updateState({isWait: false});
            }
        }, 1000);
    }

    override copy({
        verification_code = this.verification_code,
        isLoadingButtonSubmit = this.isLoadingButtonSubmit,
        errorMessage = this.errorMessage,
        isSuccess = this.isSuccess,
        isWait = this.isWait,
        timeRemainingCountingInSeconds = this.timeRemainingCountingInSeconds,
    }): this {
        this.verification_code = verification_code;
        this.isLoadingButtonSubmit = isLoadingButtonSubmit;
        this.errorMessage = errorMessage;
        this.isSuccess = isSuccess;
        this.isWait = isWait;
        this.timeRemainingCountingInSeconds = timeRemainingCountingInSeconds;

        return this;
    }
}