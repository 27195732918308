import React from "react";

import "./CreatorSpace.css";

import ic_no_product from "../../../Assets/Images/ic_no_product.svg";
import ProductSummaryItemCellWidget from "../../../CommonComponents/UI/Cells/ProductSummaryItemCellWidget";
import ProductSummaryItemModel from "../../../CommonComponents/Models/ProductSummaryItemModel";

type CreatorSpaceProductListWidgetProps = {
    sellerId?: string,
    products: ProductSummaryItemModel[] | null,
    width: number
}

export default class CreatorSpaceProductListWidget extends React.Component<CreatorSpaceProductListWidgetProps, {}> {
    render() {
        return(
            <>
                {
                    (this.props.products?.length ?? 0) > 0 ?
                    <div id="SpaceCategoryProductsContainer">
                        {
                            this.props.products?.map((model, _) => {
                                return (
                                    <ProductSummaryItemCellWidget 
                                        sellerId={this.props.sellerId} 
                                        model={model} 
                                        cellWidth={(this.props.width - 20) / 2}
                                    />
                                );
                            })
                        }
                    </div> :
                    <div id="SpaceCategoryNoItemContainer">
                        <img src={ic_no_product} alt="" />
                        <p>There are no styling item yet.</p>
                    </div>
                }
            </>
        );
    }
}