import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import CouponResponse from "./Models/CouponResponse";
import { getPreloadProps } from "../../CommonComponents/Utils";
import {
  ShowErrorModel,
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { eventManager } from "../../Analytics/Events/Common";
import CouponDetailResponse from "./Models/CuponDetailResponse";

export default class CouponViewModel extends ViewModel {
  couponData: CouponResponse | null = null;
  isLoading: boolean = true;
  isLoadingButtonSubmit: boolean = false;
  errorModel: ShowErrorModel | null = null;
  errorSnackBarModel: ShowSnackbarModel | null = null;
  errorMessage: string | null = null;
  navBarTransparency: number = 1;
  numberInCart: number = 0;
  snackbarMessage: ShowSnackbarModel | null = null;
  enteredCouponCode: string = "";
  couponDetailsData: CouponDetailResponse[] = [];
  couponDetailClickedIndex: number = 0;
  shouldShowCouponDetailSheet: boolean = false;

  claimCoupon() {
    this.emit(() => {
      this.isLoadingButtonSubmit = true;
    });
    requestAPI(Endpoints.claimCoupon, {
      onFailure: (error) => {
        this.emit(() => {
          this.isLoadingButtonSubmit = false;
          this.snackbarMessage = new ShowSnackbarModel({
            message: error,
            type: "error",
            inset: {
              bottom: 52,
            },
          });
        });
      },
      onSuccess: () => {
        this.useCouponCode(this.enteredCouponCode);
        this.apply();
        this.emit(() => {
          this.isLoadingButtonSubmit = false;
        });
      },
      parameters: {
        couponCode: this.enteredCouponCode,
      },
    });
  }

  handleEnteredCouponCodeChange(code?: string) {
    this.emit(() => {
      this.enteredCouponCode = code || "";
    });
  }

  loadPreloadingProps() {
    this.isLoading = true;
    this.emit(() => {});

    getPreloadProps({
      onSuccess: (_, __, numberInCart) => {
        this.numberInCart = numberInCart;
        this.emit(() => {});

        this.loadCoupon();
      },
      onFailure: (error) => {
        this.isLoading = false;
        this.errorModel = {
          message: error,
          onRetryButtonTapped: () => this.loadPreloadingProps(),
        };

        this.emit(() => {});
      },
    });
  }

  loadCoupon() {
    this.isLoading = true;
    this.emit(() => {});

    requestAPI(Endpoints.coupons, {
      parameters: {
        fpAuth: localStorage.fpAuth,
      },
      onSuccess: (response: BaseResponse<CouponResponse>) => {
        this.isLoading = false;
        this.couponData = response.data;
        this.emit(() => {});
        this.loadCouponDetails();
      },
      onFailure: (error: string) => {
        this.isLoading = false;
        this.errorModel = {
          message: error,
          onRetryButtonTapped: () => this.loadPreloadingProps(),
        };

        this.emit(() => {});
      },
    });
  }

  loadCouponDetails() {
    let couponDetailsTemp: CouponDetailResponse[] = new Array(
      this.couponData?.coupons.length
    );
    this.isLoading = true;
    this.emit(() => {});

    if (this.couponData?.coupons)
      for (let i = 0; i < this.couponData.coupons.length; i++) {
        requestAPI(Endpoints.couponDetail, {
          parameters: {
            fpAuth: localStorage.fpAuth,
            couponId: this.couponData.coupons[i].id,
          },
          onSuccess: (response: BaseResponse<CouponDetailResponse>) => {
            couponDetailsTemp[i] = response.data;
            this.emit(() => {});
            // console.log("coupon",coupon.id,"request result",response.data)
          },
          onFailure: (error: string) => {
            this.isLoading = false;
            this.errorModel = {
              message: error,
              onRetryButtonTapped: () => this.loadPreloadingProps(),
            };

            this.emit(() => {});
          },
        });
      }
    this.couponDetailsData = couponDetailsTemp;
    this.emit(() => {});
    //console.log("coupondetails", this.couponDetailsData)
    this.isLoading = false;
  }

  handleUseCoupon = (couponId: string, couponName: string) => {
    localStorage.setItem("use_coupon", "1");
    localStorage.setItem("couponId", couponId);
    eventManager.add({
      coupon: {
        id: couponId,
        name: couponName,
      },
    });
    localStorage.setItem("couponName", couponName);
  };

  toggleSheet() {
    this.emit(() => {
      if (this.shouldShowCouponDetailSheet) {
        // @ts-ignore
        this.selectedMapAttributes = {};
      }
      this.shouldShowCouponDetailSheet = !this.shouldShowCouponDetailSheet;
    });
  }

  apply() {
    if (localStorage.point !== undefined) {
      localStorage.removeItem("point");
    }
    window.location.href = "/Checkout";
  }

  useCouponCode(code?: string) {
    if (code) {
      localStorage.setItem("use_coupon", "1");
      localStorage.setItem("couponCode", code);
    }
  }

  handleShowCouponDetail = (index: number) => {
    this.couponDetailClickedIndex = index;
    this.toggleSheet();
  };

  onSnackbarIsFinished() {
    this.errorMessage = null;
    this.emit(() => {});
  }
}
