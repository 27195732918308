import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import BaseViewModel from "../../CommonComponents/Base/BaseViewModel"
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import StoreBrandHomeResponse, { BrandItemModel } from "./Models/StoreBrandHomeResponse";

type CategorizedBrands = {[key: string]: BrandItemModel[]};

export default class StoreBrandHomeViewModel extends BaseViewModel {
    isLoading: boolean = true;
    errorMessage: ShowErrorModel | null = null;
    errorSnackbar: ShowSnackbarModel | null = null;
    categorizedBrands?: CategorizedBrands;
    filteredCategorizedBrands?: CategorizedBrands;
    query: string = "";

    loadBrands() {
        this.isLoading = true;

        this.updateState({});

        requestAPI(Endpoints.brandHome, {
            onSuccess: (response: BaseResponse<StoreBrandHomeResponse>) => {
                this.isLoading = false;
                this.categorizedBrands = this.categorizeBrands(response.data.brands);
                this.filteredCategorizedBrands = this.categorizedBrands;

                this.updateState({});
            },
            onFailure: (error: string) => {
                this.isLoading = false;
                this.errorMessage = {
                    message: error,
                    onRetryButtonTapped: () => this.loadBrands()
                };

                this.updateState({});
            }
        });
    }

    changeQuery(value: string) {
        this.query = value;

        if (this.query.length > 0) {
             if (this.categorizedBrands) {
                const categorizedBrands = this.categorizedBrands;
                
                let result: CategorizedBrands = {};

                const keys = Object.keys(categorizedBrands);

                keys.forEach((key) => {
                    const modelsInKey: BrandItemModel[] = [];

                    categorizedBrands[key].forEach((model) => {
                        if (model.name.toLowerCase().includes(value.toLowerCase())) {
                            modelsInKey.push(model);
                        }
                    });

                    if (modelsInKey.length > 0) {
                        result[key] = modelsInKey;
                    }
                });

                this.filteredCategorizedBrands = result;
            }
        } else {
            this.filteredCategorizedBrands = this.categorizedBrands;
        }

        this.updateState({});
    }

    private categorizeBrands(brands: BrandItemModel[]): CategorizedBrands {
        var result: CategorizedBrands = {};

        brands.forEach((model) => {
            if (this.isStartWithSpecialCharOrNumber(model.name)) {
                let listOfBrands = Object.keys(result).includes("#") ? result["#"] : [];
                
                listOfBrands.push(model);

                result["#"] = listOfBrands;
            } else {
                const startLetter = (model.name.length > 0 ? model.name[0] : "").toUpperCase();

                let listOfBrands = Object.keys(result).includes(startLetter) ? result[startLetter] : [];
                
                listOfBrands.push(model);

                result[startLetter] = listOfBrands;
            }
        });

        return result;
    }

    isStartWithSpecialCharOrNumber(string: string) {
    const startWithSpecialCharRegex = /^[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/;
    const startWithNumberRegex = /^\d/;

    return startWithSpecialCharRegex.test(string) || startWithNumberRegex.test(string);
}

    copy<T extends {}>(args: T): this {
        return this;
    }
}