import React from "react";
import { LoadingView } from "../LoadingWidget/LoadingView";
import "./ImageWidget.css";

type LoadingViewTheme = "dark" | "light";

type ImageWidgetProps = {
    placeholder?: string;
    imageUrl?: string;
    loadingViewTheme?: LoadingViewTheme;
    objectFit?: 'cover' | 'fill';
};

type ImageWidgetState = {
    isLoading: boolean;
    isImageAvailable: boolean;
};

export class ImageWidget extends React.Component<ImageWidgetProps, ImageWidgetState> {
    constructor(props: ImageWidgetProps) {
        super(props);

        this.state = {
            isLoading: this.props.imageUrl?.length !== 0 ?? false,
            isImageAvailable: this.props.imageUrl?.length !== 0 ?? false,
        };
    }

    render() {
        return (
            <div id="ImageWidgetContainer">
                {
                    this.state.isLoading && <>
                        {
                            this.props.loadingViewTheme === "dark" ? 
                            <LoadingView theme="dark"/> :
                            <div className="ShimmerAnimation" style={{ height: "inherit", width: "inherit" }} />
                        }
                    </>
                }
                <img
                    id="ImageWidgetAssset"
                    alt="Asset"
                    src={!this.state.isImageAvailable && this.props.placeholder ? this.props.placeholder : this.props.imageUrl}
                    onLoad={_ => {
                        this.hideLoading();
                    }}
                    onError={_ => {
                        this.hideLoading();
                        this.switchImageToPlaceholder();
                    }}
                    style={{
                      objectFit: this.props.objectFit ?? "cover"
                    }}
                    onContextMenu={() => false}
                />
            </div>
        );
    }

    hideLoading() {
        this.setState({ isLoading: false });
    }

    switchImageToPlaceholder() {
        this.setState({ isImageAvailable: false });
    }
}
