import "./PopUpWidget.css";

import icX from "../../../Assets/Images/ic_x_circle_white_filled.svg";

import React from "react";
import ButtonWidget, { ButtonWidgetType } from "../ButtonWidget/ButtonWidget";
import { getScreenSizeWithMaxWidth } from "../../Utils";
import PopUpWidgetModel from "./Models/PopUpWidgetModel";

// Props, Models, and State

interface PopUpWidgetProps {
    model: PopUpWidgetModel,
    onPopUpClosed: () => void
}

interface PopUpWidgetState {
    isShowing: boolean;
}

// Widget

export default class PopUpWidget extends React.Component<PopUpWidgetProps, PopUpWidgetState> {
    constructor(props: PopUpWidgetProps) {
        super(props);

        this.state = {
            isShowing: true
        }
    }

    render() {
        const isDesctructiveButtonAvailable = this.props.model.desctructiveButtonTitle;
        
        if (this.state.isShowing) {
            return(
                <div id="PopUpBackground" key={this.props.model.key} onClick={() => this.props.onPopUpClosed() }>
                    <div id="PopUpContainer" key={this.props.model.key} onClick={(event) => event.stopPropagation()}>
                        {
                            this.props.model.imageUrl && 
                            <>
                                <img src={this.props.model.imageUrl} alt="" style={{width: "100%", position: "relative"}} />
                                <button style={{ position: "absolute", top: 16, right: 16 }} onClick={() => {
                                    this.props.onPopUpClosed();
                                }}>
                                    <img src={icX} alt="close" />
                                </button>
                            </>
                        }
                        <p id="PopUpTitle">{this.props.model.title}</p>
                        <p id="PopUpDescription">{this.props.model.description}</p>
                        <div id="PopUpCtaContainer">
                            {
                                isDesctructiveButtonAvailable &&
                                <>
                                    <div style={{flexGrow: 1}}>
                                        <ButtonWidget 
                                            buttonType={ButtonWidgetType.secondary} 
                                            action={() => {
                                                if (this.props.model.desctructiveButtonAction) { this.props.model.desctructiveButtonAction() }
                                                if (this.props.onPopUpClosed) { this.props.onPopUpClosed() }
                                                this.setState({isShowing: false});
                                            }} 
                                            width="calc(100% - 6px)"
                                        >
                                            {this.props.model.desctructiveButtonTitle}
                                        </ButtonWidget>
                                    </div>
                                    <div style={{width:"12px"}} />
                                </>
                            }
                            <div style={{flexGrow: 1}}>
                                <ButtonWidget 
                                    action={() => {
                                        if (this.props.onPopUpClosed) { this.props.onPopUpClosed() }
                                        this.props.model.mainButtonAction();
                                        this.setState({isShowing: false});
                                    }} 
                                    width={isDesctructiveButtonAvailable ? "calc(100% - 6px)" : "100%"}
                                >
                                    {this.props.model.mainButtonTitle}
                                </ButtonWidget>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <></>;
    }
}