import React from "react";

import "./OrderChunkWidget.css";
import OrderType from "../../Models/OrderType";
import { convertToRupiah } from "../../../../CommonComponents/Utils";
import SizedDiv from "../../../../CommonComponents/UI/SizedDiv";
import AlertWidget from "../../../../CommonComponents/UI/Alert/AlertWidget";

export type OrderChunkWidgetProps = {
  title?: string;
  info?: string;
  sections: {
    type?: OrderType;
    status?: string;
    details?: string;
    onMoreDetailsClick?: () => void;
    products?: {
      badgeImageUrl?: string;
      imageUrl?: string;
      brand?: string;
      name?: string;
      variant?: string;
      price?: number;
      weight?: string;
      isReordering?: boolean;
    }[];
  }[];
};

export default class OrderChunkWidget extends React.Component<OrderChunkWidgetProps> {
  getOrderStatusColorClassname(type?: OrderType): string {
    if (!type) return "";
    switch (type) {
      case OrderType.CANCELLED:
        return "OrderChunkWidgetStatusTypeColorsDanger";
      case OrderType.COMPLETED:
        return "OrderChunkWidgetStatusTypeColorsNormal";
      default:
        return "OrderChunkWidgetStatusTypeColorsWarning";
    }
  }

  renderStatus() {}

  render(): React.ReactNode {
    const { sections = [], title, info } = this.props;

    return (
      <div className="OrderChunkWidgetContainer">
        <div className="OrderChunkWidgetTitle">{title}</div>
        {info && (
          <div style={{ paddingRight: 24, paddingLeft: 24 }}>
            <AlertWidget text={info} type="normal" />
          </div>
        )}

        {sections.map((s) => (
          <>
            <div className="OrderChunkWidgetContent">
              {s.products?.map((p) => (
                <div className="OrderChunkWidgetItemContainer">
                  <div className="OrderChunkWidgetItemImageContainer">
                    <img
                      className="OrderChunkImageItemImage"
                      src={p.imageUrl}
                      alt={`${p.brand} - ${p.name}`}
                    ></img>
                  </div>
                  <div className="OrderChunkWidgetItemDetails">
                    <div className="OrderChunkWidgetItemBadgeContainer">
                      <img
                        className="OrderChunkWidgetItemBadgeImage"
                        src={p.badgeImageUrl}
                        alt=""
                      />
                    </div>
                    <p className="OrderChunkWidgetItemBrand">{p.brand}</p>
                    <p className="OrderChunkWidgetItemName">{p.name}</p>
                    <p className="OrderChunkWidgetItemVariant">{p.variant}</p>
                    <p className="OrderChunkWidgetItemWeight">{p.weight}</p>
                    <p className="OrderChunkWidgetItemPrice">
                      {convertToRupiah(p.price)}
                    </p>
                  </div>
                </div>
              ))}
              <div className="OrderChunkWidgetFooter">
                {s.status && (
                  <div
                    className={this.getOrderStatusColorClassname(s.type)}
                    style={{ display: "flex" }}
                  >
                    <div className="OrderChunkWidgetStatus">{s.status}</div>
                  </div>
                )}
                <SizedDiv height={4} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className={this.getOrderStatusColorClassname(s.type)}
                    style={{ display: "flex" }}
                  >
                    <div className="OrderChunkWidgetDetails">{s.details}</div>
                  </div>
                  <button
                    style={{ width: 24 }}
                    onClick={() => {
                      s.onMoreDetailsClick?.();
                    }}
                  >
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L7 7L1 13"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="OrderChunkWidgetDivider" />
          </>
        ))}
      </div>
    );
  }
}
