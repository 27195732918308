import React from "react";

import "./Button.css";

type NavigationButtonProps = {
    src: string,
    onClick?: () => void,
    numberOnRightTop?: number,
    paddingLeft?: number,
}

export default class NavigationButton extends React.Component<NavigationButtonProps, {}> {
    render() {
        return(
            <button className="ContentExplorerMainNavigationCTA" style={{paddingLeft:`${this.props.paddingLeft ?? 12}px`}} onClick={this.props.onClick}>
                <img alt="assets" src={this.props.src} />
                {
                    (this.props.numberOnRightTop ?? 0) > 0 && <div>{this.props.numberOnRightTop}</div>
                }
            </button>
        );
    }
}