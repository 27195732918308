import React from "react";

export type ProgressBarProps = {
  progress?: number;
  max?: number;
};

export default class ProgressBar extends React.Component<ProgressBarProps> {
  render(): React.ReactNode {
    const { max = 1, progress = 1 } = this.props;

    return (
      <div
        style={{
          position: "relative",
          backgroundColor: "#E4E4E4",
          height: 2,
        }}
      >
        <div
          style={{
            backgroundColor: "#626262",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: `${100 - ((progress / max) * 100)}%`,
          }}
        ></div>
      </div>
    );
  }
}
