import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { IndexIndicator } from "../../../CommonComponents/UI/IndexIndicator/IndexIndicatorWidget";
import { getLocationQueries } from "../../../CommonComponents/Utils";
import ShowCaseDetailCoverWidget from "../../Detail/Widgets/ShowCaseDetailCoverWidget";
import { ShowCaseHomeCoverModel } from "../Models/ShowCaseHomeCoverModel";

interface Props {
    model: ShowCaseHomeCoverModel
}

interface State {
    activeIndex: number
}

export class ShowCaseHomeCoverWidget extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            activeIndex: 0
        }
    }

    render() {
        const model = this.props.model
        const carouselItems = model.covers
        const topMargin = getLocationQueries()["topSafeAreaMargin"] ?? "";

        return <div id="ShowCaseDetailCarouselSection">
            <Swiper 
                slidesPerView={1}
                onSlideChangeTransitionEnd={
                    (swiper) => this.setState({
                        activeIndex: swiper.activeIndex
                    })
                }
            >
                {
                    carouselItems.map(
                        (carouselItem, index) => {
                            return <SwiperSlide key={`ShowCaseHomeCoverWidget${carouselItem.assetUrl}`}>
                                <ShowCaseDetailCoverWidget 
                                    model={carouselItem} 
                                    onClick={
                                        () => {
                                            let directionUrl = `/ShowCase/Detail?id=${carouselItem.id}`;

                                            directionUrl += `${topMargin.length > 0 ? `&topSafeAreaMargin=${topMargin}` : ""}`;
                                            directionUrl += `${carouselItem.contentTheme ? `&theme=${carouselItem.contentTheme}` : ""}`;
                                            directionUrl += `${carouselItem.navBarIconColor ? `&navBarIconColor=${carouselItem.navBarIconColor}` : ""}`;
                                            directionUrl += `${carouselItem.navBarTitle ? `&title=${(carouselItem.navBarTitle ?? "").replace(" ", "%20")}` : ""}`;

                                            window.location.href = directionUrl;
                                        }
                                    } 
                                    isTopGradientInCoverShown={true}
                                    isBottomGradientInCoverShown={true}
                                    navBarBackgroundHex="#000000"
                                    isActive={index === this.state.activeIndex}
                                />
                            </SwiperSlide>;
                        }
                    )
                }
            </Swiper>
            
            <div>
                <IndexIndicator totalOfIndices={carouselItems.length} showingIndex={this.state.activeIndex} />
            </div>
        </div>
    }
}