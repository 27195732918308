import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import BaseViewModel from "../../CommonComponents/Base/BaseViewModel";
import RegisterResponse from "./Models/RegisterResponse";

export default class RegisterProfileViewModel extends BaseViewModel {
    username: string = '';
    phone_number: string = '';
    dob: string = '';
    isLoadingButtonSubmit: boolean = false;
    errorMessage: string | null = null;
    isSuccess: boolean = false;
    errorMessageUsername: string = '';
    errorMessagePhoneNumber: string = '';
    errorMessageDOB: string = '';

    onSnackbarIsFinished() {
        this.updateState({errorMessage: null})
    }
    
    onUsernameChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        this.updateState({errorMessageUsername: null})        
        
        this.updateState({
            username: val
        })
    }

    onPhoneNumberChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        this.updateState({errorMessagePhoneNumber: null})        
        
        this.updateState({
            phone_number: val
        })
    }

    onDOBChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        this.updateState({errorMessageDOB: null})        
        
        this.updateState({
            dob: val
        })
    }

    onSubmitProfile() {
        this.updateState({isLoadingButtonSubmit: true})
        requestAPI(Endpoints.registerProfile, {
            parameters: {
                input_email_telp: localStorage.input_email_telp,
                full_name: this.username,
                phone_number: this.phone_number,
                dob: this.dob,
                svc_id : '101'
            },
            onSuccess: (response: BaseResponse<RegisterResponse>) => {
                this.updateState({isSuccess: true});
            },
            onFailure: (error) => {
                this.updateState({errorMessage: error});
            }
        });
    }

    override copy({
        username = this.username,
        phone_number = this.phone_number,
        dob = this.dob,
        isLoadingButtonSubmit = this.isLoadingButtonSubmit,
        errorMessage = this.errorMessage,
        errorMessageUsername = this.errorMessageUsername,
        errorMessagePhoneNumber = this.errorMessagePhoneNumber,
        errorMessageDOB = this.errorMessageDOB,
        isSuccess = this.isSuccess,
    }): this {
        this.username = username;
        this.phone_number = phone_number;
        this.dob = dob;
        this.isLoadingButtonSubmit = isLoadingButtonSubmit;
        this.errorMessage = errorMessage;
        this.errorMessageUsername = errorMessageUsername;
        this.errorMessagePhoneNumber = errorMessagePhoneNumber;
        this.errorMessageDOB = errorMessageDOB;
        this.isSuccess = isSuccess;

        return this;
    }
}