import React, { HTMLInputTypeAttribute } from "react";

type ButtonWidgetProps = {
    inputType?: HTMLInputTypeAttribute,
    title?: string,
    isEnabled?: boolean,
    errorMessage?: string,
    width?: string | number,
    placeholder?: string,
    maxLength?: number,
    value?: string,
    isRequired?: boolean,
    variant?: "underline" | "outlined",
    name?: string,
    autoComplete?: "on" | "off",
    action?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default class InputFieldWidget extends React.Component<ButtonWidgetProps, {}> {
    render() {
        const isDisable = this.props.isEnabled === false;
        return (
            <>
                {this.props.title &&
                <label 
                  className="form-label" 
                  style={this.props.variant === "outlined" ? {color: "black", fontWeight: "500"} : {}}
                >
                  {this.props.title}
                  <span style={{color:"red"}}>
                    {this.props.isRequired && "*"}
                  </span>
                </label>}
                <input 
                    className={this.props.variant !== "outlined" ? "InputWidgetNoBorder" : "InputWidget"}
                    type={this.props.inputType} 
                    maxLength= {this.props.maxLength}
                    onChange={isDisable ? undefined : this.props.action} 
                    placeholder={this.props.placeholder}
                    autoComplete={this.props.autoComplete || "off"}
                    value={this.props.value}
                    name={this.props.name}
                >
                </input>
                <div className="divError">
                    {
                    this.props.errorMessage !== "" && this.props.errorMessage !== null &&
                        <span className="errormsg"><img alt="ic-wrong" width="10" src={global.ImgUrl+'shopable/ic-wrong@3x.png'}></img> &nbsp;&nbsp; {this.props.errorMessage}</span>
                    }
                </div>
            </>
        );
    }
}