import React from "react";
import CartResponse from "../Models/CartResponse";
import BillingResponse from "../Models/BillingResponse";
import { convertToRupiah } from "../../../CommonComponents/Utils";

type CartPriceWidgetProps = {
    cartData: CartResponse | null,
    billingData: BillingResponse | null
};

export default class CartPriceWidget extends React.Component<CartPriceWidgetProps> {
   render() {
        const billingData = this.props.billingData;

        return(
            <div className="row fixedCart">
                {
                billingData?.billingItems.map((items,index) => (
                    <div className="row">
                        <div className='col-6'>
                            <p className="font16"><b>TOTAL</b></p>
                        </div>
                        <div className='col-6 text-end'>
                            <p className="font16"><b>{convertToRupiah(billingData?.total)}</b></p>
                        </div>
                    </div>
                ))}
                <div className='col-6'>
                    <p className="font16"><b>TOTAL</b></p>
                </div>
                <div className='col-6 text-end'>
                    <p className="font16">
                        <b>{convertToRupiah(billingData?.total)}</b>
                    </p>
                </div>
                <br></br><br></br><br></br>
            </div>
        );
    }
}