import React from "react"

import "./ContentDisplay.css";

import ic_error_dark from "../../../Assets/Images/ic_error_dark_pink.svg";

type ContentExplorerNoItemPageProps = {
    isFollowAnyCreator: boolean
}

export default class ContentExplorerNoItemPage extends React.Component<ContentExplorerNoItemPageProps, {}> {
    render() {
        return (
            <div className="ContentExplorerNoItemBackground">
                <img src={ic_error_dark} alt="" />
                <p>Creator You Follow</p>
                <p>{this.props.isFollowAnyCreator === false ? "Once You follow creator, You'll see them here." : "Still there's no content from Creator that you follow."}</p>
            </div>
        );
    }
}