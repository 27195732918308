import React from "react";
import { convertToRupiah } from "../../../../CommonComponents/Utils";
import { Optional } from "../../../../CommonTypes";
import "./CheckoutCourierWidget.css";
import icChevronRight from "../../../../Assets/Images/ic_right_chevron_black.svg";

type CheckoutCourierWidgetProps = {
  courierImageUrl?: string;
  price?: number;
  name?: string;
  shippingAddressId?: Optional<string>;
  onClick?: () => void;
};

const EmptyCourier = (props: CheckoutCourierWidgetProps) => {
  return (
    <div
      className="container"
      id="CheckoutCourierWidget"
      role="button"
      onClick={props.onClick}
    >
      <div className="details empty">
        <div
          className="buttonContent"
          style={{
            color: props.shippingAddressId ? "inherit" : "#E4E4E4",
          }}
        >
          Select courier
        </div>
      </div>
      <div className="rightIcon">
        <svg
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 4L13 10L7 16"
            stroke={props.shippingAddressId ? "#000" : "#E4E4E4"}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default class CheckoutCourierWidget extends React.Component<CheckoutCourierWidgetProps> {
  render() {
    const {
      price,
      courierImageUrl,
      name = "",
      onClick,
      shippingAddressId,
    } = this.props;

    const shouldShowEmptyState = !name;

    if (shouldShowEmptyState)
      return (
        <EmptyCourier onClick={onClick} shippingAddressId={shippingAddressId} />
      );

    return (
      <div
        className="container"
        id="CheckoutCourierWidget"
        role="button"
        onClick={onClick}
      >
        <div className="details">
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <div className="logoContainer">
              <img alt="" src={courierImageUrl} className="logo" />
            </div>
            <p className="name">{name}</p>
          </div>

          <div>
            <p className="price">{convertToRupiah(price)}</p>
          </div>
        </div>
        <div className="rightIcon">
          <img alt="" src={icChevronRight} />
        </div>
      </div>
    );
  }
}
