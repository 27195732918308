import React from "react";
import { ImageWidget } from "../../CommonComponents/UI/ImageWidget/ImageWidget";
import "./UniqueRequestWidget.css";
import { getFepiCSNumber } from "../../CommonComponents/Utils";

export type UniqueRequestWidgetProps = {
  onRequestClick?: (url?: string) => void;
};

export type UniqueRequestWidgetState = {
  url: string;
};

export default class UniqueRequestWidget extends React.Component<
  UniqueRequestWidgetProps,
  UniqueRequestWidgetState
> {
  constructor(props: UniqueRequestWidgetProps) {
    super(props);
    this.state = {
      url: "",
    };
  }

  render(): React.ReactNode {
    return (
      <div className="UniqueRequestWidgetContainer">
        <div className="UniqueRequestWidgetInputContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 8,
              marginRight: 8,
              flex: 1,
            }}
          >
            <input
              className="UniqueRequestWidgetInputField"
              onChange={(e) => {
                this.setState({
                  url: e.target.value,
                });
              }}
            ></input>
          </div>
          <button
            className="UniqueRequestWidgetInputButton"
            onClick={() => {
              this.props.onRequestClick?.(this.state.url);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 7.00001L8 1.99999L3 7"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <line
                x1="8"
                y1="2.5"
                x2="8"
                y2="13.5"
                stroke="white"
                stroke-linecap="round"
              />
            </svg>
          </button>
        </div>
        <div style={{ marginTop: 12, marginBottom: 8 }}>
          <div className="UniqueRequestWidgetDescription">
            atau hubungi tim CS The Fepi
          </div>
        </div>
        <div style={{ display: "flex", gap: 12, justifyContent: "center" }}>
          <div className="UniqueRequestWidgetSocialButton">
            <a
              href="https://www.instagram.com/thefepi_/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                width="48"
                src="https://images.thefepi.com/file/shopable/C93A8EB3-F0D6-4439-983A-F9BEDE272655.png"
                alt="fb icon"
              />
            </a>
          </div>
          <div className="UniqueRequestWidgetSocialButton">
            <a href="https://m.me/THEFEPI" target="_blank" rel="noreferrer">
              <img
                width="48"
                src="https://images.thefepi.com/file/shopable/98A10C49-D98C-424E-B363-2318BBED24D9.svg"
                alt="fb icon"
              />
            </a>
          </div>
          <div className="UniqueRequestWidgetSocialButton">
            <a target="_blank" href="/Jastip" rel="noreferrer">
              <img
                width="48"
                src="https://images.thefepi.com/file/shopable/9937B910-0DF2-452B-A6DE-F5C47529F3CD.png"
                alt="io"
              />
            </a>
          </div>
          <div className="UniqueRequestWidgetSocialButton">
            <a
              href={`https://wa.me/${getFepiCSNumber()}?text=Hello%20TheFepi`}
              target="_blank" rel="noreferrer"
            >
              <img
                width="48"
                src="https://images.thefepi.com/file/shopable/A10F5243-E293-4210-A3D2-1B61C1361F21.svg"
                alt="wa"
              />
            </a>
          </div>
        </div>
        <div style={{ marginTop: 12, marginBottom: 8 }}>
          <div className="UniqueRequestWidgetDescription">
            *pesananmu akan sampai dalam 7-10 hari kerja
          </div>
        </div>
      </div>
    );
  }
}
