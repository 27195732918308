import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
import Scaffold, {
  ShowErrorModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";
import ic_cart from "../../Assets/Images/ic_cart_dark.svg";
import ic_search from "../../Assets/Images/ic_search_dark.svg";
import { useEffect, useReducer, useRef, useState } from "react";
import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import {
  ProductSummaryItemModel,
  ProductsV3Response,
} from "./ProductGroupCategorySubCategoryProductListResponse";
import StoreTopTabbarWidget from "../CommonComponents/Widgets/StoreTopTabbarWidget";
import { mixpanelManager } from "../../Analytics/Mixpanel/Common";
import ic_burger_menu from "../../Assets/Images/ic_burger_menu.svg";
import AllCategoryBottomSheetController from "../AllCategoryBottomSheet/AllCategoryBottomSheetController";
import { AllCategoryResponse } from "../MainHome/Models/AllCategoryResponse";
import CuratedProductsSortViewWidget from "../CuratedProducts/Widgets/CuratedProductsSortViewWidget";
import ic_sort from "../../Assets/Images/ic_arrow_sort.svg";
import ProductSummaryItemCellWidget from "../../CommonComponents/UI/Cells/ProductSummaryItemCellWidget";
import { getScreenSizeWithMaxWidth } from "../../CommonComponents/Utils";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import { LoadingWidget } from "../../CommonComponents/UI/LoadingWidget/LoadingWidget";
import { ProductBadgeType } from "../../CommonTypes";

export default function ProductGroupCategorySubCategoryProductList() {
  const [dataAllCategory, setIsDataAllCategory] =
    useState<AllCategoryResponse | null>(null);
  const [shouldShowSortModal, setShouldShowSortModal] = useState(false);
  const [group, setGroup] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  // Use the useState hook to manage the selectedSortId state
  const sortId = "1"; // Replace this with the actual value or state
  const [selectedSortId, setSelectedSortId] = useState<string | null>(
    sortId || "1"
  );
  const [error, setError] = useState<ShowErrorModel | null>(null);
  const [productsData, setproductsData] = useState<ProductSummaryItemModel[]>(
    []
  );
  const [selectedGenders, setSelectedGender] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisibleBottomSheetAllCategory, setIsVisibleBottomSheetAllCategory] =
    useState(false);
  const [width, setWidth] = useState(0);
  const [offset, setOffset] = useState(0);

  const initialBadge:
    | { type: string; url: string }[]
    | (() => { type: string; url: string }[]) = [];

  const initialSorts:
    | { id: string; label: string }[]
    | (() => { id: string; label: string }[]) = [];

  // Use the useState hook to manage the sorts state
  const [subCategoryDetails, setSubCategoryDetails] =
    useState<{ id: string; label: string }[]>(initialSorts);

  const [categoryFilters, setCategoryFilters] =
    useState<{ id: string; label: string }[]>(initialSorts);

  // Use the useState hook to manage the sorts state
  const [sorts, setSorts] =
    useState<{ id: string; label: string }[]>(initialSorts);

  const [badges, setBadges] =
    useState<{ type: string; url: string }[]>(initialBadge);

  const [totalOfItems, setTotalOfItems] = useState(0);
  const [totalLengtOfItem, otalLengtOfItem] = useState(0);

  useEffect(() => {
    // Get the current URL
    const currentURL = window.location.href;
    // Split the URL into segments using '/'
    const urlSegments = currentURL
      .split("/")
      .map((segment) => segment.toLowerCase());
    // Find the index of the "store" segment (case-insensitive)
    const storeIndex = urlSegments.indexOf("store");
    // If "store" is found, get the subsequent two segments
    if (storeIndex !== -1 && storeIndex + 2 < urlSegments.length) {
      const groupValue = urlSegments[storeIndex + 2];
      const categoryValue = urlSegments[storeIndex + 3];
      const subCategoryValue = urlSegments[storeIndex + 4];
      setGroup(groupValue);
      setCategory(categoryValue);
      setSubCategory(subCategoryValue);
    }
  }, []);

  const prevSubCategory = useRef<string | null>(null);
  const prevSelectedGenders = useRef<string[]>([]);
  const prevSelectedSortId = useRef<string | null>(null);

  useEffect(() => {
    // Check if subCategory, selectedGenders, or selectedSortId has changed
    const shouldResetProductsData =
      prevSubCategory.current !== subCategory ||
      !arraysEqual(prevSelectedGenders.current, selectedGenders) ||
      prevSelectedSortId.current !== selectedSortId;

    if (shouldResetProductsData) {
      // Reset productsData and fetch new data
      setproductsData([]);
      setOffset(0); // Reset offset if needed
      setTotalOfItems(0);
      otalLengtOfItem(0);
    }
    // Call requestAPI only if group, category, and subCategory are not empty
    if (group && category && subCategory && (totalLengtOfItem === 0 || totalLengtOfItem < totalOfItems) ) {
      const fetchData = async () => {
        setIsLoading(true);
        requestAPI(Endpoints.allCategoryProductList, {
          parameters: {
            selectedGroup: group,
            selectedSubCategory: category,
            selectedSubCategoryDetail: subCategory,
            selectedCategoryFilter: selectedGenders,
            selectedSortId: selectedSortId ?? "",
            startIndex: offset,
            limit: 5,
          },
          onSuccess: (response: BaseResponse<ProductsV3Response>) => {
            setSorts(response.data.sorts);
            setSubCategoryDetails(response.data.subCategoryDetails);
            setCategoryFilters(response.data.categoryFilters);
            setproductsData((prevData) => [
              ...(prevData || []), // Ensure prevData is not null or undefined
              ...response.data.items,
            ]);

            setTotalOfItems(response.data.totalOfItems);
            otalLengtOfItem(productsData.length);

            // Check if there are more items to fetch

            setIsLoading(false);
          },
          onFailure: (error: any) => {
            setError({
              message: error,
              onRetryButtonTapped: () => fetchData(),
            });
          },
        });

        prevSubCategory.current = subCategory;
        prevSelectedGenders.current = selectedGenders;
        prevSelectedSortId.current = selectedSortId;
      };

      fetchData();
    }
  }, [group, category, subCategory, selectedGenders, selectedSortId, offset]);

  const arraysEqual = (a: any[], b: any[]) => {
    return (
      a.length === b.length && a.every((value, index) => value === b[index])
    );
  };

  useEffect(() => {
    const handleScrolling = (e: any) => {
      const scrollingHeight = e.target.documentElement.scrollHeight;
      const currentHeight =
        e.target.documentElement.scrollTop + window.innerHeight;
      if (currentHeight + 1 >= scrollingHeight) setOffset(offset + 5);
    };

    window.addEventListener("scroll", handleScrolling);
    return () => window.removeEventListener("scroll", handleScrolling);
  }, [offset]);

  const hideSortModal = () => {
    setShouldShowSortModal(false);
  };

  const showSortModal = () => {
    setShouldShowSortModal(true);
  };

  useEffect(() => {
    requestAPI(Endpoints.allCategory, {
      parameters: {},
      onSuccess: (response: BaseResponse<AllCategoryResponse>) => {
        setIsDataAllCategory(response.data);
      },
      onFailure: (error: string) => {
        setError({
          message: error,
          onRetryButtonTapped: () => {},
        });
      },
    });
  }, []);

  const onClickBottomSheetAllCategory = () => {
    setIsVisibleBottomSheetAllCategory(true);
  };

  const onCloseBottomSheetAllCategory = () => {
    setIsVisibleBottomSheetAllCategory(false);
  };

  const updateSize = () => {
    const screenSize = getScreenSizeWithMaxWidth();
    setWidth(screenSize.width);
  };

  const onClickSubCategory = (id: string) => {
    setSubCategory(id);
  };

  const toggleGenderSelection = (id: string) => {
    // Check if the index is already in the selectedStyle array
    const indexInArray = selectedGenders.includes(id);

    if (indexInArray) {
      // If the style is already selected, remove it from the array
      setSelectedGender((prevSelectedStyles) => {
        return prevSelectedStyles.filter((selectedId) => selectedId !== id);
      });
    } else {
      // If the style is not selected and the count is less than 5, add it to the array
      if (selectedGenders.length <= 2) {
        setSelectedGender((prevSelectedStyles) => [...prevSelectedStyles, id]);
      }
    }
  };

  useEffect(() => {
    // Initial setup
    updateSize();

    // Event listener for window resize
    window.addEventListener("resize", updateSize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    requestAPI(Endpoints.reusableProps, {
      parameters: {},
      onSuccess: (
        response: BaseResponse<{
          isCreator: boolean;
          creatorId: string;
          numberInCart: number;
          productBadgeImageUrls: { type: ProductBadgeType; url: string }[];
        }>
      ) => {
        setBadges(response.data.productBadgeImageUrls);
      },
      onFailure: (error: string) => {
        setError({
          message: error,
          onRetryButtonTapped: () => {},
        });
      },
    });
  }, []);

  // console.log()

  return (
    <>
      <AllCategoryBottomSheetController
        isVisible={isVisibleBottomSheetAllCategory}
        onClick={onClickBottomSheetAllCategory}
        dataAllCategory={dataAllCategory}
        onClose={onCloseBottomSheetAllCategory}
      />

      <Scaffold
        topBar={
          <>
            <p style={{ fontSize: "24px", fontWeight: "bold" }}>Shop</p>
            <div className="FlexFillSpacing"></div>
            <NavigationButton
              src={ic_search}
              onClick={() => {
                window.location.href = "/Store/Search";
              }}
            />
            <NavigationButton
              src={ic_cart}
              // numberOnRightTop={this.viewModel.numberInCart}
              onClick={() => {
                window.location.href = "/Cart";
              }}
            />
          </>
        }
        selectedBottomBarItem="store"
        showErrorModel={error}
        isShowingBottomModal={shouldShowSortModal}
        bottomModalChild={
          sorts ? (
            <CuratedProductsSortViewWidget
              models={sorts}
              selectedId={selectedSortId ?? sorts[0]?.id}
              onSelectSortId={(value) => setSelectedSortId(value)}
              onApplySortTapped={() => hideSortModal()}
            />
          ) : undefined
        }>
        <div
          className="head"
          style={{
            position: "fixed",
            top: "52px",
            width: "100%",
            maxWidth: "600px",
            backgroundColor: "white",
            zIndex: 10,
          }}>
          {subCategoryDetails && (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}>
                <img
                  src={ic_burger_menu}
                  alt=""
                  onClick={onClickBottomSheetAllCategory}
                />
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    overflowX: "auto",
                    overflowY: "visible",
                  }}>
                  <StoreTopTabbarWidget
                    selectedId={subCategory}
                    labels={subCategoryDetails.map((model) => {
                      return {
                        id: model.id,
                        label: model.label,
                        onTap: () => {
                          mixpanelManager.tabClick(model.label, "store", () => {
                            onClickSubCategory(model.id);
                          });
                        },
                      };
                    })}
                  />
                </div>
              </div>
            </>
          )}

          <div id="CuratedProductsSelectedSortDisplayed">
            <button onClick={() => showSortModal()}>
              <img src={ic_sort} alt="" />
              <div style={{ width: "4px" }} />
              {sorts.find((value) => value.id === selectedSortId)?.label ??
                sorts[0]?.label}
            </button>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "8px",
              padding: "16px",
            }}>
            {categoryFilters &&
              categoryFilters.map((data) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      padding: "6px 16px",
                      alignItems: "flex-start",
                      gap: "10px",
                      borderRadius: "20px",
                      border: "0.5px solid var(--achromatic-black, #000)",
                      background: selectedGenders.includes(data.id)
                        ? "black"
                        : "white",
                      color: selectedGenders.includes(data.id)
                        ? "white"
                        : "black",
                    }}
                    onClick={() => toggleGenderSelection(data.id)}>
                    {data.label}
                  </div>
                );
              })}
          </div>
        </div>
        <SizedDiv height={140} />
        <div
          style={{
            display: "flex",
            flexFlow: "row wrap",
            columnGap: "12px",
            rowGap: "12px",
            padding: "8px 16px 16px 16px",
          }}>
          {productsData &&
            productsData.map((p) => {
              const filteredArrayA = badges
                .filter((item) => p.badgeTypes.includes(item.type))
                .map((item) => item.url);

              return (
                <ProductSummaryItemCellWidget
                  key={`CuratedProductListItem_${p.id}`}
                  cellWidth={(width - 44.0) / 2}
                  badgeUrls={filteredArrayA}
                  model={{
                    badgeTypes: p.badgeTypes,
                    id: p.id,
                    imageUrl: p.imageUrl,
                    price: p.price,
                    title: p.title,
                    ageRestricted: p.ageRestricted,
                    brand: p.brand,
                    footnote: p.footnote,
                    isSoldOut: p.isSoldOut,
                  }}
                />
              );
            })}
        </div>
        {isLoading && <LoadingWidget />}
      </Scaffold>
    </>
  );
}
