import "./ShowCaseDetail.css";

import ic_back_black from "../../Assets/Images/ic_back.svg";
import ic_back_white from "../../Assets/Images/ic_back_white.svg";
import ic_cart_black from "../../Assets/Images/ic_cart_dark.svg";
import ic_cart_white from "../../Assets/Images/ic_cart_white.svg";
import ic_share_black from "../../Assets/Images/ic_share_box.svg";
import ic_share_white from "../../Assets/Images/ic_share_box_white.svg";

import ViewController from "../../CommonComponents/Base/ViewController";
import ShowCaseDetailViewModel from "./ShowCaseDetailViewModel";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import ShowCaseDetailCoverWidget from "./Widgets/ShowCaseDetailCoverWidget";
import ShowCaseDetailFeedWidget from "./Widgets/ShowCaseDetailFeedWidget";
import {
  getLocationQueries,
  getScreenSizeWithMaxWidth,
} from "../../CommonComponents/Utils";
import NavigationButton from "../../CommonComponents/UI/ButtonWidget/NavigationButton";
import { mixpanelManager } from "../../Analytics/Mixpanel/Common";

interface IdAndActionsPair {
  id: string;
  action: () => void;
}
export default class ShowCaseDetailViewController extends ViewController<
  {},
  ShowCaseDetailViewModel
> {
  onScrollIdsAndActions: IdAndActionsPair[] = [];

  constructor() {
    const id = getLocationQueries()["id"];
    super({}, new ShowCaseDetailViewModel(id));
    this.viewModel.loadPreloadingProps();

    window.addEventListener("scroll", () => this.handleScroll());

    mixpanelManager.trackPageView("ShowcaseDetail");
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => this.handleScroll());
  }

  render() {
    this.onScrollIdsAndActions = [];

    const response = this.viewModel.response;
    const isWhiteNavBarIcon =
      (getLocationQueries()["navBarIconColor"]?.toUpperCase() ?? "DARK") ===
      "WHITE";
    const navBarTitle = getLocationQueries()["title"];

    if (response) {
      return (
        <Scaffold
          selectedBottomBarItem={
            getLocationQueries()["topSafeAreaMargin"] ? undefined : "showcase"
          }
          creatorId={this.viewModel.creatorId}
          isCreator={this.viewModel.isCreator}
          isShowLoading={this.viewModel.isLoading}
          showErrorModel={this.viewModel.errorMessage}
          shareUrlModel={this.viewModel.shareUrlModel}
        >
          <div id="ShowCaseDetailMainContainer">
            <div
              id="ShowCaseDetailTopBar"
              style={{
                height: `${getLocationQueries()["topSafeAreaMargin"] ?? 60}px`,
              }}
            >
              <div
                id="ShowCaseDetailTopBarBackground"
                style={{
                  backgroundColor: response.navBarBackgroundHex ?? "#000000",
                }}
              />
              {!getLocationQueries()["topSafeAreaMargin"] && (
                <>
                  <NavigationButton
                    src={isWhiteNavBarIcon ? ic_back_white : ic_back_black}
                    onClick={() => {
                      window.location.href = "/ShowCase";
                    }}
                  />
                  <div
                    className="FlexFillSpacing"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "16px",
                      zIndex: "20",
                    }}
                  >
                    <p
                      id="ShowCaseDetailNavBarTitle"
                      style={{
                        color: isWhiteNavBarIcon ? "white" : "black",
                      }}
                    >
                      {(navBarTitle?.length ?? 0) > 0
                        ? navBarTitle.replaceAll("%20", " ")
                        : ""}
                    </p>
                  </div>
                  <NavigationButton
                    src={isWhiteNavBarIcon ? ic_share_white : ic_share_black}
                    onClick={() => {
                      this.viewModel.onShareLink();
                    }}
                  />
                  <NavigationButton
                    src={isWhiteNavBarIcon ? ic_cart_white : ic_cart_black}
                    numberOnRightTop={this.viewModel.numberInCart}
                    onClick={() => {
                      window.location.href = "/Cart";
                    }}
                  />
                </>
              )}
            </div>
            <ShowCaseDetailCoverWidget
              model={response.cover}
              navBarBackgroundHex={response.navBarBackgroundHex ?? "#000000"}
              isTopGradientInCoverShown={
                response.isTopGradientInCoverShown ?? true
              }
              isBottomGradientInCoverShown={
                response.isBottomGradientInCoverShown ?? true
              }
            />
            {response.sections.map((feed) => {
              if (
                feed.carouselItems.length === 0 &&
                feed.products.length === 0
              ) {
                if (feed.asset?.assetType === "VIDEO") {
                  const cellId = `${Math.random()}`.replace("0.", "");

                  this.onScrollIdsAndActions.push({
                    id: cellId,
                    action: () => {
                      if (feed.asset && feed.textContent) {
                        const element = document
                          .getElementsByClassName(cellId)[0]
                          .getElementsByClassName("VideoPlayer")[0];

                        (element as any).play();
                      }

                      const element = document
                        .getElementsByClassName(cellId)[0]
                        .getElementsByClassName("TextPositionContainer")[0];

                      if (element) {
                        if (feed.textContent?.position === "TOP") {
                          element.classList.add("TopTextAppear");
                          (element as any).style.top = "0px";
                        } else {
                          element.classList.add("BottomTextAppear");
                          (element as any).style.bottom = "0px";
                        }
                      }
                    },
                  });

                  return <ShowCaseDetailFeedWidget model={feed} id={cellId} />;
                } else if (feed.asset && feed.textContent) {
                  const cellId = `${Math.random()}`.replace("0.", "");

                  this.onScrollIdsAndActions.push({
                    id: cellId,
                    action: () => {
                      const element = document
                        .getElementsByClassName(cellId)[0]
                        .getElementsByClassName("TextPositionContainer")[0];

                      if (element) {
                        if (feed.textContent?.position === "TOP") {
                          element.classList.add("TopTextAppear");
                          (element as any).style.top = "0px";
                        } else {
                          element.classList.add("BottomTextAppear");
                          (element as any).style.bottom = "0px";
                        }
                      }
                    },
                  });

                  return <ShowCaseDetailFeedWidget model={feed} id={cellId} />;
                }
              }

              return <ShowCaseDetailFeedWidget model={feed} />;
            })}
          </div>
        </Scaffold>
      );
    }

    return <></>;
  }

  private handleScroll() {
    const screenSize = getScreenSizeWithMaxWidth();
    const screenHeight = screenSize.height;
    const coverHeight = (screenSize.width * 5) / 4;

    let minYPosition = (screenHeight - 52.0) / 2.0;

    minYPosition =
      minYPosition > screenHeight - 350.0 ? minYPosition : screenHeight - 350.0;

    for (let index = 0; index < this.onScrollIdsAndActions.length; index++) {
      const elements = document.getElementsByClassName(
        this.onScrollIdsAndActions[index].id
      );

      if (
        elements.length > 0 &&
        elements[0].getBoundingClientRect().y < minYPosition
      ) {
        this.onScrollIdsAndActions[index].action();
        this.onScrollIdsAndActions.splice(index, 1);
      }
    }

    const coverElement = document.getElementById("ShowCaseDetailMainContainer");
    const topNavBarBackgroundElement = document.getElementById(
      "ShowCaseDetailTopBarBackground"
    );
    const navbarTitle = document.getElementById("ShowCaseDetailNavBarTitle");

    const coverYPosition = coverElement?.getBoundingClientRect().y ?? 0.0;
    const percentage = -(coverYPosition / coverHeight);

    (topNavBarBackgroundElement as any).style.opacity =
      percentage > 1 ? 1 : percentage;
    (navbarTitle as any).style.opacity = percentage > 1 ? 1 : percentage;
  }
}
