import React from "react"
import CreatorSpaceResponse from "../Models/CreatorSpaceResponse";
import { handleUnicode } from "../../../CommonComponents/Utils";

import "./CreatorSpace.css";

import ic_instagram from "../../../Assets/Images/SocialMedia/ic_instagram_round.png";
import ic_youtube from "../../../Assets/Images/SocialMedia/ic_youtube_round.png";
import ic_tiktok from "../../../Assets/Images/SocialMedia/ic_tiktok_round.png";
import ic_link from "../../../Assets/Images/SocialMedia/ic_custom_link_round.png";
import ic_shop from "../../../Assets/Images/ic_shop_icon.svg";

type CreatorSpaceDescriptionProps = {
    spaceDetail: CreatorSpaceResponse | null
};

export default class CreatorSpaceDescriptionSectionWidget extends React.Component<CreatorSpaceDescriptionProps, {}> {
    render() {
        const creatorName = JSON.parse('"' + (this.props.spaceDetail?.sellerProfile.name ?? "") + '"');
        const creatorDetail = handleUnicode(this.props.spaceDetail?.sellerProfile.description);
        JSON.parse(`"${this.props.spaceDetail?.sellerProfile.description.replaceAll(/\n/g, `<br>`)}"`).replaceAll(`<br>`, `\n`);

        return(
            <div id="SpaceDescription">
                <p>{creatorName}<img src={ic_shop} alt="shop"></img></p>
                <p style={{whiteSpace:"break-spaces"}}>{creatorDetail}</p>
                <div id="SpaceSocialMediaGrid">
                    {
                        this.props.spaceDetail?.socialLinks.map((model, _) => {
                            let title: string;
                            let image: string;

                            switch(model.type) {
                                case "INSTAGRAM":
                                    title = "Instagram";
                                    image = ic_instagram;
                                    break;
                                case "TIKTOK":
                                    title = "TikTok";
                                    image = ic_tiktok;
                                    break;
                                case "YOUTUBE":
                                    title = "YouTube";
                                    image = ic_youtube;
                                    break;
                                default:
                                    title = model.url;
                                    image = ic_link;
                                    break;
                            }

                            return(
                                <button onClick={() => {window.location.href = model.url}} key={model.url}>
                                    <img src={image} alt="" key={`${model.url}_image`} />
                                    {title}
                                </button>
                            );
                        })
                    }
                </div>
                <div id="SpaceRewardTable">
                    {
                        this.props.spaceDetail?.amountDetails.map((model, index) => {
                            if (index > 0) {
                                return(
                                    <>
                                        <div className="SpaceRewardTableDivider" key={`${index}_divider`}/>
                                        <div className="SpaceRewardTableItem" key={`${index}_item`}>
                                            <p key={`${index}_par1`}>{model.title}</p>
                                            <p key={`${index}_par2`}>{model.descriptionText}</p>
                                        </div>
                                    </>
                                )
                            } else {
                                return(
                                    <div className="SpaceRewardTableItem" key={`${index}_item`}>
                                        <p key={`${index}_par1`}>{model.title}</p>
                                        <p key={`${index}_par2`}>{model.descriptionText}</p>
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </div>
        );
    }
}