import React from "react";
import "./QuantityButton.css";

export type QuantityButtonProps = {
  qty?: number;
  onAdd?: () => void;
  onSub?: () => void;
  isDisabled?: boolean;
};

export default class QuantityButton extends React.Component<QuantityButtonProps> {
  render(): React.ReactNode {
    return (
      <div
        id="QuantityButton"
        style={{ opacity: this.props.isDisabled ? 0.3 : 1 }}
      >
        <button id="QuantityButtonSub" onClick={() => this.props.onSub?.()}>
          <svg
            width="14"
            height="2"
            viewBox="0 0 14 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="1"
              x2="13"
              y2="1"
              stroke="black"
              stroke-linecap="round"
            />
          </svg>
        </button>
        <p id="QuantityButtonCounter">
          {(this.props.qty || 0) < -1 ? 0 : this.props.qty}
        </p>
        <button id="QuantityButtonAdd" onClick={() => this.props.onAdd?.()}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="10"
              y1="4"
              x2="10"
              y2="16"
              stroke="black"
              stroke-linecap="round"
            />
            <line
              x1="4"
              y1="10"
              x2="16"
              y2="10"
              stroke="black"
              stroke-linecap="round"
            />
          </svg>
        </button>
      </div>
    );
  }
}
