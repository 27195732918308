import BaseUIComponent from "../../CommonComponents/Base/BaseUIComponent";

import Scaffold, {
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";

import RegisterVerificationViewModel from "./RegisterVerificationViewModel";

import { Navigate } from "react-router-dom";
import "./Register.css";

import ButtonWidget, {
  ButtonWidgetType,
} from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";

import InputFieldWidget from "../../CommonComponents/UI/InputFieldWidget/InputFieldWidget";
import React from "react";
import mixpanel from "mixpanel-browser";
import { isResellerDomain } from "../../CommonComponents/Utils";

export default class RegisterVerificationScreen extends BaseUIComponent<
  {},
  RegisterVerificationViewModel
> {
  constructor(props: {}) {
    super(props, new RegisterVerificationViewModel());
    this.viewModel().handleTimer();

    //MIXPANEL TRACKING: semo_page_view / screen_view
    mixpanel.track(isResellerDomain() ? "semo_page_view" : "screen_view", {
      page_name: "RegisterOTPForm",
    });
  }

  handleChangeVerificationCode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.viewModel().onVerificationCodeChanged(event.target.value);
  };

  handleSubmit = (event: any) => {
    this.viewModel().onSubmitVerification();
  };

  handleResendVerification = (event: any) => {
    this.viewModel().onResendVerification();
  };

  render() {
    if (this.viewModel().isSuccess) {
      return <Navigate to="/RegisterPassword" />;
    }

    return (
      <Scaffold
        showSnackbar={
          this.viewModel().errorMessage
            ? new ShowSnackbarModel({
                message: this.viewModel().errorMessage ?? "",
              })
            : undefined
        }
      >
        <div id="RegisterContainer" className="container">
          <div className="row">
            <div className="col">
              <h4>
                Please enter the code sent to <br></br>{" "}
                {localStorage.input_email_telp}
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col mtop60">
              <InputFieldWidget
                title="Code"
                inputType="tel"
                isEnabled={true}
                errorMessage=""
                maxLength={6}
                placeholder={"6 digit code"}
                action={(event) => {
                  this.handleChangeVerificationCode(event);
                }}
              ></InputFieldWidget>
            </div>
          </div>
          <div className="row">
            <div className="col mtop22 text-center">
              <ButtonWidget
                buttonType={ButtonWidgetType.primary}
                isLoading={
                  this.viewModel().isLoadingButtonSubmit ? true : false
                }
                isEnabled={
                  this.viewModel().verification_code.length < 6 ? false : true
                }
                width="calc(100% - 6px)"
                action={() => {
                  this.handleSubmit("");
                }}
              >
                Verify
              </ButtonWidget>
            </div>
          </div>
          <div className="row">
            <div className="col mtop16 text-center">
              {this.viewModel().isWait ? (
                <p className="p_verifcation">
                  Please wait {this.viewModel().timeRemainingCountingInSeconds}{" "}
                  seconds before trying to send
                </p>
              ) : (
                <p className="p_verifcation">
                  If you did not receive the code, click to resend it.
                </p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col mtop35 text-center">
              {this.viewModel().isWait ? (
                <p
                  className="a_verifcation_nonaktif"
                  onClick={this.handleResendVerification}
                >
                  Click to Resend
                </p>
              ) : (
                <p
                  className="a_verifcation_aktif"
                  onClick={this.handleResendVerification}
                >
                  Click to Resend
                </p>
              )}
            </div>
          </div>
        </div>
      </Scaffold>
    );
  }
}
