import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import ic_voucher from "../../../Assets/Images/ic_voucher.svg";
import "./ProductDetailEligibleCouponsWidget.css"

type EligibleCoupon = {
    id: string;
    title: string;
    subtitle: string;
};

type ProductDetailEligibleCouponsWidgetProps = {
    eligibleCoupons?: EligibleCoupon[];
    onEligibleCouponClick?: (id: string) => void;
};

export default class ProductDetailEligibleCouponsWidget extends React.Component<ProductDetailEligibleCouponsWidgetProps> {
    render() {
        const eligibleCoupons = this.props.eligibleCoupons;
        // const eligibleCoupons: EligibleCoupon[] = [
        //     {
        //         id: "924580",
        //         title: "100k off for next purchase",
        //         subtitle: "Minimum purchase of 1.5 million",
        //     },
        //     {
        //         id: "924577",
        //         title: "100k off for next purchase",
        //         subtitle: "min. 1.5 million",
        //     },
        //     {
        //         id: "924580",
        //         title: "100k off for next purchase",
        //         subtitle: "min. 1.5 million",
        //     },
        // ];

        return (
            <>
                {eligibleCoupons && (
                    <ScrollContainer
                        horizontal
                        hideScrollbars={false}
                        style={{
                            display: "flex",
                            flexWrap: "unset",
                            marginBottom: "24px",
                            gap: "8px",
                        }}
                    >
                        {eligibleCoupons?.map((c) => (
                            <button
                                className="EligibleCoupon"
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "7px",
                                    backgroundColor: "#F4F5FF",
                                    color: "#6677FF",
                                    borderRadius: "8px",
                                    fontSize: "12px",
                                    width: "212px",
                                    minWidth: "212px",
                                    height: "66px",
                                    padding: "6px 8px 6px 9px",
                                    textAlign: "left",
                                    border: "1px solid #6677FF",
                                }}
                                onClick={()=>this.props.onEligibleCouponClick?.(c.id)}
                            >
                                <div>
                                    <img src={ic_voucher} alt="voucher" />
                                </div>
                                <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                                    <b style={{whiteSpace: "nowrap"}}>{c.title}</b>
                                    <p className="ProductDetailEligibleCouponSubtitle">{c.subtitle}</p>
                                </div>
                            </button>
                        ))}
                    </ScrollContainer>
                )}
            </>
        );
    }
}
