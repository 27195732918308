import img_become_creator from "../../../../Assets/Images/img_become_creator_illustration.png";

export default class PopUpWidgetModel {
    imageUrl?: string;
    title: string;
    description: string;
    mainButtonTitle: string;
    mainButtonAction: () => void;
    desctructiveButtonTitle?: string;
    desctructiveButtonAction?: () => void;
    key: string;

    constructor(args: {
        imageUrl?: string,
        title: string, 
        description: string,
        mainButtonTitle: string, 
        mainButtonAction: () => void, 
        desctructiveButtonTitle?: string, 
        desctructiveButtonAction?: () => void
    }) {
        this.imageUrl = args.imageUrl;
        this.title = args.title;
        this.description = args.description;
        this.mainButtonTitle = args.mainButtonTitle;
        this.mainButtonAction = args.mainButtonAction;
        this.desctructiveButtonTitle = args.desctructiveButtonTitle;
        this.desctructiveButtonAction = args.desctructiveButtonAction;
        this.key = Date().toLocaleString();
    }

    static get loginRequestPopUpModel(): PopUpWidgetModel {
        return new PopUpWidgetModel({
            title: "Please Sign In to Continue",
            description: "Please Sign In. You need to Sign In to use this function.",
            mainButtonTitle: "Sign In",
            mainButtonAction: () => {
                window.location.href = "/Login"
            },
            desctructiveButtonTitle: "Cancel",
            desctructiveButtonAction: () => {}
        });
    }
}