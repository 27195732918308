import React from "react";

export type ProductSizeTableWidgetProps = {
  headers?: string[];
  rows?: Array<string[]>;
};

function ProductSizeTableWidget(props: ProductSizeTableWidgetProps) {
  const { headers = [], rows = [] } = props;

  return (
    <table>
      <thead style={{ display: "flex" }}>
        {headers.map((h, idx) => (
          <td
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px 0px 8px 4px",
              height: "34px",
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#383838",
              background: "#E4E4E4",
              width: 100,
              justifyContent: idx !== 0 ? "center" : "unset",
            }}
          >
            {h}
          </td>
        ))}
      </thead>
      <tbody>
        {rows.map((row, idx1) => (
          <tr
            style={{
              display: "flex",
            }}
          >
            {row.map((d, idx2) => {
              return (
                <td
                  style={{
                    display: "flex",
                    flexDirection: "row",

                    justifyContent: idx2 !== 0 ? "center" : "unset",
                    alignItems: "center",
                    padding: "8px 0px 8px 4px",
                    height: "34px",
                    fontWeight: "400",
                    fontSize: "12px",
                    width: 100,
                    lineHeight: "18px",
                    color: "#383838",
                  }}
                >
                  {rows?.[idx2]?.[idx1] || "-"}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ProductSizeTableWidget;
