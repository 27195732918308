import React from 'react';
import { mixpanelManager } from '../../Analytics/Mixpanel/Common';

class PhotoGuideScreen extends React.Component {
    constructor() {
        mixpanelManager.trackPageView("PhotoGuide")
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col text-center no-padding">
                        <img className="img-fluid" src={global.ImgUrl + 'shopable/Photo-Guide-App-3.webp'} alt="guide"></img>
                    </div>
                </div>

            </div>
        );
    }
}

export default PhotoGuideScreen;