import React, { ReactNode } from "react";
import ErrorStateView from "../ErrorStateView/ErrorStateView";
import { BottomBarItemType, BottomBarWidget } from "../BottomBar/BottomBarWidget";
import { LoadingView } from "../LoadingWidget/LoadingView";
import PopUpWidget from "../PopUp/PopUpWidget";
import PopUpWidgetModel from "../PopUp/Models/PopUpWidgetModel";

import "./Scaffold.css";
import ShareUrlPopUpWidget from "../ShareUrlPopUp/ShareUrlPopUpWidget";
import { ShareUrlModel } from "../ShareUrlPopUp/Models/ShareUrlModel";
import { getLocationQueries } from "../../Utils";

// Props, Models, and State

export interface ShowErrorModel {
    message: string;
    onRetryButtonTapped: () => void;
}

export class ShowSnackbarModel {
    type?: "error" | "info" | "normal";
    message: string;
    key: string;
    inset?: {
      bottom?: number;
    }

    constructor(args: { message: string; type?: "error" | "info" | "normal", inset?: {
      bottom?: number;
    } }) {
        this.type = args.type;
        this.message = args.message;
        this.key = Date().toLocaleString();
        this.inset = args.inset
    }
}

type ScaffoldProps = {
    topBar?: JSX.Element;
    children?: ReactNode;
    selectedBottomBarItem?: BottomBarItemType;
    isShowLoading?: boolean;
    showErrorModel?: ShowErrorModel | null;
    showSnackbar?: ShowSnackbarModel | null;
    showLoadingAndErrorPosition?: "fullscreen" | "on-content-view";
    showPopUpModel?: PopUpWidgetModel | null;
    creatorId?: string;
    isCreator?: boolean;
    shareUrlModel?: ShareUrlModel | null;
    bottomModalChild?: JSX.Element;
    isShowingBottomModal?: boolean;
    onBottomModalBackgroundTapped?: () => void;
    showDownloadInvitationWidget?: boolean;
    backgroundColor?: string;
};

type ScaffoldState = {
    showPopUpModel?: PopUpWidgetModel;
    showSnackbar?: ShowSnackbarModel;
    isBottomModalShown: boolean;
};

// Scaffold

export default class Scaffold extends React.Component<ScaffoldProps, ScaffoldState> {
    constructor(props: ScaffoldProps) {
        super(props);
        this.state = {
            isBottomModalShown: false,
        };
    }

    render() {
        let content: JSX.Element;
        const snackbarModel = this.props.showSnackbar ?? this.state.showSnackbar;
        const popUpModel = this.props.showPopUpModel ?? this.state.showPopUpModel;
        const isDarkTheme = this.props.selectedBottomBarItem === "home" || (this.props.selectedBottomBarItem === "showcase" && getLocationQueries()["theme"] !== "LIGHT");
        
        if (snackbarModel) {
            setTimeout(() => {
                this.setState({ showSnackbar: undefined });
            }, 4200);
        }

        if (this.props.isShowLoading) {
            content = <LoadingView theme={isDarkTheme ? "dark" : "light"} />;
        } else if (this.props.showErrorModel) {
            content = (
                <ErrorStateView
                    message={this.props.showErrorModel.message}
                    retryAction={this.props.showErrorModel.onRetryButtonTapped}
                    theme={isDarkTheme ? "dark" : "light"}
                />
            );
        } else {
            content = <>{this.props.children}</>;
        }

        if (this.state.isBottomModalShown && !this.props.isShowingBottomModal) {
            setTimeout(() => {
                this.setState({ isBottomModalShown: false });
            }, 500);
        } else if (!this.state.isBottomModalShown && this.props.isShowingBottomModal) {
            this.setState({ isBottomModalShown: true });
        }

        if (this.props.showLoadingAndErrorPosition === "fullscreen" && (this.props.isShowLoading || this.props.showErrorModel)) {
            return (
                <div id="Scaffold">
                    {content}
                    {popUpModel && <PopUpWidget model={popUpModel} key={popUpModel.key} onPopUpClosed={() => this.setState({ showPopUpModel: undefined })} />}
                </div>
            );
        } else {
            let scaffoldContentType: string;

            if (this.props.selectedBottomBarItem) {
                scaffoldContentType = "ScaffoldContentWithBottomBar";
            } else {
                scaffoldContentType = "ScaffoldContentOnly";
            }

            return (
                <div id="Scaffold">
                    {
                        this.props.topBar && 
                        <div 
                            id="ScaffoldTopBar"
                        >{this.props.topBar}</div>
                    }
                    <div 
                        className={`${scaffoldContentType}`} 
                        id="ScaffoldContent" 
                        style={{
                            marginTop: `${this.props.topBar ? "52px" : "0px"}`, 
                            minHeight: `calc(100vh - ${this.props.topBar ? "52px" : "0px"} - ${this.props.selectedBottomBarItem ? "52px" : "0px"})`,
                            backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : (isDarkTheme ? "black" : "white")
                        }}
                    >
                        {content}
                    </div>
                    {this.props.selectedBottomBarItem && (
                        <BottomBarWidget
                            selectedItem={this.props.selectedBottomBarItem}
                            isCreator={this.props.isCreator}
                            creatorId={this.props.creatorId}
                            updatePopUpModel={model =>
                                this.setState({
                                    showPopUpModel: model,
                                })
                            }
                        />
                    )}
                    {popUpModel && <PopUpWidget model={popUpModel} key={popUpModel.key} onPopUpClosed={() => this.setState({ showPopUpModel: undefined })} />}
                    {this.props.shareUrlModel && (
                        <ShareUrlPopUpWidget
                            model={this.props.shareUrlModel}
                            onCopyUrlTapped={() =>
                                this.setState({
                                    showSnackbar: new ShowSnackbarModel({
                                        message: "Copied",
                                        type: "info",
                                    }),
                                })
                            }
                            key={this.props.shareUrlModel.key}
                        />
                    )}
                    {!this.props.isShowLoading && (this.props.showDownloadInvitationWidget === true) && (
                        <div id="DownloadAppRequestContainer" className={scaffoldContentType}>
                            <p>Download thefepi for more experience</p>
                            <button
                                onClick={() => {
                                    window.location.href = window.navigator.userAgent.includes("Android") ? 
                                        "https://play.google.com/store/apps/details?id=com.thefepi.shopable" : 
                                        "https://apps.apple.com/id/app/thefepi/id1590816062";
                                }}
                            >
                                Download
                            </button>
                        </div>
                    )}
                    {snackbarModel && (
                        <div
                            id="SnackbarContainer"
                            key={snackbarModel.key}
                            style={{
                                maxWidth: 580,
                                width: '100%',
                               zIndex: 999,
                                position: 'fixed',
                                left: 'calc(50%)',
                                transform: 'translateX(-50%)'
                                // bottom: snackbarModel.inset?.bottom
                            }}
                        >
                            <div id="SnackbarContainer" style={{
                                 backgroundColor: snackbarModel.type === "info" ?  "#5797FF" : snackbarModel.type === 'normal' ? '#383838' : "#F14100",
                                 marginBottom: this.props.selectedBottomBarItem ? 52 : snackbarModel.inset?.bottom,
                                 
                            }}>

                            {snackbarModel.message}

                            </div>
                        </div>
                    )}
                    {this.props.bottomModalChild && this.state.isBottomModalShown === true && (
                        <>
                            <div
                                id="BottomModelBackground"
                                className={this.props.isShowingBottomModal ? "BottomModelBackgroundShown" : "BottomModelBackgroundHidden"}
                                onClick={() => {
                                    if (this.props.onBottomModalBackgroundTapped) {
                                        this.props.onBottomModalBackgroundTapped();
                                    }
                                }}
                            />
                            <div
                                id={this.props.isShowingBottomModal ? "BottomModelContainerShown" : "BottomModelContainerHidden"}
                                className={this.props.isShowingBottomModal ? "BottomModalSlideIn" : "BottomModalSlideOut"}
                            >
                                {this.props.bottomModalChild}
                            </div>
                        </>
                    )}
                </div>
            );
        }
    }
}
