import React from "react";

type CartRemovePopupWidgetProps = {
    showModal?: boolean,
    isEnabled?: boolean,
    Message?: string | null,
    closeModal?: () => void,
    removeItem : () => void,
};

export default class CartRemovePopupWidget extends React.Component<CartRemovePopupWidgetProps> {
    render() {
        return(
            <>
            <div className={this.props.showModal ? "modal-backdrop fade show" : ""}></div>
            <div className={this.props.showModal ? "modal fade show" : "modal fade"} style={this.props.showModal ? {display:'block'} : {display:'none'}} id="ErrorModal" aria-labelledby="ErrorModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <button onClick={this.props.closeModal} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className="modal-body">
                            <p className="font14"><b>REMOVE ITEM</b></p>
                            <p className="font14">Anda ingin menghapus item ini?</p>
                            <div className='text-center'>
                                <br></br>
                                <input onClick={this.props.closeModal} type="button" className="btn btn-primary" value="TIDAK"></input>&nbsp;&nbsp;
                                <input onClick={()=>this.props.removeItem()} type="button" className='btn btn-secondary' value="YA"></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}